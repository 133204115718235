import { Dropdown, message, Modal, notification, Tooltip } from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { useEffect, useState } from 'react'
import TaskPriority from '../../TaskPriority'
import { TaskStatus } from '../../TaskStatus'
import TaskUsers from '../../TaskUsers'
import { VideoPlayer as Vidify } from 'vidify'
import { ReactComponent as WhiteTag } from '@src/modules/tasks/assets/icons/task/colored-tags.svg'
import { ReactComponent as CloseAttachmentsIcon } from '@src/modules/tasks/assets/icons/task/close-icon.svg'
import { ReactComponent as SuccesIcon } from '@src/modules/tasks/assets/icons/task/succes-icon.svg'
//TODO
// import { ReactComponent as SubtaskIcon } from '@src/modules/tasks/assets/icons/task/colored-subtask.svg'
// import { ReactComponent as DeleteIcon } from '@src/modules/tasks/assets/icons/task/delete-icon-red.svg'
// import { ReactComponent as EditeIcon } from '@src/modules/tasks/assets/icons/task/edit-icon.svg'

//import TextareaAutosize from 'react-textarea-autosize'
// import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import Button from '@src/modules/shared/components/Button/Button'
import SpacesTree from '@src/modules/shared/components/SpacesTree'
import Spinner from '@src/modules/shared/components/Spinner'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { resetFiles } from '@src/modules/shared/store/slices/settings/settingsSlice'

import { useGetListQuery, useGetStatausQuery } from '@src/modules/spaces/services/fileApi'
import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import {
  useAddTaskMutation,
  useUploadTaskAttachementsMutation,
} from '@src/modules/tasks/services/tasksApi'
import dayjs from 'dayjs'
import Calendar from '../../Calendar'
import TaskAttachments from '../../TaskAttachments'
import { useTranslation } from 'react-i18next'
import { treeSupportedTypes } from '@src/modules/shared/components/SpacesTree/SpacesTree'
import { setSocketEvent } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import Tags from '@src/modules/shared/components/Tags'
import Input from '@src/modules/shared/components/Input/Input'
import Search from '@src/modules/shared/components/Input/search'
// import { getFilesUrl } from '../../TaskAttachments/TaskAttachments'
import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
// import { v4 as uuidv4 } from 'uuid'

export interface CreateTaskInfo {
  name: string
  priority: number
  status: statusType
  statusId?: string
  listId: string
  description?: string
  startDate?: string
  dueDate?: string
  attachments?: { file: File; url: string | null; id?: string }[]
  users: any
  teams: any
  tagsId: any
  subTask: any
}

const displayTaskInfoError = (task) => {
  const error = []
  const { name, statusId, fileId, description } = task || {}
  if (!name?.trim()) error.push('Name is required')
  else if (name?.length > 200) error.push('Name is too long!')
  else if (!statusId) error.push('Status is required')
  else if (!fileId) error.push('File is required')
  else if (description && description?.length > 5000) error.push('Description is too long!')

  error?.forEach((error) => message.error(error))
}

export const isValidTask = (task: TaskType & { fileId: string }) => {
  const { name, statusId, fileId, description } = task || {}
  if (description?.length > 2000) return false
  return name.trim() && statusId && fileId
}

export const getUsersAndTeams = (users) => {
  const teams = users?.filter((user) => user?.name !== undefined)?.map((team) => team?.id) || null
  users = users?.filter((user) => !user?.isTeam && user?.id)?.map((user) => user?.id) || null

  return { users, teams }
}

export const reformulateTaskInfo = (
  taskInfo: CreateTaskInfo,
): TaskType & { statusId: string; fileId: string } => {
  let { statusId, status, listId, users } = taskInfo || {}
  const { users: usersList, teams } = getUsersAndTeams(users)

  const newTaskStatus = statusId || status?.id
  const newTask = {
    fileId: listId,
    ...taskInfo,
    users: usersList,
    teams,
  } as unknown as TaskType & { statusId: string; fileId: string }

  if (newTaskStatus) newTask.statusId = newTaskStatus

  return newTask
}

const reformulateLocalTaskInfo = (
  taskInfo: CreateTaskInfo = {} as CreateTaskInfo,
): TaskType & { statusId: string; fileId: string } => {
  let { statusId, status, listId, users, ...rest } = taskInfo
  delete rest.attachments
  delete rest.subTask

  const { users: usersList, teams } = getUsersAndTeams(users)

  const newTaskStatus = statusId || status?.id
  const newTask = {
    fileId: listId,
    ...rest,
    users: usersList,
    teams,
  } as unknown as TaskType & { statusId: string; fileId: string }

  if (newTaskStatus) newTask.statusId = newTaskStatus

  return newTask
}

const CreateTaskModal = ({ open, handleClose: handleCloseTask, data, id }: any) => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const [newList, setList] = useState<string>('')

  const listId = data?.list?.id
  const { data: status } = useGetStatausQuery({
    hierarchyType: 'fileId',
    entityUuid: newList || listId,
  })
  const { data: list } = useGetListQuery({ id: newList || listId }, { skip: !listId && !newList })

  const defaultStatus = status?.[0]

  const intialValues: CreateTaskInfo = {
    priority: 0,
    name: '',
    status: defaultStatus,
    listId: data?.list?.id,
    description: '',
    startDate: data?.startDate || null,
    dueDate: data?.dueDate || null,
    attachments: [],
    users: data?.users || [],
    teams: [],
    subTask: [],
    tagsId: [],
  }
  const [taskInfos, setTaskInfos] = useState<CreateTaskInfo>(intialValues)

  const {
    screenRecording: { recordedFile },
  } = useAppSelector((state) => state.settings)
  const [addTask, { isLoading: isAddTaskLoading }] = useAddTaskMutation()
  const [uploadTaskAttachements, { isLoading: isUploadTaskAttachmentsLoading }] =
    useUploadTaskAttachementsMutation()
  //   const saveUploadedFiles = (file) => {
  //     const newFiles = getFilesUrl([file])
  //     return newFiles
  //   }
  //   const uploadAttachment = async (file) => {
  //     try {
  //       const responseData = saveUploadedFiles(file)
  //       const response = await uploadTaskAttachements({ files: [file] })
  //       setTaskInfo('attachments', [...taskInfos?.attachments, ...responseData])
  //       return response
  //     } catch (error) {
  //       message.error(t('createdTask.failed_to_upload_attachment'))
  //       console.error('Error uploading attachment:', error)
  //     }
  //   }

  const handleClose = (id: string) => {
    dispatch(resetFiles())
    handleCloseTask(id)
    notification.destroy('record')
  }

  useEffect(() => {
    setTaskInfo('status', status?.[0])
  }, [list, defaultStatus, newList])

  const setTaskInfo = (field: string, newData: any) =>
    setTaskInfos((data) => ({ ...data, [field]: newData }))

  const addTaskEvent = () => {
    const addEvent = (newTask) => {
      addTask({
        ...newTask,
        description: newTask?.description,
        attachementsId: [...(newTask?.attachementsId || []), recordedFile?.id],
      })
        .unwrap()
        .then((res) => {
          handleClose(id)
          dispatch(setSocketEvent({ updateType: 'createdTask', updateEvent: res?.payload }))
          message.success(t('create_task.create_task_with_success'))
        })
        .catch((err) => message.error(err?.data?.message || t('create_task.create_task_error')))
    }

    const newTask = reformulateLocalTaskInfo(taskInfos)

    if (isValidTask(newTask)) {
      if (taskInfos.attachments.length > 0) {
        const files = taskInfos.attachments.map(({ file }) => file)
        uploadTaskAttachements({ files }).then((res: any) => {
          const filesLocations = res?.data?.payload?.map((file) => file.id)
          addEvent({ ...newTask, attachementsId: [...filesLocations] })
        })
      } else addEvent(newTask)
    } else displayTaskInfoError(newTask)
  }

  const removeFile = (fileIndex: number) => {
    taskInfos.attachments.splice(fileIndex, 1)
    setTaskInfo('attachments', taskInfos.attachments)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      document.getElementById('create-task-confirm').click()
    }
  }

  useEffect(() => {
    setTaskInfos(intialValues)
  }, [open])
  // TODO
  //   const [subtaskName, setSubtaskName] = useState('')
  //   const handelAddSubTask = () => {
  //     const id = uuidv4()

  //     console.log(subtaskName)
  //     if (subtaskName.trim() !== '') {
  //       setTaskInfo('subTask', [...taskInfos.subTask, { name: subtaskName, id: id }])
  //       setSubtaskName('')
  //     }
  //   }
  //   const handelDeleteSubTask = (id: string) => {
  //     setTaskInfo(
  //       'subTask',
  //       taskInfos.subTask.filter((task) => task.id !== id),
  //     )
  //   }
  //   const [edited, setEdited] = useState()

  return (
    <Modal
      footer={false}
      centered
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => {
        if (isAddTaskLoading) return
        handleClose(id)
      }}
      wrapClassName="custom-modal space-modal task-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">{t('create_task.create_new_task')}</p>
        <CloseIcon
          onClick={() => {
            if (isAddTaskLoading) return
            dispatch(resetFiles())
            handleClose(id)
          }}
          className="close-icon"
        />
      </div>

      <div className="task-modal-content">
        <div className="task-name_priority">
          <Input
            variant="label-inline"
            placeholder={t('create_task.create_task_placeholder')}
            value={taskInfos.name}
            onChange={(e) => setTaskInfo('name', e.target.value)}
            onKeyPress={handleKeyPress}
          />

          <TaskPriority
            showLabel={true}
            onSelect={(p) => setTaskInfo('priority', p)}
            activePriorityCode={taskInfos.priority}
          />
        </div>

        <div className="task-section-two">
          <TaskStatus
            activeStatus={taskInfos.status}
            onSelect={(s) => setTaskInfo('status', s)}
            listId={taskInfos?.listId}
            disable={list == undefined}
          />

          <div className="selelected-task-list">
            <Dropdown
              dropdownRender={() => (
                <div className="ant-dropdown-menu task-dropdown spaces-tree">
                  <Search onSearch={() => {}} prefix={''} variant="underlined" />
                  <div className="spaces-tree-container">
                    <SpacesTree
                      readOnly
                      noDocumentView={true}
                      autoOpen
                      item={{ id: list?.id, type: treeSupportedTypes.LIST }}
                      onSelectList={(items) => {
                        setTaskInfo('listId', items?.[0])
                        setList(items?.[0])
                      }}
                    />
                  </div>
                </div>
              )}
              trigger={['click']}
            >
              <div>
                <span>{t('create_task.in')}</span>
                <p>{list?.name || t('create_task.select_list')}</p>
              </div>
            </Dropdown>
          </div>

          <Tooltip title={list == undefined ? t('create_task.shoud_select_list_first') : ''}>
            <div className="assigned-to" style={{ cursor: list == undefined && 'not-allowed' }}>
              <span>{t('create_task.assign_to')}</span>

              <TaskUsers
                userNumber={7}
                displayAddButton={true}
                disable={list == undefined}
                listId={taskInfos?.listId}
                members={taskInfos?.users}
                setAssignedMembersList={(m) => {
                  setTaskInfo('users', m)
                }}
              />
            </div>
          </Tooltip>
        </div>

        <div className="task-description">
          <EditorContainer
            isTask={true}
            isDocument={false}
            setContent={(value) => setTaskInfo('description', JSON.stringify(value))}
          />
        </div>
        {/* TODO :  */}
        {/* <div className="subtask-container">
          <SubtaskIcon />
          <span>Add subtask</span>
          <span className="plusIcon">+</span>
        </div>
        <div className="subtask-container-item">
          <input
            placeholder="Type subtask name ..."
            value={subtaskName}
            onChange={(e) => {
              setSubtaskName(e?.target?.value)
            }}
          />
          <div className="save-button" onClick={handelAddSubTask}>
            Save
          </div>
        </div> */}
        {/* <div className="sub-task-conatiner">
          {taskInfos?.subTask?.map((item, id) => {
            return (
              <div className="list-subtask" key={id}>
                <div className="list-subtask-first-element">
                  <div className="icon" style={{ background: taskInfos?.status?.color }}></div>

                  <input
                    value={item?.name}
                    onChange={(e) =>
                      setTaskInfo(
                        'subTask',
                        taskInfos.subTask.map((task) => {
                          if (task?.id === item?.id) {
                            task.name = e?.target?.value
                          }
                          return task
                        }),
                      )
                    }
                    disabled={item.id != edited}
                    autoFocus={item.id != edited}
                  />
                </div>
                <div className="list-subtask-last-element">
                  <EditeIcon onClick={() => setEdited(item?.id)} />
                  <DeleteIcon onClick={() => handelDeleteSubTask(item?.id)} />
                </div>
              </div>
            )
          })}
        </div> */}

        {recordedFile ? (
          <div className="uploaded-video">
            <p>{recordedFile?.originalname}</p>
            <Vidify src={recordedFile?.location} enableContextMenu={false} />
          </div>
        ) : null}

        <div className="task-section-three">
          <Tags
            disabled={!list?.name}
            spaceId={list?.spaceId}
            CountOfTags={3}
            maxOfTextLength={10}
            onChange={(tags) => {
              setTaskInfo(
                'tagsId',
                tags?.map((item) => item?.id),
              )
            }}
          >
            <label className="task-attachments">
              <WhiteTag />
              {/* <TaskAttachmentIcon /> */}
              <span>{t('create_task.tags')} +</span>
            </label>
          </Tags>
          <Calendar
            setStart={(d) => d && setTaskInfo('startDate', dayjs(d))}
            setEnd={(d) => d && setTaskInfo('dueDate', dayjs(d))}
            defStart={taskInfos?.startDate}
            defEnd={taskInfos?.dueDate}
          />
          <TaskAttachments
            onUpload={(files) => setTaskInfo('attachments', [...taskInfos?.attachments, ...files])}
            defaultFiles={[]}
          />
        </div>
        {taskInfos?.attachments.length > 0 && (
          <div className="task-attachments-list">
            {taskInfos?.attachments?.map((file, index) => {
              return (
                <>
                  <div className="task-attachments-item">
                    <SuccesIcon />
                    <span>{file?.file?.name}</span>
                    <div className="closeIcon">
                      <CloseAttachmentsIcon onClick={() => removeFile(index)} />
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        )}
      </div>

      <div className="task-footer">
        <Button
          disabled={isAddTaskLoading || isUploadTaskAttachmentsLoading}
          htmlType="submit"
          className="confirm-action"
          id="create-task-confirm"
          onClick={addTaskEvent}
        >
          {isAddTaskLoading || isUploadTaskAttachmentsLoading ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Spinner /> {t('create_task.loading')}
            </div>
          ) : (
            t('create_task.create_new_task')
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateTaskModal
