/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@src/modules/shared/utils/axios'

export type documentProps = {
  name?: string
  spaceId?: string
  folderId?: string
  cover?: string
  icon?: string
  content?: string
  documentId?: string
  cover_y?: number
  isPrivate?: boolean
}

export const createDocument = createAsyncThunk(
  'document/create-document',
  async (props: documentProps) => {
    try {
      const response = await axiosInstance.post(`/api/documents`, props)

      if (response.status === 201) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message[0])
    }
  },
)

export const getAllDocuments = createAsyncThunk('document/get-all', async () => {
  try {
    const response = await axiosInstance.get(`/api/documents`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const getDocument = createAsyncThunk('document/get-one', async (documentId: string) => {
  try {
    const response = await axiosInstance.get(`/api/documents/${documentId}`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})

export const editDocument = createAsyncThunk(
  'document/edit',
  async ({ documentId, ...rest }: documentProps) => {
    try {
      const response = await axiosInstance.patch(`/api/documents/${documentId}`, rest)
      if (response.status === 201) {
        return response.data
      }
    } catch (error) {
      return Promise.reject(error.message)
    }
  },
)

export const deleteDocument = createAsyncThunk('document/delete', async (documentId: string) => {
  try {
    const response = await axiosInstance.delete(`/api/documents/${documentId}`)

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    return Promise.reject(error.message)
  }
})
