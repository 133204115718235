import { IDocument } from '@softylines/tam-types'
import ViewTitle from '@src/modules/shared/components/ViewTitle/ViewTitle'
import { ReactComponent as Search } from '../../assets/icons/trash/search.svg'
import { ReactComponent as Share } from '../../assets/icons/trash/share.svg'
import { ReactComponent as Star } from '../../assets/icons/trash/star.svg'
import { Avatar } from 'antd'
import lock from '../../../spaces/assets/icons/header/lock.svg'
import { ReactComponent as UserIcon } from '../../../settings/assets/icons/profile/user.svg'
import { ReactComponent as QuestionIcon } from '../../../settings/assets/icons/profile/question.svg'
import useWindowSize from '@src/modules/shared/hooks/useWindowSize'

export const renderDocumentLocation = (document) => {
  const { space, folder } = document || {}

  return (
    <>
      {space && (
        <div className="path">
          <div className="space">
            <Avatar src={space?.icon} className="doc-path-avatar">
              {(space?.name && space.name[0]) || 'E'}
            </Avatar>
            <span>{space?.name}</span>
            {space?.isPrivate && <img src={lock} />}
          </div>
          /
          <div className="folder">
            <span>{document.name}</span>
          </div>
        </div>
      )}

      {folder && folder?.id && (
        <div className="path">
          <div className="space">
            <Avatar src={folder?.space?.icon} className="doc-path-avatar">
              {(folder?.space?.name && folder?.space?.name[0]) || 'E'}
            </Avatar>
            <span>{folder?.space?.name}</span>
            {folder?.space?.isPrivate && <img src={lock} />}
          </div>
          /
          <div className="folder">
            <Avatar src={folder.icon} className="doc-path-avatar">
              {(folder.name && folder.name[0]) || 'E'}
            </Avatar>
            <span>{folder.name}</span>
            {folder.isPrivate && <img src={lock} />}
          </div>
          /
          <div className="folder">
            <span>{document.name}</span>
          </div>
        </div>
      )}
    </>
  )
}

type headerType = {
  title: string
  document?: IDocument | any
  isIconsVisible?: boolean
  isProfile?: boolean
  activeView?: string
  setActiveView: Function
}

const Header = ({
  isIconsVisible,
  document,
  title,
  isProfile,
  activeView,
  setActiveView,
}: headerType) => {
  const { width } = useWindowSize()

  return (
    <ViewTitle sidebarID="settings-sidebar">
      <div className="header-title">
        {renderDocumentLocation(document)}

        <p>{title}</p>
      </div>

      {isIconsVisible && (
        <div className="icons">
          <Search />
          <Star />
          <Share />
        </div>
      )}

      {width <= 1024 && isProfile && (
        <div className="profile-switch">
          <div
            className={activeView === 'profile' ? 'active' : ''}
            onClick={() => setActiveView('profile')}
          >
            <UserIcon /> <span>My Profile</span>
          </div>

          <div
            className={activeView === 'activity' ? 'active' : ''}
            onClick={() => setActiveView('activity')}
          >
            <QuestionIcon /> <span>My Activity</span>
          </div>
        </div>
      )}
    </ViewTitle>
  )
}

export default Header
