import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { ReactComponent as DragIcon } from '../../../../assets/icons/status/drag-button.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/status/settings.svg'
import { ReactComponent as RenameIcon } from '@src/modules/spaces/assets/icons/spaceDropdown/update.svg'
import { ReactComponent as DeleteIcon } from '@src/modules/spaces/assets/icons/spaceDropdown/delete.svg'
import { ReactComponent as ConfirmIcon } from '@src/modules/spaces/assets/icons/space/add-status.svg'
import { Dropdown, MenuProps, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { Input } from '@src/modules/shared/components/Input'
import { colors } from '@src/modules/auth/features/CreateWorkspace/CreateWorkspace'
import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { Draggable, DroppableProvided } from 'react-beautiful-dnd'
import { orderByOrderField } from '@src/modules/tasks/utils/orderByOrderField'
import { ICategory } from '@src/modules/spaces/services/categoryApi'

const getStatusByCatergory = (statuses, categoryId) => {
  return statuses?.filter((status) => status.categoryId === categoryId)
}

const newStatusValidation = (status: statusType, t): string => {
  if (!status.name || !status.name?.trim()) return t('space.Status-name-is-required')
  if (!status.color) return t('space.Status-color-is-required')
  return ''
}

export interface IStatus extends statusType {
  isEdit?: boolean
  isNew?: boolean
  isDeleted?: boolean
}

interface IStatusGroup {
  category: ICategory
  status: statusType[]
  viewOnly: boolean
  provided: DroppableProvided
  addStatus: (status: IStatus) => void
  updateStatus: (status: IStatus) => void
  deleteStatus: (statusId: string) => void
}

const items: (t) => MenuProps['items'] = (t) => [
  {
    key: '1',
    label: t('UpdateStatusModal.udpate_status'),
    icon: <RenameIcon />,
  },
  {
    key: '2',
    label: t('UpdateStatusModal.Delete'),
    icon: <DeleteIcon />,
  },
]

const StatusGroup = ({
  category,
  status,
  viewOnly,
  provided,
  addStatus,
  updateStatus,
  deleteStatus,
}: IStatusGroup) => {
  const { t } = useTranslation(['modals'])
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [newStatus, setNewStatus] = useState<IStatus | null>(null)

  const showEditInput = (status) => {
    setIsEdit(true)
    setNewStatus({
      id: Math.random().toString(36).substr(2, 9),
      isNew: !status,
      ...(status || {}),
      categoryId: category.id,
      isEdit: !!status,
    })
  }

  const saveNewStatus = (key: string, value: string) => {
    setNewStatus((prevStatus) => ({
      ...prevStatus,
      [key]: value,
    }))
  }

  const saveChangesHandler = () => {
    const error = newStatusValidation(newStatus, t)
    if (error) {
      message.error(error)
      return
    }
    const { isEdit, ...newStatusData } = newStatus
    if (isEdit) updateStatus(newStatusData)
    else addStatus({ ...newStatusData, isNew: true })
    setIsEdit(false)
  }

  let statuses = useMemo(() => {
    const statuses = getStatusByCatergory(status, category.id)
    return orderByOrderField(statuses) as statusType[]
  }, [status, category.id])
  return (
    <div className="status-group" ref={provided.innerRef} {...provided.droppableProps}>
      <p className="status-group-title">
        {category.name} {!viewOnly && <span onClick={() => showEditInput(null)}>+</span>}
      </p>
      {isEdit && (
        <div
          className="status-input"
          tabIndex={-1}
          onKeyDown={(e) => e.key === 'Enter' && saveChangesHandler()}
        >
          <Input
            value={newStatus?.name}
            bordered={false}
            size="sm"
            autoFocus
            placeholder="..."
            onChange={(e) => saveNewStatus('name', e.target.value)}
          />
          <div className="status-input-colors">
            <ColorSelect
              colors={colors}
              selectedColor={newStatus?.color}
              setSelectedColor={(color) => saveNewStatus('color', color)}
            />
          </div>
          <ConfirmIcon className="status-input-save" onClick={saveChangesHandler} />
        </div>
      )}
      <div className="status-list">
        {statuses?.map((status, i) => {
          return (
            <Draggable key={status.id} draggableId={status.id?.toString()} index={i}>
              {(provided) => (
                <div className="status-item" ref={provided.innerRef} {...provided.draggableProps}>
                  {!viewOnly && (
                    <span {...provided.dragHandleProps}>
                      <DragIcon />
                    </span>
                  )}
                  <div
                    className="status-content"
                    style={{
                      backgroundColor: `${status.color}80`,
                    }}
                  >
                    <div className="status-color" style={{ background: status.color }}></div>
                    <div className="status-name">{status.name}</div>
                  </div>

                  {!viewOnly && (
                    <div className="status-actions">
                      <Dropdown
                        menu={{
                          items: items(t),
                          onClick: (key) => {
                            if (key.key === '1') showEditInput(status)
                            else if (key.key === '2') deleteStatus(status.id)
                          },
                        }}
                        trigger={['click']}
                      >
                        <SettingsIcon />
                      </Dropdown>
                    </div>
                  )}
                </div>
              )}
            </Draggable>
          )
        })}
      </div>
      {isEdit && <div className="backdrop" onClick={() => setIsEdit(false)}></div>}
      {provided.placeholder}
    </div>
  )
}

export default StatusGroup
