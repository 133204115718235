import { store, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { ReactComponent as CustomFieldSVG } from '../../../assets/icons/tasks/customField.svg'
import { ReactComponent as CheckSVG } from '../../../assets/icons/tasks/check.svg'
import { ReactComponent as ArrowUpSVG } from '../../../assets/icons/tasks/arrow-up.svg'
import { ReactComponent as ArrowDownSVG } from '../../../assets/icons/tasks/arrow-down.svg'
import { ReactComponent as SortSVG } from '../../../assets/icons/tasks/sort-list.svg'
import { Dropdown, Tooltip } from 'antd'
import { fieldItems } from './FieldItems'
import { useTranslation } from 'react-i18next'
import { column } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import {
  clearSort,
  hideColumn,
  moveColumnToEnd,
  moveColumnToSart,
  setHeaders,
  sortByField,
} from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { Resizable } from 're-resizable'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import CustomFieldsSidebar from '../CustomFieldsSidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { updateURL } from '@src/modules/tasks/utils/updateURL'
import {
  useDeleteCustomFieldsMutation,
  useGetCustomFieldsByListQuery,
} from '@src/modules/tasks/services/customFieldsApi'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons'

const excludeSortFields = ['Timer']

const renderSortIcons = (field: column) => {
  return (
    <Tooltip title="Sort">
      {field?.sortBy > 0 ? (
        <div
          className="sort-column"
          onClick={(e) => {
            e.stopPropagation()
            store.dispatch(sortByField(field.id))
          }}
        >
          {field.sortBy}
          {field.sortOrder === 'ASC' ? <ArrowUpSVG /> : <ArrowDownSVG />}
        </div>
      ) : (
        <SortSVG
          className="list-view-column-sort"
          width={17}
          height={17}
          color="#afafaf"
          onClick={(e) => {
            e.stopPropagation()
            store.dispatch(sortByField(field?.id))
          }}
        />
      )}
    </Tooltip>
  )
}

const formatItems = (items, field) => {
  return items.map((item) =>
    (item?.key === '0' || item?.key === '3') && field?.sortBy
      ? {
          ...item,
          label: (
            <div className="list-header-dropdown-item">
              {item?.label} <CheckSVG />
            </div>
          ),
        }
      : item,
  )
}

interface ListHeaderProps {
  withResize?: boolean
  withDnd?: boolean
  withSort?: boolean
}

const ListHeader = ({ withResize = true, withDnd = true, withSort = true }: ListHeaderProps) => {
  //   const { t } = useTranslation(['customFields'])
  const { listId } = useParams()
  const { headers } = useAppSelector((state) => state.tasks)
  const { isLoading } = useGetCustomFieldsByListQuery(listId, {
    skip: !listId,
    refetchOnMountOrArgChange: false,
  })

  // const [updateCustomField] = useUpdateCustomFieldMutation()
  const [deleteCustomField] = useDeleteCustomFieldsMutation()

  const { t } = useTranslation()
  const { t: customFieldTranslation } = useTranslation(['customFields'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const actions = {
    sort: (field: column) => dispatch(sortByField(field.id)),
    entireSort: (field: column) => console.log('entire Sort with ', field.field),
    group: (field: column) =>
      (field?.field === 'priority' || field?.field === 'assignee') &&
      navigate(
        updateURL({
          label: 'groupBy',
          value: field?.field === 'priority' ? 2 : 1,
        }),
      ),
    moveToStart: (field: column) => dispatch(moveColumnToSart(field.id)),
    moveToEnd: (field: column) => dispatch(moveColumnToEnd(field.id)),
    hide: (field: column) => dispatch(hideColumn(field.id)),
    clearSort: (field: column) => dispatch(clearSort(field.id)),
    updateField: (field: column) => console.log('update field', field),
    deleteField: (field: column) => deleteCustomField({ id: field.id }),
  }

  const onDragEnd = (result) => {
    if (!result.destination) return
    const updatedColumns = Array.from(headers)
    const [movedColumn] = updatedColumns.splice(result.source.index, 1)
    updatedColumns.splice(result.destination.index, 0, movedColumn)
    dispatch(setHeaders(updatedColumns))
  }

  const onResizeStop = (ref, index) => {
    const newWidth = Math.max(parseInt(ref.style.width.replace('px', ''), 10), 100)
    const updatedColumns = [...headers]
    updatedColumns[index] = { ...updatedColumns[index], width: newWidth }
    dispatch(setHeaders(updatedColumns))
  }
  const splitText = (text, length) => {
    if (!text) return ''
    const words = text.split(' ')
    const modifiedWords = words.map((word) => {
      if (word.length > length) {
        const middleIndex = Math.floor((2 * word.length) / 3)
        return word.slice(0, middleIndex) + '-' + word.slice(middleIndex)
      }
      return word
    })
    return modifiedWords.join(' ')
  }

  return (
    <div className="list-veiw-header task-list">
      {!isLoading && (
        <>
          <div className="col1">
            <div className="task-row-wrapper list-view-column">
              <div className="task-row-wrapper-content">
                <div className="task-list-inner">
                  <div className="task-list-inner-content">
                    <p>{customFieldTranslation('name')}</p>
                    <span onClick={() => dispatch(sortByField(headers[0]?.id))}>
                      {renderSortIcons(
                        headers?.find((field) => field?.fieldName?.toLowerCase() === 'name'),
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-columns" direction="horizontal">
              {(provided) => {
                return (
                  <div
                    className="list-view"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: 'flex' }}
                  >
                    {headers?.map((field, index) => {
                      const customStyle = {
                        width: field?.width,
                        minWidth: field?.minWidth,
                        maxWidth: field?.maxWidth,
                        height: '25px',
                        x: 0,
                        y: 0,
                      }

                      const icon = CustomFieldsIcons?.find(
                        (customField) => customField?.field === field?.fieldType,
                      )?.icon

                      const allowedSort = !excludeSortFields.includes(field?.fieldName)

                      if (field?.disable || field.hidden) return null

                      return (
                        <Draggable
                          key={field.id}
                          draggableId={field.id.toString()}
                          index={index}
                          isDragDisabled={!withDnd}
                        >
                          {(draggableProvided) => {
                            return (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className="list-view-column"
                              >
                                <Resizable
                                  size={{ width: field.width, height: 'auto' }}
                                  enable={
                                    withResize
                                      ? {
                                          right: false,
                                          left: true,
                                          top: false,
                                          bottom: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                        }
                                      : false
                                  }
                                  handleClasses={{ left: 'resize-handle' }}
                                  minWidth={field.minWidth || 100}
                                  maxWidth={field.maxWidth}
                                  onResizeStop={(_1, _, ref) => onResizeStop(ref, index)}
                                >
                                  <div className="list-view-column">
                                    <Dropdown
                                      disabled={!withSort}
                                      menu={{
                                        items: formatItems(
                                          fieldItems(t, field, actions, headers, allowedSort),
                                          field,
                                        ),
                                      }}
                                      placement="bottomCenter"
                                      trigger={['click']}
                                      rootClassName="list-view-column-dropdown"
                                    >
                                      <div
                                        className={`list-view-column ${field?.sortBy > 0 ? 'active-field' : ''}`}
                                        style={customStyle}
                                      >
                                        <Tooltip
                                          title={
                                            field.custom ? (
                                              <div className="custom-field-tooltip">
                                                <span>
                                                  {icon} {splitText(field?.fieldName, 30)}
                                                </span>
                                                <span>{splitText(field?.description, 50)}</span>
                                              </div>
                                            ) : null
                                          }
                                        >
                                          <p {...draggableProvided.dragHandleProps}>
                                            {customFieldTranslation(field?.fieldName)}
                                          </p>
                                        </Tooltip>
                                        {withSort && allowedSort && renderSortIcons(field)}
                                      </div>
                                    </Dropdown>
                                  </div>
                                </Resizable>
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
          <Dropdown
            dropdownRender={() => <CustomFieldsSidebar />}
            trigger={['click']}
            placement={'topCenter'}
            rootClassName="custom-fields"
          >
            <div className="list-view-column list-view-more-column">
              <span>
                <CustomFieldSVG />
              </span>
            </div>
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default ListHeader
