import { getDefaultReactSlashMenuItems } from '@blocknote/react'

import CodeBlock from './components/CodeBlock/CodeBlock'
import GoogleDocsBlock from './components/GoogleDocsBlock/GoogleDocsBlock'
import GoogleDriveBlock from './components/GoogleDriveBlock/GoogleDriveBlock'
import GoogleSheetsBlock from './components/GoogleSheetsBlock/GoogleSheetsBlock'
import GoogleSlidesBlock from './components/GoogleSlidesBlock/GoogleSlidesBlock'
import IframeBlock from './components/IframeBlock/IframeBlock'
import VideoBlock from './components/VideoBlock/VideoBlock'

import {
  insertCode,
  insertGoogleDrive,
  insertIframe,
  insetGoogleDocs,
  insetGoogleSheets,
  insetGoogleSlides,
} from './CustomSlashMenu'
import { SoftyNote, useSoftyNoteStore } from 'tk-note'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import createMentionPlugin, { ELEMENT_MENTION, MentionElement } from './MentionComponentItem'
import { TransformedData } from './ParseDataBlockNoteToSofyEditor'
import MentionsList from './components/MentionBlock/MentionsList'
import { uploadFile } from '@src/modules/shared/components/BlockNote/utils/uploadFileBlockNote.tsx'
import createMentionDocPlugin from './components/MentionDocs'
import { ELEMENT_DOC_MENTION, MentionDocElement } from './components/MentionDocs/MentionDocsItem'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice.ts'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
// import { deserializeMd } from '@udecode/plate-serializer-md'

type BlockNoteEditorProps = {
  value?: string
  setValue?: Function
  isGlobalCreateTaskPopup?: boolean
  onUplaod?: (l: any) => void
  onlyView?: boolean
  insert?: boolean
  onDrop?: (v: any) => void
  onPaste?: (v: any) => void
  isIconAttachementActive?: boolean
  setIsIconMentionActive?: Function
  setIsUploading?: (l: boolean) => void
  emoji?: string
  setEmoji?: Function
  isIconImageSelected?: boolean
  setIsIconImageSelected?: Function
  isUploading?: boolean
  reset?: boolean
  focus?: boolean
  setReset?: (value: boolean) => void
  uploadAttachements?: (fiel: any) => void
}

// const isMarkdown = (string: string) => {
//   const markdownPatterns = [
//     /(?:__|[*#])|\[(.*?)\]\((.*?)\)/,
//     /!\[(.*?)\]\((.*?)\)/,
//     /^#{1,6}\s/,
//     /(\*\*|__)(.*?)\1/,
//     /(\*|_)(.*?)\1/,
//     />\s.+/,
//     /[-*]\s+.+/,
//     /\d+\.\s+.+/,
//     /`{1,3}[^`](.*?)`{1,3}/,
//   ]

//   return markdownPatterns.some((pattern) => pattern.test(string))
// }

export function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const blockSpecs = {
  video: VideoBlock,
  googleDocs: GoogleDocsBlock,
  googleSheets: GoogleSheetsBlock,
  googleSlides: GoogleSlidesBlock,
  googleDrive: GoogleDriveBlock,
  iFrame: IframeBlock,
  codeBlock: CodeBlock,
}
export const customSlashMenuItemList = [
  ...getDefaultReactSlashMenuItems(),
  insetGoogleDocs,
  insetGoogleSheets,
  insetGoogleSlides,
  insertGoogleDrive,
  insertIframe,
  insertCode,
]

const BlockNoteRichText = ({
  value,
  setValue,
  onlyView,
  emoji,
  isIconAttachementActive,
  isIconImageSelected,
  setIsUploading,
  reset,
  setReset,
  focus,
  onUplaod,
  uploadAttachements,
}: BlockNoteEditorProps) => {
  const { t } = useTranslation(['richText'])
  const editorRef = useRef<any | null>(null)
  useEffect(() => {
    if (isIconAttachementActive != undefined && editorRef.current) {
      const mention = { type: 'upload-file', children: [{ text: '' }], id: 'y78y7' }
      editorRef?.current?.editor?.insertNode(mention)
    }
  }, [isIconAttachementActive])

  useEffect(() => {
    if (isIconImageSelected != undefined && editorRef.current) {
      const Upload = { type: 'upload-image', children: [{ text: '' }] }
      editorRef?.current?.editor?.insertNode(Upload)
    }
  }, [isIconImageSelected])
  useEffect(() => {
    if (emoji && editorRef.current) {
      editorRef?.current?.editor?.insertText(emoji)
    }
  }, [emoji])

  const [content, setContent] = useState(
    value != '' && isJsonString(value) ? JSON.parse(value) : null,
  )

  useEffect(() => {
    // if (value && isMarkdown(value)) {
    //   const parser = deserializeMd(editorRef.current.editor, value)
    //   setContent(parser)
    //   return
    // }

    if (value != '' && isJsonString(value))
      setContent(value != '' && isJsonString(value) ? JSON.parse(value) : null)
  }, [value])

  const handleChange = useCallback((value) => {
    setContent(value)
    setValue(JSON.stringify(value))
  }, [])

  const handleUpload = useCallback(
    async (file) => {
      setIsUploading?.(true)
      try {
        const result = uploadAttachements
          ? await uploadAttachements(file)
          : await uploadFile(file, onUplaod)
        return result?.data ? result?.data?.payload?.[0].rawLink : result
      } catch (error) {
        message.error(t('error_uploading_file'))
        console.error(t('error_uploading_file'), error)
      } finally {
        setIsUploading?.(false)
      }
    },
    [uploadFile],
  )

  useEffect(() => {
    if (reset) editorRef?.current?.editor?.reset()
    setReset?.(false)
  }, [reset])
  const { setFileSelected, fileSelected } = useSoftyNoteStore()
  const getFileType = (fileSelected) => {
    if (fileSelected?.type == 'img') return 'image'
    if (fileSelected?.type === 'upload-file' && fileSelected.url?.toLowerCase().includes('.pdf'))
      return 'pdf'
    return 'other'
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (
      typeof fileSelected?.url === 'string' &&
      fileSelected?.url?.trim() &&
      getFileType(fileSelected) !== 'other'
    ) {
      dispatch(
        openModal({
          id: 'media-player',
          data: {
            withSwiper: false,
            attachements: [
              {
                rawLink: fileSelected?.url,
                mimetype: getFileType(fileSelected),
                location: fileSelected?.url,
                originalname: 'file',
              },
            ],
          },
        }),
      )
      setFileSelected('', '')
    }
  }, [fileSelected])

  const initialValue = useMemo(() => {
    // if (isMarkdown(content) && editorRef.current?.editor) {
    //   return deserializeMd(editorRef.current.editor, content)
    // }

    if (content && Array.isArray(content) && content[0]?.id?.length > 12) {
      return TransformedData(content, setContent)
    }
    return content ? content : [{ children: [{ text: '' }], type: 'p' }]
  }, [])

  const newPlugins = useMemo(() => {
    return [
      {
        element: MentionElement,
        key: ELEMENT_MENTION,
        plugin: createMentionPlugin,
      },
      {
        element: MentionDocElement,
        key: ELEMENT_DOC_MENTION,
        plugin: createMentionDocPlugin,
      },
      // {
      //   element: MentionDocElement,
      //   key: ELEMENT_DOC_MENTION,
      //   plugin: MarkdownPlugin,
      // },

      //   element: UploadFileElement,
      //   key: ELEMENT_UPLOAD_FILE,
      //   plugin: createUploadFilePlugin,
      // },
    ]
  }, [])

  return (
    <SoftyNote
      onUpload={handleUpload}
      onChange={handleChange}
      initialValue={initialValue}
      value={initialValue}
      editorClassName="editor"
      readOnly={onlyView}
      ref={editorRef}
      MentionComponentItem={MentionsList}
      autoFocus={focus}
      mentionComponentClassName="dropDown"
      slashComponentClassName="slashComponent"
      slashItemClassName="slashItem"
      floatingToolbarClassname="toolbar"
      NewPlugins={newPlugins}
    />
  )
}

export default React.memo(BlockNoteRichText)
