import { TaskType } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { defaultOpenStatus } from '../ListView'

export const getStateViewByTree = (view, treeId: string, stateLabel: string) => {
  return view[treeId][stateLabel]
}

export const setNewStatusTree = (
  item,
  newTree: string,
  parentRoot,
  tree,
  groupBy = { id: 'status', type: GroupByFields.STATUS, custom: false },
) => {
  const fieldToUpdate = groupBy?.custom ? 'customFieldsValues' : groupBy?.type

  if (parentRoot) {
    let targetDataByGroup =
      groupBy?.type === GroupByFields.ASSIGNEE
        ? tree[newTree]?.content?.user_ids?.map(({ id, username }) => ({ id, fullName: username }))
        : groupBy?.type === GroupByFields.PRIORITY
          ? tree[newTree]?.content?.id
          : tree[newTree]?.content

    if (groupBy?.custom) {
      const targetTree = tree[newTree]?.content
      targetDataByGroup = item?.content?.customFieldsValues?.map((field) => {
        if (field.customFieldId === groupBy?.id) return { ...field, value: targetTree?.value }
        else return field
      })
    }

    return {
      ...item,
      content: { ...item?.content, [fieldToUpdate]: targetDataByGroup },
    }
  } else return item
}

export const addSkeletons = (item, setData) => {
  setData((prev) => {
    return {
      ...prev,
      [item?.id]: { ...prev[item?.id], isLoading: true },
    }
  })
}

export const removeSkeletons = (id: string, setData) =>
  setData((tree) => ({
    ...tree,
    [id]: { ...tree[id], isLoading: false },
  }))

export const reformulateTrees = (statuses, savedTrees = {}) => {
  const tree = {}
  statuses?.forEach((status) => {
    tree[status?.id] = {
      index: status?.id,
      children: [],
      content: status,
      isOpen: defaultOpenStatus,
    }
  })

  return { ...tree, ...savedTrees }
}

export const reformulateTreeViewState = (statuses) => {
  const viewStates = {}
  statuses?.forEach((status) => {
    if (viewStates?.[status?.id])
      viewStates[status?.id] = {
        focusedItem: [],
        expandedItems: [],
        selectedItems: [],
      }
  })
  return viewStates
}

export const reformulateTasksCountState = (trees, savedCount) => {
  const tasksCount = {}
  trees?.forEach((tree) => {
    tasksCount[tree?.id] = { currentPage: 1 }
  })
  return { ...tasksCount, ...savedCount }
}

export const generateFakeTask = (
  id: string,
  parentId: string,
  isTop: boolean,
  isBottom: boolean,
) => {
  return {
    index: id,
    id,
    parentId,
    canMove: false,
    isTop,
    isBottom,
    isFolder: false,
    data: id,
  }
}

export const clearExpandedItems = (trees, setExpandedItems, getItemById: (id: string) => any) => {
  Object.keys(trees)?.map((treeId) => {
    const tree = trees[treeId]
    const newExpandedItems = tree?.expandedItems?.filter(
      (id: string) => getItemById(id)?.content?.totalSubtask !== 0,
    )
    setExpandedItems(newExpandedItems, treeId)
  })
}

export const convertTasksToTemplateTree = (
  tasks: TaskType[],
  getTaskChildrens,
  groupBy: string = GroupByFields.STATUS,
) => {
  let data = {}
  tasks?.forEach((task) => {
    const parentId =
      groupBy === GroupByFields.STATUS
        ? task?.parentId || task?.status?.id
        : groupBy === GroupByFields.PRIORITY
          ? task.priority
          : task.users
              ?.map(({ id }) => id)
              .reverse()
              .join('&')

    data[task?.id] = {
      index: task?.id,
      id: task?.id,
      parentId,
      canMove: true,
      isFolder: true,
      children: getTaskChildrens(task?.id) || undefined,
      data: task?.id,
      content: task,
      canRename: true,
    }
  })

  return data
}

export const mergeFakeTask = (items: string[], fakeTask) => {
  if (fakeTask) {
    if (fakeTask?.isTop) return ['fake-task', ...items]
    else if (fakeTask?.isBottom) return [...items, 'fake-task']
  } else return items
}

export const getTasksChildrenIds = (item) => {
  return item?.children?.filter((id: string) => id !== 'fake-task')
}

export const removeDuplicate = (tasks: string[]) => {
  return [...new Set(tasks)]
}
