import { Dropdown, Tooltip } from 'antd'
import { useState } from 'react'
import StatusList from './StatusList'
import { addOpacity } from '@src/modules/shared/utils/addOpacity'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { useGetStatausQuery } from '@src/modules/spaces/services/fileApi'
import { useTranslation } from 'react-i18next'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { IStatus } from '@softylines/tam-types'

interface TaskStatusProps {
  activeStatus: IStatus
  onSelect?: (s: statusType) => void
  listId?: string
  disable?: boolean
  displayLabel?: boolean
  children?: any
  placement?: 'bottomRight' | 'bottomCenter'
  displayIcon?: boolean
}

const TaskStatus = ({
  activeStatus,
  disable = false,
  onSelect,
  listId,
  placement = 'bottomRight',
  displayLabel = true,
  displayIcon = true,
  children,
}: TaskStatusProps) => {
  const { t } = useTranslation(['taskDetails'])
  const { t: taskTranslation } = useTranslation(['tasks'])
  const { data: statuses } = useGetStatausQuery(
    {
      hierarchyType: 'fileId',
      entityUuid: listId,
    },
    {
      skip: !listId,
    },
  )
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const handleCloseAllDropdowns = () => {
    setDropdownVisible(false)
  }

  const DropdownChangeEvent = async (open: boolean) => {
    setDropdownVisible(open)
  }

  return (
    <div
      className="task-status"
      onClick={(e) => e.stopPropagation()}
      style={disable ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
    >
      <Tooltip title={disable ? t('shoud_select_list_first') : ''}>
        <Dropdown
          open={dropdownVisible}
          placement={placement}
          destroyPopupOnHide
          disabled={disable || !activeStatus}
          dropdownRender={() => (
            <StatusList
              data={{
                statuses: statuses || [],
                activeItemID: activeStatus?.id,
                isLoading: false,

                onSelect,
              }}
              handleCloseAllDropdowns={handleCloseAllDropdowns}
            />
          )}
          trigger={['click']}
          onOpenChange={DropdownChangeEvent}
        >
          {children || (
            <div
              className="task-status-active"
              style={{ backgroundColor: displayLabel && addOpacity(activeStatus?.color) }}
            >
              <div className="icon" style={{ background: activeStatus?.color }}></div>
              {displayLabel && (
                <OverflowTooltip
                  children={taskTranslation(activeStatus?.name) || '-'}
                  className="label"
                />
              )}
              {displayIcon && (
                <div className="dropdown-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    style={{
                      transform: dropdownVisible ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    <path
                      d="M4.75657 6.04932L8.47106 1.59193C8.90528 1.07087 8.53475 0.279785 7.85648 0.279785L1.27255 0.279785C0.594277 0.279785 0.223752 1.07087 0.657969 1.59193L4.37246 6.04932C4.47241 6.16926 4.65662 6.16926 4.75657 6.04932Z"
                      fill={activeStatus?.color}
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          )}
        </Dropdown>
      </Tooltip>
    </div>
  )
}

export default TaskStatus
