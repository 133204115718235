import { getData } from '@src/modules/notifications/data/NotificationsSlice'
import { store } from '@src/modules/shared/store'
import { message } from 'antd'
import { io, Socket } from 'socket.io-client'
import { setUsersActivity } from '@src/modules/settings/data/ActiveUsers/activeUsersSlice'
import { events } from './events'

class SocketManager implements ISocketManager {
  private socket: Socket | null
  token: string | null
  private connectedRoomId: string | null
  private connectedWorkSpaceId: string | null

  constructor() {
    this.socket = null
    this.connectedWorkSpaceId = null
    this.token = localStorage.getItem('access_token')
    this._connectSocket()
  }

  public _connectSocket(): void {
    this.connectedRoomId &&
      console.log('Should Leave from the previous room with ID', this.connectedRoomId)
    const token = localStorage.getItem('access_token')
    if (this.socket || !token) return

    const socket = io(import.meta.env.VITE_APP_SOCKET_BASE_URL, {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })

    this.socket = socket
    socket.on('connect', () => {
      this.socket = socket
      this._membersManagement()
      this._versionManagment()
    })

    socket.on('error', (e) => {
      console.error('socket error', e)
    })
  }

  public _joinRoom(listId: string): void {
    if (!this.socket) return

    this.socket.emit('joinRoom', listId)
    this.connectedRoomId = listId

    this._versionManagment()
  }

  private _versionManagment() {
    if (!this.socket) return
    this.socket.on('VERSION', (version) => {
      const currentVersion = localStorage.getItem('version')
      if (!version) {
        message.error('no version come from socket!!')
      } else if (currentVersion !== version) {
        localStorage.setItem('version', version)
        if (currentVersion) window.location.reload()
      }
    })
  }

  public joinWorkSpace(workspaceId: string): void {
    if (!this.socket || !workspaceId || this.connectedWorkSpaceId === workspaceId) return
    this.socket.emit('joinWorkspace', workspaceId)
    this.connectedWorkSpaceId = workspaceId
    this._getNotifications()

    this.socket.on('notifRoom', (update) => {
      let { entity, action, data } = update || {}

      entity = entity?.toLowerCase()
      action = action?.toLowerCase()
      if (!events?.[entity] || !events?.[entity]?.[action]) return
      events[entity][action](data)
    })
  }

  public _updateDocument(payload: any) {
    if (!this.socket) return
    this.socket.emit('updateDocument', payload)
  }

  public _membersManagement(): void {
    if (!this.socket) return
    this.socket.on('userActive', (data) => {
      const currentDate = new Date()
      const lastActive = currentDate.toString()
      store.dispatch(setUsersActivity({ ...data, lastActive }))
    })
  }
  public connectUserRoom(userId): void {
    if (!this.socket) return
    this.socket.emit('joinRoom', userId)
  }
  public _getNotifications(): void {
    if (!this.socket) return
    this.socket.on('notifWorkspace', (data) => {
      store.dispatch(getData(data))
    })
  }

  public _getSocket(): Socket {
    if (this.socket) return this.socket
    else this._connectSocket()
  }

  public disconnect(): void {
    // if (this.socket) this.socket.emit('disconnect')
  }
}

const socketManager = new SocketManager()

export default socketManager

interface ISocketManager {
  token: string | null
  _connectSocket(): void
  _joinRoom(listId: string): void
  joinWorkSpace(workspaceId: string): void
  _updateDocument(payload: any): void
  _getSocket(): Socket
}
