import { useGetMembersQuery } from '@src/modules/auth/services/memberApi'
import { Avatar, Dropdown, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import { sidebarCloseAt } from '../../layout/MainLayout/MainLayout'
import { useAppDispatch, useAppSelector } from '../../store'
import { openModal } from '../../store/slices/modals/modalsSlice'
import { closeSidebar } from '../../store/slices/settings/settingsSlice'
import ArabicElement from '../ArabicSupport/ArabicSupport'
import { ReactComponent as InviteIcon } from './../../assets/icons/sidebar/invite.svg'
import { ReactComponent as AngleSVG } from './../../assets/icons/workspacePicker/angle.svg'
import { ReactComponent as IntergrationsSVG } from './../../assets/icons/workspacePicker/intergrations.svg'
import { ReactComponent as SettingsSVG } from './../../assets/icons/workspacePicker/settings.svg'
import { ReactComponent as WorkspacesSVG } from './../../assets/icons/workspacePicker/workspaces.svg'
import { Can } from '../../context/permissions/Can'

export const getCurrentWorkspace = (id: string, data: any) => {
  return data?.find((item: any) => item?.workspace?.id === id)
}

interface WorkspacePickerDropdown {
  workSpaces: any
  currentWorkSpace: any
}

const ids = ['6599bc23bda8ee00d6d7f39c', '65952244d634bb0ccdf57a93']

const WorkspacePickerDropdown = ({ workSpaces, currentWorkSpace }: WorkspacePickerDropdown) => {
  const { t } = useTranslation(['sidebar'])
  const { icon, name, theme, id } = currentWorkSpace || {}
  const plan = ids?.includes(id)
    ? t('WorkspacePickerDropdown.buisness_plan')
    : t('WorkspacePickerDropdown.free_plan')
  const navigate = useNavigate()
  const { data: members } = useGetMembersQuery({
    limit: 10,
  })

  return (
    <div className="workspace-picker-dropdown">
      <div className="workspace-picker-current">
        <Avatar src={icon} style={{ background: !icon && theme }} shape={'square'} size={'large'}>
          {name?.slice(0, 25) && name[0]?.slice(0, 25).toUpperCase()}
        </Avatar>

        <Tooltip title={name}>
          <p>{name?.length > 25 ? name?.slice(0, 25) + '...' : name?.slice(0, 25)}</p>
        </Tooltip>

        <p>
          {plan} |{' '}
          <span>
            {members?.metadata?.total_items} {t('settings.members')}
          </span>
        </p>
      </div>

      <div className="workspace-picker-settings">
        <Link to={'/settings/workspace'} className="settings-item">
          <SettingsSVG />
          <p>{t('settings.title')}</p>
        </Link>

        <Link to={'/settings/integrations'} className="settings-item">
          <IntergrationsSVG />
          <p>{t('settings.integrations')}</p>
        </Link>

        <Link to={'/settings/manage-workspaces'} className="settings-item">
          <WorkspacesSVG />
          <p>{t('settings.workspaces')}</p>
        </Link>
      </div>

      <div className="workspace-picker-list hovered-scrollbar">
        <p className="title">{t('WorkspacePickerDropdown.switch_workspaces')}</p>
        {workSpaces
          ?.filter((workspace) => workspace?.workspace?.id !== id)
          ?.map((workspace) => {
            const { icon, name, theme, id } = workspace?.workspace || {}
            const plan = ids?.includes(id)
              ? t('WorkspacePickerDropdown.buisness_plan')
              : t('WorkspacePickerDropdown.free_plan')

            return (
              <div
                className="workspace-picker-list-item"
                key={id}
                onClick={() => {
                  navigate(`/select-workspace/${id}`)
                  localStorage.setItem('changedWorkspace', 'yes')
                }}
              >
                <Avatar src={icon} style={{ background: !icon && theme }}>
                  {name && name[0]?.toUpperCase()}
                </Avatar>
                <div className="workspace-info">
                  <ArabicElement
                    tag="p"
                    content={`${name?.length > 25 ? name?.slice(0, 25) + '...' : name?.slice(0, 25)}`}
                  />
                  <p>{plan}</p>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const WorkspacePicker = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { user } = useAppSelector((state) => state.auth)
  const { workspace: currentWorkSpace } =
    user?.currentWorkspace ||
    getCurrentWorkspace(user?.lastWorkspaceUsed, user?.user?.workspaces) ||
    {}

  return (
    <div className="workspace-picker">
      <Dropdown
        trigger={['click']}
        dropdownRender={() => (
          <WorkspacePickerDropdown
            workSpaces={user?.user?.workspaces}
            currentWorkSpace={currentWorkSpace}
          />
        )}
      >
        <div className="current-workspace">
          <Avatar
            size={'small'}
            style={{ background: currentWorkSpace?.icon ? 'transparent' : currentWorkSpace?.color }}
            src={currentWorkSpace?.icon}
          >
            {currentWorkSpace?.name && currentWorkSpace?.name[0]?.toUpperCase()}
          </Avatar>
          <ArabicElement content={currentWorkSpace?.name} tooltip tag="p" />
          <AngleSVG />
        </div>
      </Dropdown>

      <Can I="create" a="users">
        <div
          className="invite-button"
          onClick={() => {
            dispatch(openModal({ id: 'invite-modal' }))
            width < sidebarCloseAt && dispatch(closeSidebar())
          }}
        >
          <InviteIcon />
        </div>
      </Can>
    </div>
  )
}

export default WorkspacePicker
