import ViewTitle from '@src/modules/shared/components/ViewTitle'
// import { ReactComponent as SearchIcon } from './../../assets/icons/header/search.svg'
import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { useAppDispatch } from '@src/modules/shared/store'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { Avatar } from 'antd'
import { useParams } from 'react-router-dom'
import folder from '../../assets/icons/folder/folder-icon.svg'
import lock from '../../assets/icons/header/lock.svg'
import list from '../../assets/icons/header/list.svg'
import { useGetListQuery } from '../../services/fileApi'
import { ReactComponent as ShareIcon } from './../../assets/icons/header/share.svg'
import { ReactComponent as StarIcon } from './../../assets/icons/header/star.svg'
import { useTitle } from '@src/modules/shared/hooks/useTitle'

const renderIcon = (item) => {
  switch (item.type) {
    case 'SPACE':
      return (
        <Avatar
          style={{ background: item?.icon ? 'transparent' : item?.color }}
          src={item?.icon}
          className="avatar_path"
        >
          {item?.name && item?.name?.slice(0, 1)}
        </Avatar>
      )

    case 'FOLDER':
      return <img src={folder} />
  }
}

const getPathString = (path) => {
  return path?.map((item: any) => item?.name).join(' / ')
}

export const renderPath = (path) => {
  return path?.map((item: any, i: number) => {
    {
      return (
        <span key={i} className="path_items">
          <span className="path_items">
            {renderIcon(item)}

            <ArabicElement tag="span" content={item?.name} />

            {item?.isPrivate && <img src={lock} />}
          </span>

          {i !== path.length - 1 && <span> {' / '} </span>}
        </span>
      )
    }
  })
}

function Header() {
  const dispatch = useAppDispatch()
  const { listId } = useParams()
  const { data } = useGetListQuery({ id: listId }, { skip: !listId })
  const path = [...(data?.path || [])].reverse()
  if (data) useTitle(`${getPathString(path)} / ${data?.name}`)

  return (
    <ViewTitle sidebarID="space-sidebar">
      <div className="header">
        <div className="header-start">
          {renderPath(path)}
          {data && (
            <>
              <img src={list} />
              <ArabicElement tag="span" content={data?.name} />
            </>
          )}
        </div>

        <div className="header-end">
          {/* <span>
            <SearchIcon />
          </span> */}

          <span style={{ cursor: 'not-allowed' }}>
            <StarIcon />
          </span>

          <span
            style={!listId ? { cursor: 'not-allowed' } : {}}
            onClick={() =>
              listId &&
              dispatch(openModal({ id: 'share-modal', data: { ...data, type: 'fileId' } }))
            }
          >
            <ShareIcon />
          </span>
        </div>
      </div>
    </ViewTitle>
  )
}

export default Header
