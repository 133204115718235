import { ReactComponent as CercelClock } from '../assets/icons/cercel-clock.svg'
import { Input } from '@src/modules/shared/components/Input'
import { useTranslation } from 'react-i18next'
const ManualContent = ({ hours, setHours, minutes, setMinutes }) => {
  const { t } = useTranslation(['timer'])

  return (
    <div className="timer-dropdown-content-header-tabs-content-timer">
      <div className="timer-dropdown-content-header-tabs-content-timer-dropdown-search">
        <CercelClock />
        <Input
          maxLength={2}
          placeholder="00"
          type="number"
          value={hours}
          onChange={(e) => setHours(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
        {t('hours')}
        <Input
          placeholder="00"
          maxLength={2}
          max={99}
          type="number"
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
        {t('minutes')}
      </div>

      <div className="timer-dropdown-content-header-tabs-content-footer">
        <div className="timer-dropdown-content-header-tabs-content-footer-when">
          {t('when')}
          <span className="timer-dropdown-content-header-tabs-content-footer-when-now">
            {t('now')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ManualContent
