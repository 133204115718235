import { useCallback } from 'react'
import Input from '@src/modules/shared/components/Input/Input'
import debounce from 'lodash/debounce'

const NameFilter = ({ onData, disable }) => {
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onData(value)
    }, 500),
    [onData],
  )

  return (
    <div className="name-filter">
      <Input
        type="text"
        placeholder="Filter By Name"
        disabled={disable}
        onChange={(e) => debouncedOnChange(e.target.value)}
        variant="label-inline"
        size="xs"
        containerClassName="name-filter-input"
      />
    </div>
  )
}

export default NameFilter
