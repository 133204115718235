import Button from '@src/modules/shared/components/Button/Button'
import { useFormik } from 'formik'
import { SpaceStepProps } from '../Modals/SpaceModal/SpaceModal'

import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import { FileUpload } from '@src/modules/shared/components/FileUpload'
import { colors } from '@src/modules/auth/features/CreateWorkspace/CreateWorkspace'
import { useState } from 'react'
import { fileToObjectURL } from '@src/modules/shared/utils/fileToObjectURL'
import * as Yup from 'yup'
import { useAppDispatch } from '@src/modules/shared/store'
import { closeModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { updateSpace } from '../../data/spaceSlice/spacesThunk'
import { Avatar, message } from 'antd'
import { uploadFile } from '@src/modules/shared/utils/uploadFile'
import { setSpacesSocketEvent } from '../../data/spaceSlice/spacesSlice'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'

const BasicForm = ({ data, setNextStep, setData }: SpaceStepProps) => {
  const [uploadedImage, setUploadedImage] = useState<File | null>(data.avatar || null)
  const { t } = useTranslation(['modals'])

  const dispatch = useAppDispatch()
  const icon = uploadedImage ? fileToObjectURL(uploadedImage) : data?.icon ? data.icon : null
  const color = data.selectedColor || data.color || colors[0]

  const initialValues = {
    name: data?.name || '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(3, t('space.Name must contains at least 3 caracters'))
        .required(t('space.name_validation')),
    }),

    onSubmit: async (values) => {
      if (data?.isEdit) {
        const { selectedColor, color, icon, avatar } = data || {}
        let path = null
        if (uploadedImage) {
          const { location } = (await uploadFile(uploadedImage)) || {}
          path = location
        }
        const updateObject = {
          id: data.id || '',
          color: selectedColor || color,
          icon: path || icon || avatar,
        }
        dispatch(updateSpace(updateObject))
          .unwrap()
          .then(() => {
            dispatch(closeModal({ id: 'space-modal' }))
            dispatch(
              setSpacesSocketEvent({
                updateType: 'updatedItem',
                updateEvent: { ...data, ...updateObject },
              }),
            )
          })
          .catch((err) => {
            message.error(err.message || t('space.Error while update space color'))
          })
      } else if (setData) {
        setData((data: any) => {
          return { ...data, ...values, avatar: uploadedImage }
        })
        setNextStep()
      }
    },
  })

  return (
    <div className="ta-form space-form">
      <form onSubmit={formik?.handleSubmit}>
        {!data.isEdit ? (
          <Input
            type="text"
            name="name"
            variant="filled"
            status={formik.errors.name ? 'error' : 'success'}
            label={t('space.name')}
            placeholder={t('space.placeholder_name')}
            size="md"
            formik={formik}
          />
        ) : null}

        <div className="space-form-upload">
          {!data.isEdit ? <div className="label">{t('space.style')}</div> : null}

          <div className="space-icon-color">
            <div className="color-icon">
              <ColorSelect
                disabled={false}
                title={t('space.placeholder_style')}
                colors={colors}
                selectedColor={data?.selectedColor || data?.color}
                setSelectedColor={(c) =>
                  setData((data: any) => {
                    return { ...data, selectedColor: c }
                  })
                }
              />

              <div className="differ">{t('space.or')}</div>

              <FileUpload
                label={t('space.upload_image')}
                handleFile={(f: File) => setUploadedImage(f)}
                disabled={false}
                className="create-space-upload"
              />
            </div>

            <div className="icon">
              <Avatar
                src={icon}
                shape={'square'}
                alt={'Error'}
                style={{
                  background: !icon && color,
                }}
              >
                {(formik.values.name || 'E')[0]?.toUpperCase()}
              </Avatar>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <Button
            htmlType="reset"
            className="cancel-action"
            onClick={() => {
              formik.setValues(initialValues)
              setUploadedImage(null)
              dispatch(closeModal({ id: 'space-modal' }))
            }}
          >
            {t('space.cancel')}
          </Button>

          <Button htmlType="submit" className="confirm-action">
            {data.isEdit ? t('space.confirm') : t('space.next')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BasicForm
