import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyTasksSVG } from '../../assets/icons/task/empty-tasks.svg'

const EmptyTasks = () => {
  const { t } = useTranslation(['tasks'])

  return (
    <div className="empty-tasks">
      <div className="empty-tasks-icon">
        <EmptyTasksSVG />
      </div>
      <div className="empty-tasks-text">{t('no-matching-results-found')}</div>
    </div>
  )
}

export default EmptyTasks
