import { PlateElement, createPluginFactory } from '@udecode/plate-common'
import { Avatar, Badge, Dropdown } from 'antd'
import { useState } from 'react'
import { ReactComponent as EmailIcon } from '../../../shared/assets/icons/blockNote/email.svg'
import { ReactComponent as ClockIcon } from '../../../shared/assets/icons/blockNote/clock.svg'
import { useGetOneUserQuery } from '@src/modules/auth/services/userApi'
import { getTimeDifferenceFromDate } from '@src/modules/dailyQuestions/components/MemberView/utils/getTimeDifferenceFromDate'
import OverflowTooltip from '../OverflowTooltip'
import { useTranslation } from 'react-i18next'
import { openModal } from '../../store/slices/modals/modalsSlice'
import { useAppDispatch, useAppSelector } from '../../store'
//TODO
export const ELEMENT_MENTION = 'mention_tam'

const createMentionPlugin = createPluginFactory({
  key: ELEMENT_MENTION,
  isElement: true,
  isInline: true,
  isMarkableVoid: true,
  isVoid: true,
})
export default createMentionPlugin
export function MentionElement({ children, ...props }: any) {
  const dispatch = useAppDispatch()
  const { element } = props
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const { data } = useGetOneUserQuery({ id: element?.children[0]?.user_id })
  let lastActive = data?.payload?.lastActive

  const { usersActivity } = useAppSelector((state) => state.activeUsers)

  const userActivity = usersActivity?.find((member) => member?.id === data?.payload?.centralId)

  const isActive = userActivity?.isActive ?? data?.payload?.isActive
  lastActive = userActivity?.lastActive ?? lastActive

  const different = lastActive && getTimeDifferenceFromDate(lastActive, 'en')

  const { t } = useTranslation(['richText'])
  return (
    <PlateElement
      {...props}
      onClick={(e) => e.stopPropagation()}
      contentEditable={false}
      className="mention-compoent-item"
    >
      <Dropdown
        placement="bottomLeft"
        open={dropdownVisible}
        onOpenChange={() => setDropdownVisible(!dropdownVisible)}
        visible={dropdownVisible}
        overlay={
          <div className="dropDownMenu">
            <div>
              <Badge dot offset={[0, 0]} color={isActive ? '#12B76A' : '#FF8C22'}>
                <Avatar size={'small'} src={data?.payload?.avatar || ''}>
                  {(data?.payload?.email && data?.payload?.email?.toUpperCase()) || 'D'}
                </Avatar>
              </Badge>
              <span className="fullName">
                {data?.payload?.fullName
                  ? data.payload.fullName.length > 10
                    ? data.payload.fullName.substring(0, 10) + '...'
                    : data.payload.fullName
                  : t('user_deleted')}
              </span>
              {data && (
                <div className="about-user">
                  <div>
                    <EmailIcon />
                    <span style={{ maxWidth: '130px' }}>
                      <OverflowTooltip className="label" children={data?.payload?.email} />
                    </span>
                  </div>
                  <div>
                    <ClockIcon />
                    <span>last seen {different}</span>
                  </div>
                </div>
              )}
              {data && (
                <div
                  className="view-profile-button"
                  onClick={() =>
                    dispatch(openModal({ id: 'user-profile-drawer', data: data?.payload }))
                  }
                  aria-disabled
                >
                  <span>View profile</span>
                </div>
              )}
            </div>
          </div>
        }
        trigger={['hover']}
      >
        <p contentEditable={false} className={dropdownVisible ? 'MentIonContainer' : ''}>
          @
          {data?.payload?.fullName
            ? data?.payload?.fullName
            : element?.children?.[0]?.fullName || t('user_deleted')}
        </p>
      </Dropdown>

      <p className="children">{children}</p>
    </PlateElement>
  )
}
