import { useEffect, useState } from 'react'
import { ReactComponent as AngleIcon } from './../../assets/icons/invite/down.svg'
import { ReactComponent as InviteIcon } from './../../assets/icons/invite/role.svg'
import { ReactComponent as CheckIcon } from './../../assets/icons/invite/check.svg'
import { Dropdown, message } from 'antd'
import { useGetRolesQuery } from '../../services/rolesApi'
import { memberType } from '../MemberName/MemberName'
import { useUpdateMemberMutation } from '../../services/membersApi'
import { useTranslation } from 'react-i18next'
import { IRole, ITeam } from '@softylines/tam-types'
import { useAppDispatch } from '@src/modules/shared/store'
import { updateTeam } from '../../data/teams/TeamsThunk'

export interface RoleType extends IRole {
  color: string
}

interface RoleDropdownItemProps {
  role: RoleType
  isActive: boolean
}

const RoleDropdownItem = ({ role, isActive }: RoleDropdownItemProps) => {
  const { t } = useTranslation(['members'])

  return (
    <div className={`role-dropdown ${isActive ? 'active-role' : ''}`}>
      <div className="role-dropdown-header">
        <InviteIcon /> <span>{role?.name}</span> {isActive ? <CheckIcon /> : null}
      </div>
      <p>{role?.description || t('Role description')}</p>
    </div>
  )
}

const formatRoles = (
  rolesList: RoleType[],
  activeRoleID: string,
  onClick: (r: RoleType) => void,
) => {
  return rolesList.map((role, i: number) => {
    return {
      key: i.toString(),
      label: <RoleDropdownItem role={role} isActive={activeRoleID === role.id} />,
      onClick: () => onClick(role),
    }
  })
}

interface MemberRoleProps {
  role: any
  member?: memberType
  setTeamMemberRoleAction?: boolean
  teamId?: string
  team?: ITeam
  setDefaultRole?: (r) => void
  className?: string
  onClick?: (r: RoleType) => void
  isOwner?: boolean
  disabled?: boolean
  level?: number
  Icon?: any
}

const MemberRole = ({
  role,
  onClick,
  level,
  member,
  setTeamMemberRoleAction,
  disabled = false,
  setDefaultRole,
  Icon,
  teamId,
  team,
  ...props
}: MemberRoleProps) => {
  const { t } = useTranslation(['members'])
  let { data: roles } = useGetRolesQuery({ level })
  const [updateMember] = useUpdateMemberMutation()
  const [modelOpen, setModelOpen] = useState(false)
  roles = roles?.payload || []
  const [activeRole, setActiveRole] = useState<RoleType>(roles[1])
  const dispatch = useAppDispatch()

  let selectedRole =
    team?.roles?.map((role) => ({
      name: role?.name,
      user_id: role?.userId,
      role_id: role?.roleId,
    })) || []

  const onRoleChange = (role: RoleType) => {
    if (setTeamMemberRoleAction) {
      selectedRole = selectedRole?.map((el) => {
        if (el?.user_id === member?.id) return { ...el, role_id: role?.id, name: role?.name }
        else return el
      })
      dispatch(updateTeam({ id: teamId, query: { roles: selectedRole } }))
    }
    if (!team && member)
      updateMember({ id: member.id, roles: [role.id] })
        .unwrap()
        .then(() => {
          setActiveRole(role)
        })
        .catch((err) => {
          message.error(err?.data?.message || t('Error on update role'))
        })
    else if (onClick) {
      setActiveRole(role)
      onClick(role)
    }
  }

  useEffect(() => {
    if (roles) {
      const DefaultRole =
        roles.find((ro: RoleType) => ro.id === role || ro.name === role) ||
        roles.find((ro) => ro.isDefault) ||
        roles[1]

      setActiveRole(DefaultRole)
      if (setDefaultRole) setDefaultRole(DefaultRole)
      else onClick && onClick(DefaultRole)
    }
  }, [roles, role])

  const capitalizeFirstChar = (str) => {
    if (!str) return ''
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
  }

  const renderRoleIconColor = (role: string) => {
    switch (role) {
      case 'member':
        return '#9747FF'
        break

      case 'admin':
        return '#F51F45'
        break

      case 'Guest':
        return '#0BA5EC'
        break
    }
  }

  return (
    <Dropdown
      menu={{ items: formatRoles(roles, activeRole?.id, onRoleChange) }}
      trigger={['click']}
      onOpenChange={(open) => setModelOpen(open)}
      overlayClassName="roles-dropdown"
      disabled={disabled || props?.isOwner}
    >
      <div className={`member-role ${props.className}`}>
        <div
          className="member-role-icon"
          style={{ background: props.isOwner ? '#34A174' : renderRoleIconColor(activeRole?.name) }}
        ></div>

        <div className="member-role-label">
          {props?.isOwner ? 'Owner' : capitalizeFirstChar(activeRole?.name)}
        </div>

        {!props?.isOwner ? (
          <div>
            {modelOpen ? (
              Icon ? (
                <Icon style={{ transform: 'rotate(180deg)', transition: '300ms' }} />
              ) : (
                <AngleIcon style={{ transform: 'rotate(180deg)', transition: '300ms' }} />
              )
            ) : Icon ? (
              <Icon style={{ transform: 'rotate(360deg)', transition: '300ms' }} />
            ) : (
              <AngleIcon style={{ transform: 'rotate(360deg)', transition: '300ms' }} />
            )}
          </div>
        ) : null}
      </div>
    </Dropdown>
  )
}

export default MemberRole
