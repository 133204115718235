export const isValidFilter = (filters): boolean => {
  let error = false

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]

    const { field, operation, orm, targetFilter } = filter || {}

    if (!field) {
      error = true
    } else if (!operation) {
      error = true
    } else if (!orm) {
      error = true
    } else if (
      targetFilter === null ||
      targetFilter === undefined ||
      (Array.isArray(targetFilter) && targetFilter?.length === 0)
    ) {
      error = true
    }

    if (!field && !operation && !targetFilter) error = false

    if (error) break
  }

  return !error
}
