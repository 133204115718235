import ViewLayout from '@src/modules/shared/components/ViewLayout'
import Header from '../../components/Header'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '@src/modules/shared/components/Input/Input'
import { FileUpload } from '@src/modules/shared/components/FileUpload'
import { useState } from 'react'
import Avatar from '@src/modules/shared/components/Avatar/Avatar'
import { ColorSelect } from '@src/modules/shared/components/ColorSelect'
import Button from '@src/modules/shared/components/Button/Button'
import { RootState, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
// import { ReactComponent as TransferIcon } from '../../assets/icons/workspace/Return_light.svg'
// import { ReactComponent as ArrowIcon } from '../../assets/icons/workspace/Arrow.svg'
// import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { updateWorkspace } from '../../data/workspaceSlice/workspaceThunk'
import { message } from 'antd'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { fetchUser } from '../../../auth/context/AuthProvider'
import { getChangedValues } from '@src/modules/shared/utils/getChangedValuesFormik'
import { useTranslation } from 'react-i18next'
import { useTitle } from '@src/modules/shared/hooks/useTitle'

type FormValues = {
  workspaceName: string
  color: string
  icon: any
}

export const colors = [
  '#FFC98D',
  '#9777E8',
  '#00A2FE',
  '#FFE976',
  '#C1B332',
  '#97D856',
  '#3FABE8',
  '#96D9FF',
  '#B294FF',
  '#FFACDE',
]

const Workspace = () => {
  const { t } = useTranslation(['settings'])
  const { permissions } = useAppSelector((state) => state.permissions)
  const { user } = useAppSelector((state) => state.auth)
  const { status } = useAppSelector((state) => state.workspaceSetting)
  const [uploadedImage, setUploadedImage] = useState()
  const [previewImage, setPreviewImage] = useState<any | null>()
  const [selectedColor, setSelectedColor] = useState<any | null>(user?.currentWorkspace?.color)
  const dispatch = useAppDispatch()

  useTitle(t('Settings - Workspace Settings'))

  const headerProps = { title: t('general_settings') }

  const handleFileChange = (file: any) => {
    const maxFileSize = 1 * 1024 * 1024
    if (file) {
      if (file.size > maxFileSize) {
        message.error(t('upload_error_size'))
        return
      }

      setUploadedImage(file)

      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target) {
          const result = e.target.result as string
          setPreviewImage(result)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const initialValues: FormValues = {
    workspaceName: user?.currentWorkspace?.name || user?.currentWorkspace?.workspace?.name,
    color: user?.currentWorkspace?.color || user?.currentWorkspace?.workspace?.color,
    icon: user?.currentWorkspace?.icon || user?.currentWorkspace?.workspace?.icon,
  }

  const formik = useFormik({
    initialValues,

    validationSchema: Yup.object().shape({
      workspaceName: Yup.string()
        .required(t('validation.required_workspace'))
        .min(5, t('validation.nb_caracters'))
        .matches(/^[a-zA-Z][a-zA-Z0-9 ]*$/, t('validation.regex_name')),
    }),

    onSubmit: (values) => {
      values.icon = uploadedImage
      values.color = selectedColor
      const changedValues = getChangedValues(values, initialValues)

      if (
        changedValues?.color !== undefined ||
        changedValues?.icon !== undefined ||
        changedValues?.workspaceName !== undefined
      ) {
        dispatch(
          updateWorkspace({ id: user?.currentWorkspace?.workspace?.id, query: changedValues }),
        )
          .unwrap()
          .then(() => {
            message.success(t('update_with_success'))
            fetchUser()
          })
          .catch((err) => {
            message.error(err.message || t('error_while_updating'))
          })
      }
    },
  })
  const theme = useAppSelector((state: RootState) => state.theme.mode)
  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ height: '100%', background: theme === 'light' ? '#F2F7FA' : '#2d2f39' }}
      >
        <div
          className={`workspace-settings-container ${
            !permissions.can('update', 'workspaces') ? 'disable' : ''
          }`}
        >
          <div className="workspace-theme-avatar-container">
            <div className="workspace-name-input-container">
              <span>{t('name')}</span>

              <Input
                className="workspace-name-input"
                name="workspaceName"
                formik={formik}
                variant="underlined"
                placeholder={t('workspace_placeholder')}
                disabled={false}
                defaultValue={formik.values.workspaceName}
                type="text"
                status={formik.errors.workspaceName ? 'error' : 'success'}
              />
            </div>

            <div className="workspace-avatar-container">
              <div className="image-upload">
                <Avatar
                  text={formik?.values?.workspaceName || 'workspace'}
                  src={
                    previewImage !== undefined
                      ? previewImage
                      : user?.currentWorkspace?.workspace?.icon
                  }
                  color={formik?.values?.color || selectedColor || '#A6A6A6'}
                />

                <FileUpload
                  handleFile={handleFileChange}
                  label={t('upload_an_image')}
                  disabled={false}
                />
              </div>

              <div className="avatar-color">
                <ColorSelect
                  colors={colors}
                  disabled={false}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                />
              </div>
            </div>
          </div>

          <div className="save-button-container">
            <Can I="update" a="workspaces">
              <Button
                children={t('save')}
                className="save-button"
                htmlType={'submit'}
                disabled={status === 'loading'}
              />
            </Can>
          </div>
        </div>
      </form>
    </ViewLayout>
  )
}

export default Workspace
