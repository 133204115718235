import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { Dropdown, Switch, message } from 'antd'
import { useEffect, useState } from 'react'
import { hideColumn, setHeaders, showColumn } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import { CustomFieldsIcons, ICustomField } from '@src/modules/customFields/data/CustomFieldIcons'
import Button from '@src/modules/shared/components/Button/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Input from '@src/modules/shared/components/Input/Input'
import { Fields } from '@src/modules/customFields/components/CustomDrawer/CustomDrawer'
import { ReactComponent as ArrowLeftIcon } from '@src/modules/customFields/assets/arrowLeft-icon.svg'
import { ReactComponent as ArrowBottomIcon } from '@src/modules/customFields/assets/icon-bottom.svg'
import { useCreateCustomFieldMutation } from '@src/modules/tasks/services/customFieldsApi'
import { useParams } from 'react-router-dom'
import { defaultFieldsIcons } from '../utils/defaultFields'
import Search from '@src/modules/shared/components/Input/search'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

interface TabsProps {}

const FieldSettings = ({ field, setActiveField }) => {
  const { t } = useTranslation(['customFields'])
  const { listId } = useParams()

  const [createCustomField, { isLoading, isError }] = useCreateCustomFieldMutation()

  const initialValues = {
    fieldName: '',
    type: field?.field,
    fieldDescription: null,
    values: {},
  }

  const validationSchema = Yup.object().shape({
    fieldName: Yup.string()
      .matches(/\S/, t('invalid_field_name'))
      .required(t('field_name_is_required')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!listId) return

      createCustomField({
        name: values?.fieldName,
        description: values?.fieldDescription,
        fieldType: values?.type,
        isPublic: false,
        typeConfig: values?.values || null,
        entityType: 'file',
        files: [{ id: listId }],
      })
        .unwrap()
        .then(() => {
          setActiveField(null)
          message.success('Field created successfully')
        })
    },
  })

  const handleMenuClick = (field) => {
    setActiveField(field)
    formik.setFieldValue('type', field.field)
  }

  useEffect(() => {
    if (isError) {
      message.error('Error while creating field')
    }
  }, [isError])

  const Component = Fields({ formik })[formik?.values?.type]?.component

  return (
    <div className="field-settings">
      <form onSubmit={formik.handleSubmit}>
        <div className="field-settings-header">
          <ArrowLeftIcon onClick={() => setActiveField(null)} />
          <span>{t('add_new_field')}</span>
        </div>
        <div className="field-settings-form">
          <div className="field-settings-type">
            <span className="label">{t('type')}</span>

            <Dropdown
              trigger={['click']}
              className="field-settings-dropdown"
              menu={{
                items: CustomFieldsIcons.map((item) => ({
                  key: item.field,
                  label: (
                    <div className="field-settings-dropdown-item">
                      {item.icon}
                      <span>{t(item.label)}</span>
                    </div>
                  ),
                  onClick: () => handleMenuClick(item),
                })),
              }}
            >
              <div className="field-settings-dropdown-selected">
                <div className="field-settings-dropdown-view">
                  {CustomFieldsIcons.find((item) => item.field === field?.field)?.icon}

                  {t(CustomFieldsIcons.find((item) => item.field === field?.field)?.label)}
                </div>
                <ArrowBottomIcon />
              </div>
            </Dropdown>
          </div>
          <div className="field-settings-input-container">
            <Input
              name="fieldName"
              label={t('field_name')}
              formik={formik}
              variant="filled"
              placeholder={t('enter_name')}
              type="text"
              required={false}
              disabled={false}
            />
          </div>
          <div className="field-settings-input-container">
            <Input
              name="fieldDescription"
              label={
                <span>
                  {t('description')}
                  <span className="field-settings-optional">({t('optional')})</span>
                </span>
              }
              formik={formik}
              variant="filled"
              placeholder={t('enter_description')}
              type="text"
              required={false}
              disabled={false}
            />
          </div>
          <div className="field-settings-settings">
            {Component}
            <div className="field-settings-button-container">
              <Button
                children={t('cancel')}
                className="field-settings-button-close"
                onClick={() => setActiveField(null)}
                htmlType="reset"
              />
              <Button
                children={t('create')}
                htmlType="submit"
                className="field-settings-button-valid"
                type={'primary'}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

const NewFields = () => {
  const [activeField, setActiveField] = useState<ICustomField | null>(null)
  const [search, setSearch] = useState<string>('')

  const filtredFields = search
    ? CustomFieldsIcons?.filter((field) => field.label?.toLowerCase().includes(search))
    : CustomFieldsIcons
  const popularFields = CustomFieldsIcons?.filter((field) => field.populare)
  const { t } = useTranslation(['customFields'])
  return (
    <div className="custom-fields-tab new-fields-tab">
      {activeField ? (
        <FieldSettings field={activeField} setActiveField={setActiveField} />
      ) : (
        <>
          <div className="search-bar">
            <Search onSearch={setSearch} prefix={''} variant="filled" />
          </div>
          {!search && (
            <>
              <div className="tab-title">
                <span>{t('popular')}</span>
              </div>
              {popularFields?.map((field) => (
                <div className="field-item" key={field.label} onClick={() => setActiveField(field)}>
                  <div className="field-item-icon">{field.icon}</div>
                  <div className="field-item-name">{t(field.label)}</div>
                </div>
              ))}
            </>
          )}

          <div className="devider"></div>
          <div className="tab-title">
            <span>{t('all')}</span>
          </div>
          {filtredFields?.map((field) => {
            return (
              <div className="field-item" onClick={() => setActiveField(field)}>
                <div className="field-item-icon">{field.icon}</div>
                <div className="field-item-name">{t(field.label)}</div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

interface FieldsVisibilityTabProps extends TabsProps {}

const FieldsVisibility = ({}: FieldsVisibilityTabProps) => {
  const [search, setSearch] = useState<string>('')
  const { headers } = useAppSelector((state) => state.tasks)
  const dispatch = useAppDispatch()

  const filtredFields = search
    ? headers?.filter((field) => field.fieldName?.toLowerCase().includes(search))
    : headers

  const hiddenFields = filtredFields.filter((field) => field.hidden)

  const shownFields = filtredFields.filter((field) => !field.hidden)

  const hideField = (id) => {
    // request hide field API
    dispatch(hideColumn(id))
  }

  const showField = (field) => {
    //request  show field API

    dispatch(showColumn(field?.id))
  }

  const hideAll = () => {
    dispatch(
      setHeaders(headers.map((column) => (column.disable ? column : { ...column, hidden: true }))),
    )
  }
  const { t } = useTranslation(['customFields'])
  return (
    <div className="custom-fields-tab">
      <div className="search-bar">
        <Search onSearch={setSearch} prefix={''} variant="filled" />
      </div>
      <div className="tab-title">
        <span>{t('shown')}</span>
        <p onClick={hideAll}>{t('hide_all')}</p>
      </div>
      {shownFields?.map((column) => {
        const icon =
          defaultFieldsIcons[column.id] ||
          CustomFieldsIcons?.find((field) => field.field === column?.fieldType)?.icon

        return (
          <div className={`field-item ${column.disable ? 'disabled-field-item' : ''}`}>
            <div className="field-item-icon">{column?.icon || icon}</div>
            <OverflowTooltip className="field-item-name">{t(column.fieldName)}</OverflowTooltip>
            <div className="field-item-toggle">
              <Switch
                checked={!!shownFields.find((field) => field.field === column.field)}
                onChange={() => !column.disable && hideField(column.id)}
              />
            </div>
          </div>
        )
      })}

      <div className="devider"></div>
      <div className="tab-title">
        <span>{t('hidden')}</span>
      </div>
      {hiddenFields?.map((column) => {
        const icon =
          defaultFieldsIcons[column.id] ||
          CustomFieldsIcons?.find((field) => field.field === column?.fieldType)?.icon

        return (
          <div className={`field-item ${column.disable ? 'disabled-field-item' : ''}`}>
            <div className="field-item-icon">{column?.icon || icon}</div>
            <OverflowTooltip className="field-item-name">{t(column.fieldName)}</OverflowTooltip>
            <div className="field-item-toggle">
              <Switch
                checked={!hiddenFields.find((hiddenField) => column.field === hiddenField.field)}
                onChange={() => showField(column)}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const tabs = {
  0: NewFields,
  1: FieldsVisibility,
}

const CustomFieldsSidebar = () => {
  const { t } = useTranslation(['customFields'])

  const [activeTab, setActiveTab] = useState<number>(0)
  const Tab = tabs[activeTab]

  const setTabHandler = (tab: number) => setActiveTab(tab)

  return (
    <div className="custom-fields ant-dropdown-menu">
      <div className="custom-fields-header">
        <div className="custom-fields-header-content">
          <div
            className={`header-left  ${activeTab === 0 ? 'header-active-tab' : ''}`}
            onClick={() => setTabHandler(0)}
          >
            {t('add_new_field')}
          </div>
          <div
            className={`header-right ${activeTab === 1 ? 'header-active-tab' : ''}`}
            onClick={() => setTabHandler(1)}
          >
            {t('fields_visibility')}
          </div>
        </div>
      </div>
      <div className="custom-fields-content">
        <div className="custom-fields-container hovered-scrollbar">
          <Tab />
        </div>
      </div>
    </div>
  )
}

export default CustomFieldsSidebar
