import { useState } from 'react'

import { ReactComponent as CercelClock } from '../assets/icons/cercel-clock.svg'
import { ReactComponent as NoteIcon } from '../assets/icons/note-icon.svg'

import { Avatar, Dropdown, message, Tooltip } from 'antd'
import { Input } from '@src/modules/shared/components/Input'
import Search from '@src/modules/shared/components/Input/search'

import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { handleSave } from '../../Timer/Timer'
import { useTranslation } from 'react-i18next'
import { ReactComponent as XCloseIcon } from '../assets/icons/x-close-icon.svg'
import { useAddManualTimerMutation } from '@src/modules/tasks/services/timerApi'
const ManualContent = ({ tasks, handleSearch, search, refetch }) => {
  const { t } = useTranslation(['timer'])
  const [selectedTask, setSelectedTask] = useState(null)
  const [note, setNote] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const handleDropdownOpenChange = (open) => {
    setDropdownOpen(open)
  }

  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [addManualTimer] = useAddManualTimerMutation()
  const handleCreateSession = async () => {
    const result = await handleSave({
      hours,
      minutes,
      taskId: selectedTask?.id,
      addManualTimer,
      description: note,
      t,
    })
    if (result?.data) {
      message.success(t('timer_added_successfully'))
      setHours('')
      setMinutes('')
      setSelectedTask(null)
      setNote('')
      refetch()
    }
  }
  const handleCancel = () => {
    setSelectedTask(null)
    setHours('')
    setMinutes('')
    setNote('')
  }
  return (
    <div className="global-timer-dropdown-content-header-tabs-content-timer">
      <Dropdown
        open={dropdownOpen}
        onOpenChange={handleDropdownOpenChange}
        dropdownRender={() => (
          <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown">
            {Array.isArray(tasks) &&
              tasks?.map((item, index) => (
                <div
                  className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-item"
                  key={index}
                  onClick={() => {
                    setSelectedTask(item)
                    setDropdownOpen(false)
                  }}
                >
                  <span
                    className="global-timer-dropdown-content-body-item-content-header-icon"
                    style={{ backgroundColor: item?.status?.color || '#000' }}
                  ></span>

                  <OverflowTooltip
                    title={item?.name}
                    className="global-timer-dropdown-content-body-item-content-header-tooltip"
                  >
                    <span>{item?.name}</span>
                  </OverflowTooltip>

                  <Avatar.Group maxCount={1}>
                    {item?.users?.map((assignee, index) => {
                      return (
                        <Avatar src={assignee?.avatar} key={index}>
                          {assignee?.firstName?.charAt(0)}
                        </Avatar>
                      )
                    })}
                  </Avatar.Group>
                </div>
              ))}
          </div>
        )}
        trigger={['click']}
      >
        <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search">
          <Search
            placeholder={selectedTask ? selectedTask?.name : t('name_of_the_task')}
            onChange={(e) => handleSearch(e)}
            value={selectedTask ? selectedTask?.name : search}
            prefix={
              selectedTask ? (
                <span
                  className="global-timer-dropdown-content-body-item-content-header-icon"
                  style={{ backgroundColor: selectedTask?.status?.color || '#000' }}
                ></span>
              ) : null
            }
            suffix={selectedTask ? <XCloseIcon onClick={() => setSelectedTask(null)} /> : null}
          />
        </div>
      </Dropdown>

      <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search">
        <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search-time">
          <CercelClock />
          <Input
            maxLength={2}
            placeholder="00"
            type="number"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
          {t('hours')}
          <Input
            placeholder="00"
            maxLength={2}
            max={99}
            type="number"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
          {t('minutes')}
        </div>
      </div>
      <div className="global-timer-dropdown-content-header-tabs-content-timer-dropdown-search">
        <Input
          placeholder={t('add_a_note')}
          prefix={<NoteIcon />}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <div className="global-timer-dropdown-content-header-tabs-content-footer">
        <div
          className="global-timer-dropdown-content-header-tabs-content-footer-cancel"
          onClick={handleCancel}
        >
          {t('cancel')}
        </div>
        <Tooltip
          title={
            !selectedTask
              ? t('please_select_a_task')
              : !hours && !minutes
                ? t('please_enter_hours_or_minutes')
                : !note
                  ? t('please_enter_a_note')
                  : ''
          }
        >
          <div
            className="global-timer-dropdown-content-header-tabs-content-footer-save"
            onClick={
              selectedTask && (hours || minutes) && note ? () => handleCreateSession() : undefined
            }
            style={{
              opacity: selectedTask && (hours || minutes) && note ? 1 : 0.5,
              cursor: selectedTask && (hours || minutes) && note ? 'pointer' : 'not-allowed',
            }}
          >
            {t('save')}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ManualContent
