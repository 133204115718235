import { setSpacesSocketEvent } from '@src/modules/spaces/data/spaceSlice/spacesSlice'
import { store } from '../store'
import { setSocketEvent } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import {
  addAnswerToAnswerList,
  addEmojiToAnswer,
  addEmojiToReply,
  addReplyToAnswer,
  deleteEmojiFromAnswer,
  deleteEmojiFromReply,
  deleteReplyToAnswer,
  updateAnswersPayload,
  updateEmojiInAnswer,
  updateEmojiReply,
  updateReplyToAnswer,
} from '@src/modules/dailyQuestions/data/answers/answersSlice'
import { setTimerSocketEvent } from '@src/modules/tasks/data/timerData/slices'

export const events = {
  list: {
    create: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: data }))
    },
    update: (data) => {
      store.dispatch(
        setSpacesSocketEvent({
          updateType: 'updatedItem',
          updateEvent: data,
        }),
      )
    },
    delete: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data }))
    },
  },

  folder: {
    create: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: data }))
    },
    update: (data) => {
      store.dispatch(
        setSpacesSocketEvent({
          updateType: 'updatedItem',
          updateEvent: data,
        }),
      )
    },
    delete: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data }))
    },
  },

  space: {
    create: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: data }))
    },
    update: (data) => {
      store.dispatch(
        setSpacesSocketEvent({
          updateType: 'updatedItem',
          updateEvent: data,
        }),
      )
    },
    delete: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data }))
    },
  },

  document: {
    create: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'createdItem', updateEvent: data }))
    },
    update: (data) => {
      store.dispatch(
        setSpacesSocketEvent({
          updateType: 'document',
          updateEvent: data,
        }),
      )
    },
    delete: (data) => {
      store.dispatch(setSpacesSocketEvent({ updateType: 'deletedItem', updateEvent: data }))
    },
  },

  task: {
    create: (data) => {
      store.dispatch(setSocketEvent({ updateType: 'createdTask', updateEvent: data }))
    },
    update: (data) => {
      store.dispatch(setSocketEvent({ updateType: 'updatedTask', updateEvent: data }))
    },
    delete: (data) => {
      store.dispatch(setSocketEvent({ updateType: 'deletedTask', updateEvent: data }))
    },
    bulkupdate: (data) => {
      store.dispatch(setSocketEvent({ updateType: 'bulkUpdateTask', updateEvent: data }))
    },
    updatedmany: (data) => {
      store.dispatch(setSocketEvent({ updateType: 'updatedMany', updateEvent: data }))
    },
  },

  answer: {
    create: (data) => {
      let dateQuery: string
      const endDate = new Date(data?.createdAnswer?.date)
      endDate.setDate(endDate.getDate() + 1)
      dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
      const key = `${data?.questionId}-${dateQuery}`
      store.dispatch(
        addAnswerToAnswerList({
          key,
          payload: {
            ...data?.createdAnswer,
            owner: {
              ...data.owner,
            },
            actions: data?.actions,
          },
        }),
      )
    },
    update: (data) => {
      const { questionId, date, answerId, updatedAnswer } = data || {}
      let dateQuery: string
      const endDate = new Date(date)
      endDate.setDate(endDate.getDate() + 1)
      dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
      const key = `${questionId}-${dateQuery}`
      store.dispatch(
        updateAnswersPayload({
          key,
          body: updatedAnswer?.body,
          payloadId: answerId || '',
        }),
      )
    },
    delete: () => {},
  },

  reaction: {
    create: (data) => {
      const { date, questionId, response, answerId } = data || {}
      let dateQuery: string
      const endDate = new Date(date)
      endDate.setDate(endDate.getDate() + 1)
      dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
      const key = `${questionId}-${dateQuery}`
      store.dispatch(addEmojiToAnswer({ emoji: response, key, answerId }))
    },
    update: (data) => {
      const { newData, date } = data || {}
      let dateQuery: string
      const endDate = new Date(date)
      endDate.setDate(endDate.getDate() + 1)
      dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
      const key = `${newData?.questionId}-${dateQuery}`
      store.dispatch(
        updateEmojiInAnswer({
          updatedEmoji: newData,
          key,
          answerId: newData?.answerId,
        }),
      )
    },
    delete: (data) => {
      const { date, reactionExist, answerId, questionId } = data || {}
      let dateQuery: string
      const endDate = new Date(date)
      endDate.setDate(endDate.getDate() + 1)
      dateQuery = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(1, '0')}-${endDate.getDate().toString().padStart(1, '0')}`
      const key = `${questionId}-${dateQuery}`
      store.dispatch(
        deleteEmojiFromAnswer({
          key,
          emojiIdToDelete: reactionExist.id,
          answerId,
        }),
      )
    },
  },

  timer: {
    start: (data) => {
      store.dispatch(setTimerSocketEvent({ updateType: 'STARTED', updateEvent: data }))
    },
    close: (data) => {
      store.dispatch(setTimerSocketEvent({ updateType: 'CLOSED', updateEvent: data }))
    },
  },

  reply: {
    create: (data) => {
      store.dispatch(addReplyToAnswer(data))
    },
    update: (data) => {
      store.dispatch(deleteReplyToAnswer(data))
    },
    delete: (data) => {
      store.dispatch(updateReplyToAnswer(data))
    },
  },

  commentreaction: {
    create: (data) => {
      store.dispatch(
        addEmojiToReply({ emoji: data?.NewReaction, key: data?.NewReaction?.answerId }),
      )
    },
    update: (data) => {
      store.dispatch(
        updateEmojiReply({
          updatedEmoji: data?.NewReaction,
          key: data?.NewReaction?.answerId,
          commentId: data?.NewReaction?.commentId,
        }),
      )
    },
    delete: (data) => {
      store.dispatch(
        deleteEmojiFromReply({
          emojiIdToDelete: data?.reactionExist,
          key: data?.answerId,
          commentId: data?.reactionExist?.commentId,
        }),
      )
    },
  },
}
