import Picker from '@emoji-mart/react'
import { uploadImage } from '@src/modules/shared/components/BlockNoteRichText/utils/uploadImage'
import ViewLayout from '@src/modules/shared/components/ViewLayout/ViewLayout'
import socketManager from '@src/modules/shared/socket/socketManager'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { useGetDocumentQuery } from '@src/modules/spaces/services/documentApi'
import { Dropdown, Spin, message } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../../settings/components/Header/Header'
import { ReactComponent as Comment } from '../../assets/icons/comment.svg'
import { ReactComponent as Cover } from '../../assets/icons/cover.svg'
import { ReactComponent as Smile } from '../../assets/icons/smile.svg'
import CommentSection from '../../components/CommentSection/index'
// import DocumentContent from '../../components/DocumentContent/DocumentContent'
import DocumentCover from '../../components/DocumentCover/DocumentCover'
import DocumentEmogi from '../../components/DocumentEmogi/DocumentEmogi'
import DocumentSkeleton from '../../components/DocumentSkeleton/DocumentSkeleton'
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle'
import LastUpdateInfos from '../../components/LastUpdateInfos/LastUpdateInfos'
import { editDocument } from '../../data/documentSlice/documentThunk'
import { LoadingOutlined } from '@ant-design/icons'
import Input from '@src/modules/shared/components/Input/Input'
import DocumentContent from '../../components/DocumentContent'

// const shouldUpdateDocument = (data, document, documentContent, isUploading) => {
//   return (
//     (data &&
//       data?.content !== document?.content &&
//       !isUploading &&
//       documentContent?.indexOf('slash_input') === -1) ||
//     data?.id !== document?.id
//   )
// }

const Documents = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['document'])
  const { documentId } = useParams()
  const { data, isError, isLoading, isFetching, refetch } = useGetDocumentQuery(documentId, {
    skip: !documentId,
  })

  const [document, setDocument] = useState(data)
  //   const [value, setValue] = useState()
  //   console.log(value)

  const [documentCover, setDocumentCover] = useState(null)
  const [documentEmoji, setDocumentEmoji] = useState(null)
  const [isOpenComments, setIsOpenComments] = useState(false)
  const [isCoverChanging, setIsCoverChanging] = useState(false)

  const headerProps = { isIconsVisible: true, document }

  const onEmojiClick = async (emojiObject) => {
    setDocumentEmoji(emojiObject.native)
    await dispatch(editDocument({ documentId, icon: emojiObject.native }))
  }

  const handleCoverChange = (event) => {
    try {
      setIsCoverChanging(true)
      uploadImage(event.target.files[0]).then(async (res) => {
        setDocumentCover(res)
        await dispatch(editDocument({ documentId, cover: res }))
        setIsCoverChanging(false)
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    refetch()
  }, [documentId]) // Removed 'location' from dependencies

  useEffect(() => {
    if (documentId) socketManager._joinRoom(documentId)
  }, [documentId])

  const prevDataRef = useRef(data)
  useEffect(() => {
    if (data && data !== prevDataRef.current) {
      // Added condition to prevent unnecessary updates
      setDocument(data)
      setDocumentCover(data?.cover)
      setDocumentEmoji(data?.icon)
      prevDataRef.current = data
    }
  }, [data]) // Removed 'document' from dependencies

  if (isError) navigate('/spaces')
  if (isLoading || isFetching) return <DocumentSkeleton />

  return (
    <ViewLayout header={Header} headerProps={headerProps}>
      <div className={`document_page_container ${!documentCover && 'no-cover'}`}>
        <DocumentCover
          documentCover={documentCover}
          documentId={documentId}
          cover_y={document?.cover_y}
          handleCoverChange={handleCoverChange}
          isCoverChanging={isCoverChanging}
        />

        {isCoverChanging && !documentCover && (
          <div className="add-cover-loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />} />
          </div>
        )}

        <div className="document_page_content">
          <DocumentEmogi documentEmoji={documentEmoji} onEmojiClick={onEmojiClick} />

          <div className="document_header_options">
            {!documentEmoji && (
              <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                  <Picker
                    // data={data}
                    categories={['frequent', 'people', 'nature', 'foods', 'activity']}
                    onEmojiSelect={(emoji) => {
                      onEmojiClick(emoji)
                    }}
                  />
                )}
              >
                <div className="document_header_option">
                  <Smile />

                  <span>{t('Add icon')}</span>
                </div>
              </Dropdown>
            )}

            {!documentCover && (
              <Input
                hidden={true}
                type="file"
                accept="image/*"
                id="input-cover"
                onChange={(event) => handleCoverChange(event)}
                style={{ display: 'none' }}
              />
            )}

            {!documentCover && (
              <label htmlFor="input-cover" className="document_header_option">
                <Cover />
                <span>{t('Add cover')}</span>
              </label>
            )}

            <div
              className="document_header_option"
              onClick={() => setIsOpenComments(!isOpenComments)}
            >
              <Comment />
              <span>{t('Add comment')}</span>
            </div>
          </div>

          <DocumentTitle documentTitle={document?.name} documentId={document?.id} />

          <LastUpdateInfos owner={document?.owner} updatedAt={document?.updatedAt} />

          <div className="divider"></div>

          <DocumentContent
            // isUploading={isUploading}
            // setIsUploading={setIsUploading}
            documentContent={document?.content}
            documentId={documentId}
            // setDocumentContetn={setDocumentContetn}
          />
        </div>

        <CommentSection
          isOpenComments={isOpenComments}
          setIsOpenComments={setIsOpenComments}
          documentId={document?.id}
        />
      </div>
    </ViewLayout>
  )
}

export default Documents
