import { useState, useRef, useEffect } from 'react'
import { ReactComponent as TimerClock } from '../assets/icons/timer-clock.svg'
import { ReactComponent as ManualTimer } from '../assets/icons/manual-timer.svg'
import { ReactComponent as PlayIcon } from '../assets/icons/big-play.svg'

import { Popover, Skeleton } from 'antd'

import { useAppSelector } from '@src/modules/shared/store'
import { useGetGlobalTimerMyTasksQuery, useGetTasksQuery } from '../../../services/tasksApi'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { concatStatusFilter } from '../../Board/StatusCard/StatusCard'
import { handleScroll } from '@src/modules/shared/utils/infinite-scroll'

import { useParams } from 'react-router-dom'

import { formatTimerTime } from '../../Timer/utils/formateTimerTime'
import { formatDateToMultiLang } from '../../../utils/formateDateToMultiLang'
import TimerContent from '../TimerContent'
import ManualContent from '../ManualContent'
import { useTranslation } from 'react-i18next'

const GlobalTimerDropdownContent = ({ elapsedSeconds }) => {
  const [selectedTab, setSelectedTab] = useState('timer')
  const { t } = useTranslation(['timer'])
  const timerTabRef = useRef<HTMLDivElement>(null)
  const manualTabRef = useRef<HTMLDivElement>(null)
  const [indicatorStyle, setIndicatorStyle] = useState({})
  const [page, setPage] = useState(1)
  const { user } = useAppSelector((state) => state.auth)
  const [search, setSearch] = useState('')
  const isArabic = localStorage.getItem('i18nextLng') === 'ar'
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    setPage(1)
    setSearch(e ? e.target.value : '')
  }
  const lang = localStorage.getItem('i18nextLng') || 'en'

  const { listId } = useParams<{ listId: string }>()

  const queryOptions = listId
    ? {
        fileId: listId,
        skip: 0,
        limit: 10 * page,
        options: concatStatusFilter({
          ...(search ? { name: { operation: '$contains', value: search } } : {}),
          fileId: { operation: '$Equal', value: listId },
        }),
      }
    : {
        limit: 10,
        skip: page,
        userId: user?.user?.id,
        options: concatStatusFilter({
          ...(search ? { name: { operation: '$contains', value: search } } : {}),
        }),
      }

  const { data, isLoading, isFetching, refetch } = listId
    ? useGetTasksQuery(queryOptions)
    : useGetGlobalTimerMyTasksQuery(queryOptions)

  const tasks = listId ? data?.payload?.payload : data?.result?.payload
  const tasksMetaData = listId ? data?.payload?.metadata : data?.result?.metadata

  useEffect(() => {
    const updateIndicator = () => {
      const activeTab = selectedTab === 'timer' ? timerTabRef.current : manualTabRef.current
      if (activeTab) {
        setIndicatorStyle({
          left: `${activeTab.offsetLeft}px`,
          width: `${activeTab.offsetWidth}px`,
        })
      }
    }

    updateIndicator()
    window.addEventListener('resize', updateIndicator)
    return () => window.removeEventListener('resize', updateIndicator)
  }, [selectedTab])
  return (
    <div className="global-timer-dropdown-content">
      <div
        className={`global-timer-dropdown-content-header ${selectedTab === 'timer' ? 'global-timer-dropdown-content-header-timer-height' : 'global-timer-dropdown-content-header-manual-height'}`}
      >
        <div className="global-timer-dropdown-content-header-tabs">
          <div
            ref={timerTabRef}
            className={`global-timer-dropdown-content-header-tabs-tab ${selectedTab === 'timer' ? 'global-timer-dropdown-content-header-tabs-tab-selected' : ''}`}
            onClick={() => setSelectedTab('timer')}
          >
            <TimerClock />
            {t('timer')}
          </div>
          <div
            ref={manualTabRef}
            className={`global-timer-dropdown-content-header-tabs-tab ${selectedTab === 'manual' ? 'global-timer-dropdown-content-header-tabs-tab-selected' : ''}`}
            onClick={() => setSelectedTab('manual')}
          >
            <ManualTimer />
            {t('manual_timer')}
          </div>
          <div
            className="global-timer-dropdown-content-header-tabs-indicator"
            style={indicatorStyle}
          />
        </div>
        <div className="global-timer-dropdown-content-header-tabs-content">
          {selectedTab === 'timer' && (
            <TimerContent
              elapsedSeconds={elapsedSeconds}
              page={page}
              tasks={tasks}
              handelSearch={handleSearch}
              search={search}
              setPage={setPage}
              tasksMetaData={tasksMetaData}
            />
          )}
          {selectedTab === 'manual' && (
            <ManualContent
              tasks={tasks}
              handleSearch={handleSearch}
              search={search}
              refetch={refetch}
            />
          )}
        </div>
      </div>

      <div
        className="global-timer-dropdown-content-body"
        onScroll={(e) => handleScroll(e, tasksMetaData?.total_items, page, 10, setPage)}
      >
        {isLoading ||
          (isFetching &&
            Array.from({ length: 10 }).map((_, index) => (
              <div className="global-timer-dropdown-content-body-skeleton" key={index}>
                <Skeleton.Input active />
              </div>
            )))}
        <>
          {Array.isArray(tasks) &&
            tasks?.map((item, index) => {
              const hasTimers = item?.timers?.length > 0
              return (
                <Popover
                  key={index}
                  content={
                    hasTimers ? (
                      <div className="global-timer-dropdown-content-body-item-content-header-popover">
                        <div className="global-timer-dropdown-content-body-item-content-header-popover-name">
                          <div className="global-timer-dropdown-content-body-item-content-header-popover-name-task">
                            <span className="global-timer-dropdown-content-body-item-content-header-icon"></span>

                            <OverflowTooltip
                              title={item?.name}
                              className="global-timer-dropdown-content-body-item-content-header-tooltip"
                            >
                              <span>{item?.name}</span>
                            </OverflowTooltip>
                          </div>
                          <span>({item?.timers?.length || 0})</span>
                        </div>
                        <div className="global-timer-dropdown-content-body-item-content-header-popover-time">
                          {item?.timers?.map((timer, index) => {
                            return (
                              <div
                                className="global-timer-dropdown-content-body-item-content-header-popover-time-container"
                                key={index}
                              >
                                <span className="global-timer-dropdown-content-body-item-content-header-popover-time-container-time">
                                  {formatTimerTime(timer?.duration)}
                                </span>
                                <span className="global-timer-dropdown-content-body-item-content-header-popover-time-container-date">
                                  {formatDateToMultiLang(timer?.startTime, lang, 'MMM DD, YYYY')}
                                </span>
                                <span className="global-timer-dropdown-content-body-item-content-header-popover-time-container-time">
                                  {formatDateToMultiLang(timer?.startTime, lang, 'hh:mm a')} -{' '}
                                  {formatDateToMultiLang(timer?.endTime, lang, 'hh:mm a')}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ) : null
                  }
                  trigger="click"
                  placement="right"
                >
                  <div className="global-timer-dropdown-content-body-item" key={index}>
                    <>
                      <PlayIcon />
                      <div className="global-timer-dropdown-content-body-item-content">
                        <div className="global-timer-dropdown-content-body-item-content-header">
                          <span className="global-timer-dropdown-content-body-item-content-header-icon"></span>
                          <OverflowTooltip
                            title={item?.name}
                            className="global-timer-dropdown-content-body-item-content-header-tooltip"
                          >
                            <span>{item?.name}</span>
                          </OverflowTooltip>
                        </div>
                        <div className="global-timer-dropdown-content-body-item-content-footer">
                          <span>
                            {formatTimerTime(
                              item?.timers?.reduce((acc, curr) => acc + +curr.duration, 0),
                            )}
                          </span>
                          <span>{formatDateToMultiLang(item?.updatedAt, lang, 'MM MMM')} </span>
                        </div>
                      </div>
                      <div
                        className={`global-timer-dropdown-content-body-item-content-footer-count ${
                          isArabic
                            ? 'global-timer-dropdown-content-body-item-content-footer-count-ar'
                            : ''
                        }`}
                      >
                        ({item?.timers?.length})
                      </div>
                    </>
                  </div>
                </Popover>
              )
            })}

          {tasksMetaData?.next && (
            <div className="global-timer-dropdown-content-body-skeleton">
              <Skeleton.Input />
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default GlobalTimerDropdownContent
