import { Avatar } from '@src/modules/shared/components/Avatar'
import { Divider } from 'antd'
import TeamActionMenu from '../TeamActionMenu'
import TaskUsers from '@src/modules/tasks/components/TaskUsers'
import SkeletonLoader from '@src/modules/shared/components/SkeletonLoader'
import { ReactComponent as CardSkeleton } from '../../assets/icons/teams/card_skeleton.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

const TeamCard = ({ data, status }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['teams'])

  return (
    <SkeletonLoader
      isLoading={status === 'loading'}
      skeleton={<CardSkeleton className="skeleton" />}
    >
      <div className="team-card-container" onClick={() => navigate(`/settings/teams/${data?.id}`)}>
        <div className="action-conatiner">
          <TeamActionMenu data={data} />
        </div>

        <Avatar
          text={data?.name || 'Team'}
          src={data?.icon}
          color={data?.color || '#A6A6A6'}
          size={80}
        />

        <Tooltip title={data?.name} placement="topLeft">
          <span className="team-name">
            {data?.name?.length > 15 ? data?.name.slice(0, 15) + '...' : data?.name || 'Team'}
          </span>
        </Tooltip>

        <span className="creation-date">
          {t('created_on')} {formatDate(data?.createdAt)}
        </span>

        <Divider />

        <span className="team-manager">
          {t('managed_by')}{' '}
          <Tooltip title={data?.managers?.[0]?.user?.fullName} placement="topLeft">
            <span className="manager-name">
              {data?.managers?.[0]?.user?.fullName?.length > 15
                ? data?.managers?.[0]?.user?.fullName?.slice(0, 15)
                : data?.managers?.[0]?.user?.fullName}
            </span>
          </Tooltip>
        </span>

        <div>
          <TaskUsers
            displayAddButton={false}
            userNumber={3}
            members={data?.roles.map((role) => role?.user)}
            allowRemoveUser={false}
          />
        </div>
      </div>
    </SkeletonLoader>
  )
}

export default TeamCard
