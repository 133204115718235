import { Drawer, Dropdown, message, Skeleton } from 'antd'
import { ReactComponent as WarningSVG } from '../../../../assets/icons/status/warning.svg'
import { Button } from '@src/modules/shared/components/Button'
import {
  useGetConflictedStatusesQuery,
  useResolveConflictedStatusesMutation,
} from '@src/modules/spaces/services/fileApi'
import { useEffect, useState } from 'react'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { IStatus } from '../StatusGroup/StatusGroup'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowSVG } from '../../../../assets/icons/status/arrow.svg'
import { ReactComponent as AngleDownSVG } from '../../../../assets/icons/status/angle-down.svg'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

const renderSkeletons = (count: number = 3) => {
  return Array.from({ length: count }).map((_, index) => (
    <div className="status-drawer-map-item item-skeleton" key={index}>
      <div className="item-skeleton-left">
        <Skeleton.Avatar active size={'small'} />
        <Skeleton.Input active size={'small'} />
      </div>
      <Skeleton.Input active size={'small'} />
    </div>
  ))
}

type conflictedStatusType = statusType & {
  toBe: IStatus
  taskCount: number
  isDeleted?: boolean
  customStatusId?: string
}

const getTotalTasks = (conflictedStatuses: conflictedStatusType[]) => {
  return conflictedStatuses?.reduce((acc, status) => acc + status.taskCount, 0)
}

const findMatchingStatus = (conflict, statuses: IStatus[]): IStatus | undefined => {
  return statuses.find(
    (status) =>
      (status?.name?.toLowerCase() === conflict?.name?.toLowerCase() ||
        status?.name?.toLowerCase().includes(conflict?.name?.toLowerCase())) &&
      !status?.isDeleted,
  )
}

const autoSelectStatus = (conflictsStatuses, statuses: IStatus[]): conflictedStatusType[] => {
  return conflictsStatuses?.map((conflict) => {
    if (conflict?.toBe) return conflict

    const status = findMatchingStatus(conflict, statuses)
    return { ...conflict, toBe: status }
  })
}

const getConflictedStatusesWithDeleted = (conflictsStatuses, statuses): conflictedStatusType[] => {
  const haveADeletedStatus = statuses?.some((status) => status?.isDeleted && !status?.isNew)

  const result = haveADeletedStatus
    ? conflictsStatuses
        ?.filter((conflict) =>
          statuses.find((status) => status.id === conflict.id && status.isDeleted),
        )
        .map((conflict) => {
          const status = statuses.find((status) => status.id === conflict.id)
          return { ...conflict, ...status }
        })
    : conflictsStatuses

  return autoSelectStatus(result, statuses)
}

const ConflictManagementDrawer = ({ handleClose, open, id: drawerId, data }) => {
  const { statuses, hierarchyType, entityUuid, onResolve, inheritStatusFromParent } = data
  const [conflictsStatuses, setConflictsStatuses] = useState<conflictedStatusType[] | null>(null)
  const { data: conflictedStatuses, isLoading: gettingConflictedStatus } =
    useGetConflictedStatusesQuery(
      { entityUuid, hierarchyType },
      {
        refetchOnMountOrArgChange: true,
      },
    )
  const [globalLoading, setGlobalLoading] = useState<boolean>(false)
  const [resolveConflictedStatuses, { isLoading }] = useResolveConflictedStatusesMutation()
  const { t } = useTranslation(['modals'])
  const closeModal = () => handleClose(drawerId)
  const totalConflictedTasks = getTotalTasks(conflictsStatuses)

  const newStatusItems = (conflictedStatus) =>
    statuses
      ?.filter((status) => !status?.isDeleted)
      ?.map((status) => {
        return {
          key: status?.id,
          label: (
            <div className="status-drawer-option">
              <span style={{ background: status?.color }}></span>
              <OverflowTooltip>{status?.name}</OverflowTooltip>
            </div>
          ),
          onClick: () => saveStatusToBe(conflictedStatus?.id, status),
        }
      })

  useEffect(() => {
    setConflictsStatuses(
      getConflictedStatusesWithDeleted(conflictedStatuses, statuses) ||
        ([] as conflictedStatusType[]),
    )
  }, [conflictedStatuses])

  const saveStatusToBe = (conflictedStatusId, status) => {
    const newConflictsStatuses = conflictsStatuses.map((conflictStatus) =>
      conflictStatus?.id === conflictedStatusId
        ? { ...conflictStatus, toBe: status }
        : conflictStatus,
    )

    setConflictsStatuses(newConflictsStatuses)
  }

  const save = () => {
    setGlobalLoading(true)
    const payload = {
      entityUuid,
      hierarchyType,
      inheritStatusFromParent,
    }

    const allConflictsResolved = conflictsStatuses?.every((status) => status?.toBe)

    if (!allConflictsResolved) {
      message.error(t('status-conflicts.resolve-all-conflicts'))
      return
    }

    const statusesKey = inheritStatusFromParent ? 'parentStatuses' : 'customStatuses'

    payload[statusesKey] = conflictsStatuses
      .map((status) => {
        if (!status?.toBe) return null
        const { isDeleted: deleteStatus = false } = status
        const { isNew, isDeleted, ...rest } = status?.toBe
        if (isNew) delete rest?.id

        let newStatusItem = inheritStatusFromParent
          ? {
              parentStatusId: rest?.id,
            }
          : {
              statusPayload: rest,
              customStatusId: rest?.id,
            }

        if (!isNew && !inheritStatusFromParent) {
          delete newStatusItem.statusPayload
        } else delete newStatusItem.customStatusId

        return {
          conflictingStatusId: status?.id,
          ...newStatusItem,
          deleteStatus,
        }
      })
      .filter((status) => status)

    let restOfStatuses = statuses?.filter(
      (status) => !conflictsStatuses.find((item) => item?.toBe?.id === status?.id),
    )

    const isDeletedStatuses = statuses?.some((status) => status?.isDeleted)

    if (isDeletedStatuses)
      restOfStatuses = restOfStatuses?.filter((status) => status?.isNew && !status?.isDeleted)

    if (!inheritStatusFromParent)
      payload[statusesKey] = [
        ...payload[statusesKey],
        ...restOfStatuses?.map((status) => {
          const { isNew, isDeleted, ...rest } = status
          if (isNew) {
            delete rest.isNew
            delete rest.id
          }
          return { statusPayload: rest, createNew: !!isNew, deleteStatus: !!isDeleted }
        }),
      ]

    resolveConflictedStatuses(payload)
      .unwrap()
      .then(async () => {
        await onResolve()
        closeModal()
      })
      .catch((error) => {
        message.error(
          error?.message || t('status-conflicts.error-while-resolving-conflicted-statuses'),
        )
      })
      .finally(() => setGlobalLoading(false))
  }

  return (
    <Drawer width={670} onClose={closeModal} open={open} className="status-drawer" placement="left">
      <div className="status-drawer-content">
        <div className="status-drawer-warning-img">
          <WarningSVG />

          <div className="status-drawer-warning-text">
            {t('status-conflicts.resolve-your-conflicts')}
            {gettingConflictedStatus ? (
              <Skeleton
                active
                paragraph={{
                  rows: 1,
                  width: '100%',
                }}
              />
            ) : (
              <p>
                {t('status-conflicts.warning-text-1')} {totalConflictedTasks}{' '}
                {t('status-conflicts.warning-text-2')}
              </p>
            )}
          </div>
        </div>

        <div className="status-drawer-map">
          <div className="status-drawer-map-header">
            <p>{t('status-conflicts.old-status')}</p>
            <p>{t('status-conflicts.new-status')}</p>
          </div>
          <div className="status-drawer-map-content">
            {gettingConflictedStatus
              ? renderSkeletons()
              : conflictsStatuses?.map((status) => {
                  return (
                    <div className="status-drawer-map-item" key={status?.id}>
                      <div className="status-drawer-map-item-left">
                        <div className="status-drawer-map-item-status">
                          <span style={{ background: status?.color }}></span>
                          <OverflowTooltip>{status?.name}</OverflowTooltip>
                        </div>
                        <div className="status-drawer-map-item-tasks">
                          {status?.taskCount} {t('status-conflicts.task')} <ArrowSVG />
                        </div>
                      </div>
                      <div className="status-drawer-map-item-right">
                        <div className="status-drawer-map-item-select">
                          <Dropdown menu={{ items: newStatusItems(status) }} trigger={['click']}>
                            <div>
                              <p className="status-drawer-map-item-selcetd-status">
                                {status?.toBe ? (
                                  <>
                                    {<span style={{ background: status?.toBe?.color }}></span>}
                                    <OverflowTooltip> {status.toBe?.name}</OverflowTooltip>
                                  </>
                                ) : (
                                  t('status-conflicts.select-status')
                                )}
                              </p>
                              <AngleDownSVG />
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  )
                })}
          </div>
        </div>
        {!gettingConflictedStatus && (
          <div className="status-drawer-footer">
            <Button className="cancel-button" onClick={closeModal}>
              {t('status-conflicts.cancel')}
            </Button>
            <Button onClick={save} loading={isLoading || globalLoading}>
              {t('status-conflicts.save')}
            </Button>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default ConflictManagementDrawer
