import { ReactComponent as BoardIcon } from './../../assets/icons/tasks/board.svg'
import { ReactComponent as ListIcon } from './../../assets/icons/tasks/list.svg'
import { ReactComponent as GroupByIcon } from './../../assets/icons/tasks/group-by.svg'
import { ReactComponent as StatusIcon } from './../../assets/icons/tasks/status-group-by.svg'
import { parseBoolean } from '@src/modules/shared/utils/parseBoolean'
import Search from '@src/modules/shared/components/Input/search'
import { useGetListQuery, useGetStatausQuery } from '@src/modules/spaces/services/fileApi'
import { Dropdown, Segmented, message } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import { Fragment, memo, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import socketManager from '../../../shared/socket/socketManager'
import Board from '../../components/Board'
import ListView from '../../components/ListView/ListView'
import TasksFilter from '../../components/TasksFilter/TasksFilter'
import { updateURL } from '../../utils/updateURL'
import { ReactComponent as SearchIcon } from './../../assets/icons/filter/search-icon.svg'
import { ReactComponent as ProfileIcon } from './../../assets/icons/tasks/profile.svg'
import { ReactComponent as Settings } from './../../assets/icons/tasks/setting.svg'
import { ReactComponent as ExpandCollapseIcon } from './../../assets/icons/tasks/hierarchy.svg'
import { useTranslation } from 'react-i18next'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { resetFilter } from '@src/modules/shared/store/slices/filter/filterSlice'
import { setHeaders } from '../../data/tasksSlice/tasksSlice'
import { useUpdateGenericViewMutation } from '../../services/genericViewsApi'
import { toField } from '../../utils/toField'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { useGetCustomFieldsByListQuery } from '../../services/customFieldsApi'
import { getFieldsToGroupBy } from '../../utils/getFieldToGroupBy'
import { defaultFieldsIcons } from '../../components/ListView/utils/defaultFields'
import { CustomFieldsIcons } from '@src/modules/customFields/data/CustomFieldIcons'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'

export const groupByFields = (t, activeFieldIndex: string, fieldsToGroupBy) => {
  const baseGroupByList = fieldsToGroupBy.map((field, index) => {
    const icon =
      defaultFieldsIcons[field.id] ||
      CustomFieldsIcons?.find((item) => item.field === field?.fieldType)?.icon

    return {
      key: index + 1,
      label: (
        <OverflowTooltip title={t(field.fieldName)} style={{ width: '100px' }}>
          {t(field.fieldName)}
        </OverflowTooltip>
      ),
      icon,
      id: field.custom ? field.id : field.fieldType,
      custom: field.custom,
      type: field?.fieldType,
      fieldName: field.fieldName,
      className: `groupby-field-dropdown  ${+activeFieldIndex === index + 1 && 'active-groupby-field'}`,
    }
  })

  baseGroupByList.unshift({
    key: 0,
    label: t('status'),
    icon: <StatusIcon />,
    id: 'status',
    type: 'status',
    fieldName: 'status',
    className: `groupby-field-dropdown  ${+activeFieldIndex === 0 && 'active-groupby-field'}`,
  })

  return baseGroupByList
}

const List = (props) => <ListView {...props} />

const BoardView = (props) => <Board {...props} />

interface viewsType {
  [key: number]: any
}

export enum GroupByFields {
  STATUS = 'status',
  PRIORITY = 'priority',
  ASSIGNEE = 'users',
  CHECKBOX = 'checkbox',
  LABEL = 'label',
  WEBSITE = 'website',
  DROPDOWN = 'dropdown',
  RATE = 'rate',
}

const Views: viewsType = {
  0: memo(List),
  1: memo(BoardView),
}

const Tasks = () => {
  const { t } = useTranslation(['tasks'])
  const [searchParams, setSearchparams] = useSearchParams()
  let defaultView = searchParams.get('view')
  const filterByMe = searchParams.get('byMe')
  const expand = searchParams.get('expand')
  const dispatch = useAppDispatch()
  const groupByIndex = searchParams.get('groupBy') || '0'
  const navigate = useNavigate()
  const { listId = '' } = useParams()
  const {
    data: list,
    refetch,
    isError,
    isFetching,
  } = useGetListQuery({ id: listId } as any, { skip: !listId })
  const search = searchParams.get('search')

  const { data } = useGetStatausQuery(
    {
      hierarchyType: 'fileId',
      entityUuid: listId,
    },
    {
      skip: !listId,
    },
  )

  defaultView = defaultView || '0'
  const [activeItem, setActiveItem] = useState<string>(defaultView)
  const View = Views[activeItem as unknown as keyof viewsType] || Fragment
  const { headers } = useAppSelector((state) => state.tasks)
  const { data: customFields } = useGetCustomFieldsByListQuery(listId, {
    skip: !listId,
    refetchOnMountOrArgChange: false,
  })
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [updateView] = useUpdateGenericViewMutation()
  const fieldsToGroupby = getFieldsToGroupBy(headers)
  // const [updateCustomField] = useUpdateCustomFieldMutation()

  useEffect(() => {
    if (customFields && list && list?.id === listId) {
      const newFields = customFields?.map((field) => toField(field))

      const savedHeaders = list?.genericView?.settings?.map((header) => {
        const field = newFields.find((f) => f.id === header.id)
        if (field) header = { ...header, typeConfig: field.typeConfig }
        return header
      })

      dispatch(setHeaders(removeDuplicatesById([...(savedHeaders || []), ...newFields])))
    }
  }, [customFields, list])

  useEffect(() => {
    if (
      headers?.length > 0 &&
      JSON.stringify(headers) !== JSON.stringify(list?.genericView?.settings)
    ) {
      updateView({ fileId: listId, settings: headers })
        .unwrap()
        .then(() => refetch())
        .catch(() => {
          message.error(t('failed_to_update_view'))
        })
    }
  }, [headers])

  const items = [
    {
      label: t('list'),
      value: '0',
      icon: <ListIcon />,
    },
    {
      label: t('board'),
      value: '1',
      icon: <BoardIcon />,
    },
  ]

  useEffect(() => {
    //socket connection
    if (listId) socketManager._joinRoom(listId)
    searchParams.delete('search')
    setSearchparams(searchParams)
    dispatch(resetFilter({ model: 'tasks' }))
  }, [listId])

  useEffect(() => {
    if (isError && !isFetching) {
      message.error(t('List not found'))
      navigate('/dashboard')
    }
  }, [isError, isFetching])

  const groupByFieldsItems = groupByFields(t, groupByIndex, fieldsToGroupby)

  const groupBy = {
    id: groupByFieldsItems[parseInt(groupByIndex)]?.id,
    type: groupByFieldsItems[parseInt(groupByIndex)]?.type,
    custom: !!groupByFieldsItems[parseInt(groupByIndex)]?.custom,
  }

  return (
    <div className="dashboard-tasks">
      <div className="dashboard-tasks-bar">
        <Segmented
          options={items}
          defaultValue={defaultView}
          onChange={(e: SegmentedValue) => {
            setActiveItem(e as string)
            navigate(updateURL({ label: 'view', value: e }))
          }}
        />

        <div className="dashboard-tasks-settings-options">
          <TasksFilter scope="tasks" />

          <div
            className={`dashboard-tasks-button me-button ${parseBoolean(filterByMe) ? 'groupby-btn' : ''} `}
            onClick={() =>
              navigate(
                updateURL({
                  label: 'byMe',
                  value: `${filterByMe === 'true' ? 'false' : 'true'}`,
                }),
              )
            }
          >
            <ProfileIcon />
            <p>{t('Me')}</p>
          </div>

          <div
            className={`dashboard-tasks-button groupby-btn`}
            onClick={() =>
              navigate(
                updateURL({
                  label: 'expand',
                  value: `${expand === 'true' ? 'false' : 'true'}`,
                }),
              )
            }
          >
            <ExpandCollapseIcon />
            <p>
              {t('tasks')} {parseBoolean(expand) ? t('expand_all') : t('collapse_all')}
            </p>
          </div>

          <Dropdown
            trigger={['click']}
            menu={{
              items: groupByFields(t, groupByIndex, fieldsToGroupby),
              onClick: ({ key }) =>
                navigate(
                  updateURL({
                    label: 'groupBy',
                    value: key,
                  }),
                ),
            }}
          >
            <div className={`dashboard-tasks-button groupby-btn`}>
              <GroupByIcon />
              <p>
                {t('groub_by')} {t(groupByFieldsItems[groupByIndex]?.fieldName)}
              </p>
            </div>
          </Dropdown>

          <div className="dashboard-tasks-search">
            <Search
              size="xs"
              onSearch={(s: string) => navigate(updateURL({ label: 'search', value: s }))}
              prefix={<SearchIcon />}
              value={search}
              placeholder={t('search_tasks')}
              style={{ width: '100%', maxWidth: '200px' }}
              containerClassName="dashboard-tasks-search-input"
              variant="filled"
              autoFocus={false}
              inputRef={searchInputRef}
            />
          </div>
          <div
            className="dashboard-tasks-customize"
            onClick={() => {
              //   dispatch(openModal({ id: 'custom-fields-modal', data: {} }))
              navigate(updateURL({ label: 'customFields', value: 'true' }))
            }}
            // onClick={() => dispatch(openModal({ id: 'custom-fields-modal', data: {} }))}
          >
            <Settings className="dashboard-tasks-customize-icon" /> {t('customize')}
          </div>
        </div>
      </div>
      <RTLStyleDecorator>
        <div className="dashboard-tasks-container">
          <View list={{ ...list, status: data, refetch }} groupBy={groupBy} search={search} />
        </div>
      </RTLStyleDecorator>
    </div>
  )
}

export default Tasks
