import { BaseFieldProps } from '../../ListView/ListColumns/ListColumns'
import TaskUsers from '../../TaskUsers'
import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'

const PeopleField = ({ task, field, saveCustomFieldValue, customFieldValue }: BaseFieldProps) => {
  const taskFieldValue = field?.custom ? customFieldValue : task?.[field?.value]
  const { singleUser } = field?.typeConfig

  return (
    <div>
      <TaskUsers
        displayAddButton={!taskFieldValue?.length}
        userNumber={3}
        // full={includeGroups}
        // withTeams={!!}
        multiple={!singleUser}
        members={removeDuplicatesById(taskFieldValue)}
        onClick={(e) => e.stopPropagation()}
        setAssignedMembersList={(users) => saveCustomFieldValue(users)}
      />
    </div>
  )
}

export default PeopleField
