/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { fovorities, archive } from '../../__mock__'
import {
  SetSpaceChildPayload,
  ToggleFolderPyload,
  ToggleSpacePyload,
  manageFilesPyload,
} from './spacesTypes'
import { FolderType } from '../../components/Folder/Folder'
import { createSpace, deleteSpace, getShared, getSpaces, getStatuses } from './spacesThunk'
import { ListType } from '../../components/List/List'
import { statusType } from '../statusSlice/statusSlice'
import { ISpace } from '@softylines/tam-types'

export interface spaceType extends ISpace {
  isOpen?: boolean
}

export interface SpacesState {
  favorities: { payload: spaceType[] }
  share: { payload: { folders: FolderType[]; lists: ListType[] } }
  projects: { payload: spaceType[] } | null
  spaces: spaceType[] | null
  archive: { payload: spaceType[] }
  spacesChildren: {
    [key: string]: {
      folders: any
      lists: any
    }
  } | null
  foldersChildren: {
    [key: string]: {
      lists: any
      folders: any
    }
  } | null
  error: any
  document: any
  status: string
  statuses: statusType[] | null
  createdItem: any
  updatedItem: any
  // bulkUpdateTask: any
  deletedItem: any
}

const addCustomField = (
  array: spaceType[] | FolderType[],
  field: string,
  content: string | boolean,
): any => {
  return array.map((item: spaceType | FolderType) => {
    return { ...item, [field]: content }
  })
}

const initialState: SpacesState = {
  favorities: { payload: addCustomField(fovorities, 'isOpen', false) },
  share: null,
  projects: null,
  archive: { payload: addCustomField(archive, 'isOpen', false) },
  spacesChildren: null,
  foldersChildren: null,
  spaces: null,
  error: null,
  document: null,
  status: 'idle',
  statuses: null,
  createdItem: null,
  updatedItem: null,
  // bulkUpdateTask: any
  deletedItem: null,
}

const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    setSpacesSocketEvent(state, { payload }) {
      state[payload.updateType] = payload.updateEvent
    },
    resetSpacesSocketEvents(state) {
      state.createdItem = state.deletedItem = state.updatedItem = null
    },
    toggleSpace(state, action: PayloadAction<ToggleSpacePyload>) {
      const { spaceID, group, open = null } = action.payload
      if (group !== 'share' && state[group] !== null)
        state[group]!.payload = state[group]!.payload.map((space: spaceType) =>
          space.id === spaceID ? { ...space, isOpen: open !== null ? open : !space.isOpen } : space,
        )
    },
    toggleFolder(state, action: PayloadAction<ToggleFolderPyload>) {
      const { spaceID, folderID, group, open = null } = action.payload
      if (group !== 'share') {
        const copyState = { ...state.spacesChildren }
        copyState[spaceID] = {
          ...copyState[spaceID],
          folders: copyState[spaceID].folders.map((f: FolderType) =>
            f.id === folderID ? { ...f, isOpen: open !== null ? open : !f.isOpen } : f,
          ),
        }
        state.spacesChildren = copyState
      } else {
        state.share.payload.folders = state.share.payload.folders.map((folder: FolderType) =>
          folder.id === folderID ? { ...folder, isOpen: !folder.isOpen } : folder,
        )
      }
    },
    setSpaceChildren(state, action: PayloadAction<SetSpaceChildPayload>) {
      const { id, folders, lists } = action.payload
      state.spacesChildren = {
        ...state.spacesChildren,
        [id]: {
          folders: addCustomField(folders as any, 'isOpen', false),
          lists,
        },
      }
    },
    setFolderChildren(state, action: PayloadAction<any>) {
      const { folderID, lists } = action.payload
      state.foldersChildren = {
        ...state.foldersChildren,
        [folderID]: { lists },
      }
    },
    setSpaces(state, action: PayloadAction<spaceType[]>) {
      state.spaces = action.payload
    },
    manageFiles(state, action: PayloadAction<manageFilesPyload>) {
      const { type, id, newObj, parent, targetId, targetData, group } = action.payload
      const target = parent === 'space' ? 'spacesChildren' : 'foldersChildren'
      const data = state[target]
      if (group === 'share' && type === 'update') {
        state.share.payload[targetData] = state.share.payload[targetData]?.map((target) =>
          target.id === targetId ? newObj : target,
        )
      } else if (group === 'share' && type === 'remove') {
        state.share.payload[targetData] = [...(state.share.payload[targetData] || [])].filter(
          (target) => target.id !== targetId,
        ) as any
      } else if (group === 'share' && type === 'add') {
        state.share.payload[targetData] = [
          ...(state.share.payload[targetData] || []),
          newObj,
        ] as any
      } else if (type === 'add' && data) {
        state[target] = {
          ...data,
          [id]: {
            ...data[id],
            [targetData]: [...((data[id] && data[id][targetData]) || []), newObj],
          },
        }
      } else if (type === 'remove' && data) {
        state[target] = {
          ...data,
          [id]: {
            ...data[id],
            [targetData]: data[id][targetData]?.filter(
              (item: ListType | FolderType) => item.id !== targetId,
            ),
          },
        }
      } else if (data) {
        state[target] = {
          ...data,
          [id]: {
            ...data[id],
            [targetData]: data[id][targetData]?.map((itemFile: ListType | FolderType) =>
              itemFile.id === targetId ? newObj : itemFile,
            ),
          },
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpaces.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getSpaces.fulfilled, (state, action: PayloadAction<any>) => {
      action.payload.payload = addCustomField(action.payload.payload, 'isOpen', false)
      state.projects = action.payload
      state.status = 'idle'
    })
    builder.addCase(getSpaces.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getShared.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getShared.fulfilled, (state, action: PayloadAction<any>) => {
      action.payload.payload = {
        folders: addCustomField(action.payload.folders, 'isOpen', false),
        lists: addCustomField(
          action.payload.files?.filter((file) => file.type === '{"name":"list"}'),
          'isOpen',
          false,
        ),
        documents: addCustomField(
          action.payload.files?.filter((file) => file.type === '{"name":"document"}'),
          'isOpen',
          false,
        ),
      }

      state.share = { payload: action.payload.payload }
      state.status = 'idle'
    })
    builder.addCase(getShared.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(createSpace.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(createSpace.fulfilled, (state, action: PayloadAction<any>) => {
      state.projects = {
        payload: [...(state.projects?.payload || []), { ...action.payload.payload, isOpen: false }],
      }
      state.status = 'idle'
    })
    builder.addCase(createSpace.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(deleteSpace.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(deleteSpace.fulfilled, (state) => {
      state.status = 'idle'
    })
    builder.addCase(deleteSpace.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
    builder.addCase(getStatuses.pending, (state) => {
      state.error = null
      state.status = 'loading'
    })
    builder.addCase(getStatuses.fulfilled, (state, action: PayloadAction<statusType[]>) => {
      state.statuses = (action.payload as any).payload
      state.status = 'idle'
    })
    builder.addCase(getStatuses.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload
      state.status = 'failed'
    })
  },
})

export const {
  setSpacesSocketEvent,
  resetSpacesSocketEvents,
  toggleSpace,
  setSpaceChildren,
  toggleFolder,
  setFolderChildren,
  manageFiles,
  setSpaces,
} = spacesSlice.actions

export default spacesSlice.reducer
