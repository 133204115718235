/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../../shared/utils/axios'
// import { serialize } from 'object-to-formdata'
import { GetUserActivityPayload, UpdatePasswordPayload, UpdateProfilePayload } from './profileTypes'
export const updateProfile = createAsyncThunk(
  'settings/updateProfile',
  async (query: UpdateProfilePayload) => {
    try {
      const response = await axiosInstance.patch(`/api/profile`, query)
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const updateUserPassword = createAsyncThunk(
  'settings/updateUserPassword',
  async (query: UpdatePasswordPayload) => {
    try {
      const response = await axiosInstance.patch(`/api/userspass`, query)
      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)

export const getUserActivity = createAsyncThunk(
  'settings/getUserActivity',
  async ({ userId, page, options }: GetUserActivityPayload) => {
    try {
      const params = new URLSearchParams({ skip: page.toString(), limit: '15' })
      if (options) {
        params.append('options', options)
      }
      const response = await axiosInstance.get(
        `/api/users/${userId}/activities?${params.toString()}`,
      )

      if (response.status === 200) {
        return response.data
      }

      throw new Error(response.statusText)
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  },
)
