import { ListType } from '@src/modules/spaces/components/List/List'
import StatusCard from './StatusCard'
import { memo, useCallback, useEffect, useState } from 'react'
import { DragDropContext, DraggableLocation, DropResult, Droppable } from 'react-beautiful-dnd'
import {
  useBulkUpdateTasksMutation,
  useGetBoardGroupsQuery,
  useUpdateTaskMutation,
} from '../../services/tasksApi'
import { message } from 'antd'
import { orderArray } from '../../utils/orderArray'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { statusType } from '@src/modules/spaces/data/statusSlice/statusSlice'
import { store, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { bukUpateStatus } from '@src/modules/spaces/data/statusSlice/statusThunk'
import { orderByOrderField } from '../../utils/orderByOrderField'
// import { GroupByFields } from '../../features/Tasks/Tasks'
import { resetSocketEvents } from '../../data/tasksSlice/tasksSlice'
import { socketTasksManagments } from '../../utils/socketTasksManagments'
import AddStatus from './AddStatus'
import { useSearchParams } from 'react-router-dom'
import { parseBoolean } from '@src/modules/shared/utils/parseBoolean'
import { GroupByFields } from '../../features/Tasks/Tasks'
import { priorities } from '@src/modules/spaces/__mock__'
import { getOneTask } from '../../data/tasksSlice/tasksThunk'
import { getBulkUpdateFieldByGroupBy } from '../../utils/getSortFieldByGroupBy'
import EmptyTasks from '../EmptyTasks'

const reorder = (list, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return [...result]
}

const addOrderField = (array: statusType[]) => array?.map((status, i) => ({ ...status, order: i }))

export const insert = (arr: any = [], index: number, newItem: TaskType | string) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
]

export const manageSocketUpdate = (
  updateData,
  updateInfo: { deletedTask: any; updatedTask: any; bulkUpdateTask: any; updatedMany: any },
  columns: statusType[],
  currentListId: string,
  groupBy: string,
  setTasksCount?: any,
) => {
  const { deletedTask, updatedTask, bulkUpdateTask, updatedMany } = updateInfo
  if (deletedTask) {
    updateData((prev) => socketTasksManagments.deleteTask(prev, deletedTask, setTasksCount))
  } else if (updatedTask) {
    updateData((prev) =>
      socketTasksManagments.updateTask(prev, updatedTask, currentListId, groupBy, setTasksCount),
    )
  } else if (bulkUpdateTask) {
    store
      .dispatch(getOneTask({ id: updatedTask || bulkUpdateTask?.taskUUID }))
      .unwrap()
      .then((res) => {
        const newTask = res?.payload
        if (bulkUpdateTask) {
          updateData((prev) =>
            socketTasksManagments.bulkUpdateTask(
              prev,
              bulkUpdateTask,
              columns,
              newTask,
              setTasksCount,
              groupBy,
            ),
          )
        }
      })

    // store
    //   .dispatch(getOneTask({ id: updatedTask || bulkUpdateTask?.id }))
    //   .unwrap()
    //   .then((res) => {
    //     const newTask = res?.payload
    // if (bulkUpdateTask) {
    //   if (groupBy === GroupByFields.STATUS)
    //     updateData((prev) =>
    //       socketTasksManagments.bulkUpdateTask(
    //         prev,
    //         bulkUpdateTask,
    //         columns,
    //         newTask,
    //         setTasksCount,
    //       ),
    //     )
    // } else
    //   updateData((prev) =>
    //     socketTasksManagments.updateTask(
    //       prev,
    //       updatedTask,
    //       newTask,
    //       currentListId,
    //       groupBy,
    //       setTasksCount,
    //     ),
    //   ) if (bulkUpdateTask) {
    //     if (groupBy === GroupByFields.STATUS)
    //       updateData((prev) =>
    //         socketTasksManagments.bulkUpdateTask(
    //           prev,
    //           bulkUpdateTask,
    //           columns,
    //           newTask,
    //           setTasksCount,
    //         ),
    //       )
    //   } else
    //     updateData((prev) =>
    //       socketTasksManagments.updateTask(
    //         prev,
    //         updatedTask,
    //         newTask,
    //         currentListId,
    //         groupBy,
    //         setTasksCount,
    //       ),
    //     )
    //   })
    //   .catch(() => {})
  }
  if (updatedMany) {
    socketTasksManagments.updateMany(
      updateData,
      { ...updatedMany, listId: currentListId },
      setTasksCount,
      groupBy,
    )
  }
  store.dispatch(resetSocketEvents())
}

export interface TasksByGroupType {
  [key: string]: TaskType[]
}
interface BoardProps {
  list: ListType
  groupBy?: {
    id: string
    custom: boolean
    type: GroupByFields
  }
  search: string
}

type ITaskGroup = statusType & { fieldType: string }

const Board = ({ list, groupBy, search }: BoardProps) => {
  // eslint-disable-line
  const [searchParams] = useSearchParams()
  const filterByMe = searchParams.get('byMe')
  const { deletedTask, updatedTask, bulkUpdateTask, updatedMany } = useAppSelector(
    (state) => state.tasks,
  ) //socket updates "socket events"
  const [tasksByGroup, setTasksByGroup] = useState<TasksByGroupType | null>(null)
  const [taskIsDragging, setTaskDragging] = useState<string | null>('')
  const [tasksCount, setTasksCount] = useState({})
  const [tasksGroups, setTasksGroups] = useState<ITaskGroup[]>(list?.status as ITaskGroup[])
  const {
    data: groupByData,
    refetch,
    isLoading,
  } = useGetBoardGroupsQuery(
    { source: groupBy?.custom ? `cf_${groupBy?.id}` : groupBy?.id, fileId: list?.id },
    { skip: !list?.id || !groupBy?.id, refetchOnMountOrArgChange: true },
  )
  const [bulkupdate] = useBulkUpdateTasksMutation()
  const [updateTask] = useUpdateTaskMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      list?.id &&
      GroupByFields.PRIORITY !== groupBy?.type &&
      GroupByFields.STATUS !== groupBy?.type
    )
      refetch()
    manageSocketUpdate(
      setTasksByGroup,
      { deletedTask, updatedTask, bulkUpdateTask, updatedMany },
      tasksGroups,
      list?.id,
      groupBy?.type,
      setTasksCount,
    )
  }, [deletedTask, updatedTask, bulkUpdateTask, updatedMany])

  useEffect(() => {
    if (list?.status && groupBy?.type === GroupByFields.STATUS) {
      setTasksGroups(list.status as ITaskGroup[])
    } else if (groupBy?.type === GroupByFields.PRIORITY) {
      setTasksGroups(priorities)
    } else setTasksGroups(groupByData)
  }, [list?.status, groupByData, groupBy])

  const handleTaskDragEnd = useCallback(
    async (source: DraggableLocation, destination: DraggableLocation, isItselfReorder: boolean) => {
      const sourceStatusId = source.droppableId
      const destinationStatusId = destination.droppableId

      const sourceOrder = source.index
      const destinationOrder = destination.index

      const sourceTasks = [...tasksByGroup[sourceStatusId]]
      const [taskToMove] = sourceTasks?.splice(sourceOrder, 1) || []

      const setNewDataField = () => {
        if (groupBy?.type === GroupByFields.PRIORITY) {
          return +destinationStatusId
        } else if (groupBy?.type === GroupByFields.ASSIGNEE) {
          return destinationStatusId !== 'not_assigned_users' ? destinationStatusId.split('&') : []
        }
      }

      const destinationTasks = tasksByGroup[destinationStatusId] || []
      const newTasks =
        insert(isItselfReorder ? sourceTasks : destinationTasks, destinationOrder, {
          ...taskToMove,
          [groupBy?.type]:
            list?.status.find((status) => status.id === destinationStatusId) || setNewDataField(),
        }) || []

      try {
        // Local update task order
        setTasksByGroup((prev: { [key: string]: TaskType[] }) => ({
          ...prev,
          [destinationStatusId]: newTasks,
          ...(!isItselfReorder ? { [sourceStatusId]: [...sourceTasks] } : {}),
        }))

        const newStatus =
          list?.status.find((status) => status.id === destinationStatusId) || taskToMove.status

        setTasksCount((prev) => ({
          ...prev,
          [newStatus?.id]: {
            ...prev[newStatus?.id],
            total_items: prev[newStatus?.id]?.total_items + 1,
          },
          [sourceStatusId]: {
            ...prev[sourceStatusId],
            total_items:
              prev[sourceStatusId]?.total_items === 0 ? 0 : prev[sourceStatusId]?.total_items - 1,
          },
        }))

        const taskBelow = newTasks[destinationOrder - 1]

        await bulkupdate({
          taskUUID: taskToMove?.id,
          statusId: newStatus?.id,
          fileId: list?.id,
          taskBelowUUID: taskBelow?.id,
          targetIndex: getBulkUpdateFieldByGroupBy(groupBy?.type),
        }).unwrap()

        if (!isItselfReorder) {
          updateTask({ id: taskToMove?.id, [groupBy?.type]: setNewDataField() })
            .unwrap()
            .then(() => refetch())
        }
      } catch (error) {
        console.error('Error updating tasks:', error)
        setTasksByGroup((prev: { [key: string]: TaskType[] }) => ({
          ...prev,
          [destinationStatusId]: destinationTasks,
          [sourceStatusId]: tasksByGroup[sourceStatusId],
        }))
        message.error('Error updating tasks')
      }
    },
    [tasksByGroup, bulkupdate, orderArray], // eslint-disable-line
  )

  const handleStatusDragEnd = useCallback(
    async (sourceIndex: number, destinationIndex: number) => {
      const reorderedStatuses = reorder(tasksGroups, sourceIndex, destinationIndex) as statusType[]
      const updatedStatuses = addOrderField(reorderedStatuses) as ITaskGroup[]
      try {
        setTasksGroups(updatedStatuses)
        await dispatch(
          bukUpateStatus({
            status: updatedStatuses.map((status, i) => ({ id: status?.id, order: i })),
          }),
        ).unwrap()
      } catch (error) {
        console.error('Error updating status order:', error)
        setTasksGroups(tasksGroups)
        message.error('Error updating status order')
      }
    },
    [tasksGroups, dispatch, bukUpateStatus], // eslint-disable-line
  )

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      setTaskDragging(null)

      const { destination, source, type } = result

      if (!destination) return

      if (source.droppableId === destination.droppableId && source.index === destination.index)
        return

      const isItselfReorder = source.droppableId === destination.droppableId

      if (type === 'TASKS') {
        handleTaskDragEnd(source, destination, isItselfReorder)
      } else {
        handleStatusDragEnd(source.index, destination.index)
      }
    },
    [handleTaskDragEnd, handleStatusDragEnd],
  )

  const isCompatible =
    [GroupByFields?.PRIORITY, GroupByFields?.STATUS].includes(groupBy?.type) ||
    tasksGroups?.every((item) => item?.fieldType === groupBy?.type || !item?.fieldType)

  if (!isCompatible) return null

  const isEmpty = !isLoading && tasksGroups && !tasksGroups?.length

  return (
    <div className="view-board">
      {isEmpty ? (
        <EmptyTasks />
      ) : (
        <DragDropContext onDragStart={(e) => setTaskDragging(e.type)} onDragEnd={handleDragEnd}>
          <Droppable droppableId="board" direction="horizontal" type="COLUMN">
            {(provided) => (
              <div
                className="view-board-status"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {orderByOrderField(tasksGroups)?.map((data: statusType, i: number) => {
                  return (
                    <StatusCard
                      data={data}
                      list={list}
                      filterByMe={parseBoolean(filterByMe)}
                      search={search}
                      tasks={tasksByGroup && tasksByGroup[data?.id]}
                      groupBy={groupBy}
                      tasksCount={tasksCount[data?.id] || []}
                      setTasksCount={setTasksCount}
                      setTasks={setTasksByGroup}
                      key={data.id}
                      taskIsDraging={!!taskIsDragging}
                      index={i}
                    />
                  )
                })}
                {taskIsDragging !== 'COLUMN' && groupBy?.type === GroupByFields.STATUS ? (
                  <AddStatus data={list} />
                ) : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  )
}

export default memo(Board) as typeof Board
