import {
  clearAllNotifications,
  getAllNotifications,
} from '@src/modules/notifications/data/NotificationsThunk'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store/index'
import { useCallback, useEffect, useState } from 'react'

import NotificationCard from '@src/modules/notifications/components/NotificationCard/NotificationCard'
import { Skeleton } from 'antd'
import { ReactComponent as ClearAllIcon } from '../../assets/icons/activities/clearAll.svg'
import { ReactComponent as ClearedIcon } from '../../assets/icons/activities/cleared.svg'
import { ReactComponent as NewIcon } from '../../assets/icons/activities/new.svg'
import noNotifs from '../../assets/icons/activities/noNotifs.svg'
import { useTranslation } from 'react-i18next'

const renderEmptyView = (t) => {
  return (
    <div className="no-notifications">
      <img src={noNotifs} alt="No Notifications" />
      <p>{t('You have no notifications yet ..')}</p>
    </div>
  )
}

const RecentActivities = () => {
  const { t } = useTranslation(['notifications'])

  const dispatch = useAppDispatch()
  const { notifications, metadata, fetchStatus } = useAppSelector((state) => state.notification)
  const [notifData, setNotifData] = useState(notifications?.payload || [])
  const [newNotif, setNewNotif] = useState(notifications?.payload || [])
  const [isSelected, setIsSelected] = useState('new')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const isRead = isSelected === 'new' ? false : true

  const fetchNotifications = useCallback(() => {
    dispatch(getAllNotifications({ isRead, page, isMention: false, type: 'all' }))
      .unwrap()
      .then((res) => {
        if (page === 1) {
          isRead ? setNotifData(res?.payload?.payload) : setNewNotif(res?.payload?.payload)
        } else {
          isRead
            ? setNotifData((prev) => [...prev, ...res.payload.payload])
            : setNewNotif((prev) => [...prev, ...res.payload.payload])
        }
        setLoading(false)
      })
  }, [dispatch, isRead, page])

  useEffect(() => {
    fetchNotifications()
  }, [isRead, page, fetchNotifications])

  const loadMore = () => {
    if (metadata?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const clearAllNotificationsHandler = () => {
    if (!isRead)
      dispatch(clearAllNotifications(''))
        .unwrap()
        .then(() => {
          setNotifData([])
        })
  }

  const infiniteScrollHandler = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    if (scrollHeight - scrollTop === clientHeight && metadata?.next) {
      setLoading(true)
      loadMore()
    }
  }

  const RecentActivitySkeleton = () => (
    <div className="skeleton-activity">
      <Skeleton.Avatar active={true} style={{ width: '40px', height: '40px' }} />
      <div>
        <Skeleton.Input
          active={true}
          style={{ height: '20px', width: '250px', borderRadius: '8px' }}
        />
        <Skeleton.Input
          active={true}
          style={{ height: '20px', width: '200px', borderRadius: '8px' }}
        />
      </div>
    </div>
  )

  return (
    <div className="recent-activities">
      <div className={`recent-activities-top `}>
        <div
          className={`act-btn clear-all ${isSelected === 'cleared' || (!isRead && newNotif?.length === 0) ? 'no-recent-activities-top' : ''}`}
          onClick={() => clearAllNotificationsHandler()}
          aria-disabled={isSelected === 'cleared' || (!isRead && newNotif?.length === 0)}
        >
          <ClearAllIcon /> {t('Clear All')}
        </div>

        <div
          className={`act-btn new ${isSelected === 'new' ? 'selected' : ''}`}
          onClick={() => {
            setIsSelected('new')
            setPage(1)
          }}
        >
          <NewIcon /> {t('New')}
        </div>

        <div
          className={`act-btn cleared ${isSelected === 'cleared' ? 'selected' : ''}`}
          onClick={() => {
            setIsSelected('cleared')
            setPage(1)
          }}
        >
          <ClearedIcon /> {t('Cleared')}
        </div>
      </div>
      <div className="recent-activities-content" onScroll={infiniteScrollHandler}>
        {fetchStatus === 'loading' && page === 1 ? (
          <>
            <RecentActivitySkeleton />
            <RecentActivitySkeleton />
            <RecentActivitySkeleton />
          </>
        ) : isRead && notifData?.length === 0 ? (
          renderEmptyView(t)
        ) : !isRead && newNotif?.length === 0 ? (
          renderEmptyView(t)
        ) : (
          (isRead ? notifData : newNotif)?.map((notif) => {
            return <NotificationCard notification={notif} isInDashboard={true} key={notif.id} />
          })
        )}

        {loading && metadata?.next && <RecentActivitySkeleton />}
      </div>
    </div>
  )
}

export default RecentActivities

{
  /* {notifData?.length > 0 && (
        <InfiniteScroll
          dataLength={notifData?.length || 0}
          next={loadMore}
          hasMore={metadata?.next}
          loader={false}
          className="recent-activities-content"
          height={'calc(100% - 30px)'}
        >
          {notifData?.map((notif) => {
            return <NotificationCard notification={notif} isInDashboard={true} key={notif.id} />
          })}

          {fetchStatus === 'loading' && page !== 1 && (
            <div className="skeleton-activity">
              <Skeleton.Avatar active={true} style={{ width: '40px', height: '40px' }} />
              <div>
                <Skeleton.Input
                  active={true}
                  style={{ height: '20px', width: '250px', borderRadius: '8px' }}
                />
                <Skeleton.Input
                  active={true}
                  style={{ height: '20px', width: '200px', borderRadius: '8px' }}
                />
              </div>
            </div>
          )}
        </InfiniteScroll>
      )} */
}
