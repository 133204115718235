import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { resetFilter, setFilter } from '@src/modules/shared/store/slices/filter/filterSlice'
import { Dropdown, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetTaskModelsFilterQuery } from '../../services/tasksApi'
import TaskPriority from '../TaskPriority'
import { ReactComponent as ClearIcon } from './../../assets/icons/filter/clear.svg'
import { ReactComponent as CollapseIcon } from './../../assets/icons/filter/collapse.svg'
import { ReactComponent as DeleteIcon } from './../../assets/icons/filter/delete.svg'
import { ReactComponent as FilterIcon } from './../../assets/icons/tasks/filter.svg'
import DateFilter from './components/DateFilter/DateFilter'
import MembersFilter from './components/MembersFilter/MembersFilter'
import NameFilter from './components/NameFilter/NameFilter'
import TagsFilter from './components/TagsFilter/TagsFilter'
import { filterFields } from './helpers/filterFields'
import { getFieldsFilter } from './helpers/getFieldsFilter'
import { getFilterCount } from './helpers/getFilterCount'
import { getOperationFilter } from './helpers/getOperationFilter'
import { getOrmOptions } from './helpers/getOrmOptions'
import { isValidFilter } from './helpers/isValidFilter'
import { parser } from './helpers/parser'

const emptyFilterSchema = (lastIndex: number) => ({
  orm: lastIndex ? 'AND' : 'where',
  field: null,
  type: '',
  operation: null,
  targetFilter: null,
  id: lastIndex + 1,
})

const TargetFilterComponents = {
  name: NameFilter,
  // status: StatusFilter,
  dueDate: DateFilter,
  tags: TagsFilter,
  priority: TaskPriority,
  users: MembersFilter,
}

interface FilterObject {
  orm: string
  field: string
  type: string
  operation: string
  targetFilter: any
  id: number
}

const FilterItem = ({ data, setFilters, filter, onDelete, t }: any) => {
  const TargetFilterComponent = TargetFilterComponents[filter.type]
  const setFilter = (filter) => {
    setFilters((prev) => {
      return prev.map((savedFilter) =>
        savedFilter?.id === filter?.id ? { ...filter, error: null } : savedFilter,
      )
    })
  }
  const title = !filter?.field
    ? t('pleas_select_field')
    : !filter?.operation
      ? t('pleas_select_operation')
      : ''

  return (
    <div className={`filter-item`}>
      <Dropdown
        menu={{
          items: getOrmOptions(setFilter, filter),
        }}
        trigger={['click']}
        disabled={filter?.orm === 'where'}
      >
        <div className="filter-item-orm">
          {filter?.orm} {filter?.orm !== 'where' && <CollapseIcon />}
        </div>
      </Dropdown>

      <div className="filter-item-query">
        <Tooltip title={title}>
          <Dropdown menu={{ items: getFieldsFilter(data, setFilter, filter) }} trigger={['click']}>
            <div className="filter-item-field">
              {filter?.field || t('select_filter')} <CollapseIcon />
            </div>
          </Dropdown>
        </Tooltip>
        <Tooltip title={title}>
          <Dropdown
            menu={{ items: getOperationFilter(data, setFilter, filter?.field, filter) }}
            trigger={['click']}
            disabled={!filter?.field}
          >
            <div className="filter-item-operation">
              {filter?.operation === 'ilike' ? 'is like' : filter?.operation || t('select')}
              <CollapseIcon />
            </div>
          </Dropdown>
        </Tooltip>
        <Tooltip title={title}>
          <div className="filter-item-data">
            {TargetFilterComponent ? (
              <TargetFilterComponent
                disable={!filter?.operation}
                onData={(data) => setFilter({ ...filter, targetFilter: data })}
                onSelect={(data) => {
                  const isArray = Array.isArray(data)
                  setFilter({ ...filter, targetFilter: isArray ? data : data?.toString() || null })
                }}
                showLabel
                activePrioritiesIds={filter?.targetFilter}
              />
            ) : (
              t('select_filter')
            )}
          </div>
        </Tooltip>
        <span className="delete-filter-icon" onClick={() => onDelete(filter?.id)}>
          <DeleteIcon />
        </span>
      </div>
    </div>
  )
}

const Filter = ({ filter, scope }) => {
  const { t } = useTranslation(['tasks'])
  const { data } = useGetTaskModelsFilterQuery({})

  const [filters, setFilters] = useState<FilterObject[] | null>([emptyFilterSchema(0)])
  const dispatch = useAppDispatch()

  const createNewFilterSchema = () =>
    setFilters([...(filters || []), emptyFilterSchema(filters?.length || 0)])

  const deleteFilter = (id: number) => {
    const DeletedfilterIndex = filters?.findIndex((filter) => filter.id === id)
    if (DeletedfilterIndex === -1) return
    const Deletedfilter = { ...filters[DeletedfilterIndex], index: DeletedfilterIndex }
    if (Deletedfilter?.index !== 0 && Deletedfilter?.orm !== 'where') {
      setFilters(filters?.filter((filter) => filter?.id !== id))
    } else if (Deletedfilter?.index === 0 && Deletedfilter?.orm === 'where') {
      setFilters((prev) => {
        const newFilters = [...prev]
        newFilters.splice(0, 1, emptyFilterSchema(0))
        return newFilters
      })
    }
  }

  useEffect(() => {
    if (!filter) setFilters([emptyFilterSchema(0)])
  }, [filter])

  useEffect(() => {
    if (filters[0]?.field) {
      if (!isValidFilter(filters)) return
      const filterObject = parser.formatFilter(filters)
      dispatch(setFilter({ filter: filterObject, model: scope }))
    } else dispatch(setFilter({ filter: null, model: scope }))
  }, [filters])

  return (
    <div className="ant-dropdown-menu tasks-filter" id="tasks-filter">
      <div className="tasks-filter-header">
        <p>{t('filter')}</p>
      </div>

      <div className="tasks-filter-body">
        {filters?.map((filter, i) => (
          <FilterItem
            data={filterFields(data, TargetFilterComponents)}
            filter={filter}
            setFilters={setFilters}
            onDelete={deleteFilter}
            t={t}
            key={i}
          />
        ))}
      </div>

      <div className="tasks-filter-footer" onClick={createNewFilterSchema}>
        <span>+</span>

        <p>{t('add_filter')}</p>
      </div>
    </div>
  )
}

const TasksFilter = ({ scope }) => {
  const { t } = useTranslation(['tasks'])
  const disptach = useAppDispatch()
  const { tasks: filter } = useAppSelector((state) => state.filter)
  const filterCount = getFilterCount(filter)

  const clearFilter = (e) => {
    e.stopPropagation()
    disptach(resetFilter({ model: scope }))
  }

  return (
    <Dropdown
      dropdownRender={() => <Filter filter={filter} scope={scope} />}
      placement={'bottomCenter'}
      trigger={['click']}
    >
      <div className="dashboard-tasks-button filter-btn">
        <FilterIcon />
        <p>{t('filter')}</p>{' '}
        {filterCount ? (
          <>
            <span>{filterCount}</span>{' '}
            <ClearIcon className="filter-btn-clear" onClick={clearFilter} />
          </>
        ) : null}
      </div>
    </Dropdown>
  )
}

export default TasksFilter
