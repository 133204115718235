import styled from 'styled-components'
import { isArabic } from '../../utils/isArabic'
import OverflowTooltip from '../OverflowTooltip'

interface ArabicSupportProps {
  tag: 'p' | 'div' | 'span'
  content: string
  className?: string
  onClick?: () => void
  tooltip?: boolean
}

const createArabicElement = (tag: 'p' | 'div' | 'span') => styled(tag)`
  direction: ${(props) => (isArabic(props.children as string) ? 'rtl' : 'ltr')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ArabicElement = ({ content, tooltip, tag, ...props }: ArabicSupportProps) => {
  const Arabic = createArabicElement(tag)

  return (
    <OverflowTooltip title={tooltip ? content : null}>
      <Arabic {...props}>{content}</Arabic>
    </OverflowTooltip>
  )
}

export default ArabicElement
