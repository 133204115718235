import { removeDuplicatesById } from '@src/modules/settings/utils/removeDup'
import { api } from '@src/modules/shared/services/api'
import { TimerType } from '@src/modules/tasks/data/timerData/types'
const defaultLimit = 10
type ApiResponse<T> = {
  payload: T
  metadata: {
    current_page: number
    last_page: number
    next: { page: number; limit: number }
    rows_per_page: number
    total_items: number
  }
}
type ApiEditTimer = {
  data: TimerType
  metadata: {
    current_page: number
    last_page: number
    next: { page: number; limit: number }
    rows_per_page: number
    total_items: number
  }
}
export const TimerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTimer: build.query<ApiResponse<TimerType[]>, { id: string; limit?: number; skip?: number }>({
      query: (params) => {
        const { id, limit = defaultLimit, skip } = params
        return {
          url: `api/timer/${id}`,
          params: { limit, skip },
        }
      },
      transformResponse: (response: { payload }) => {
        return response.payload
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      providesTags: [{ type: 'Timer', id: 'LIST' }] as any,
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs.arg
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }
        currentCache.metadata = newItems.metadata

        currentCache.payload =
          shouldCache || otherArgs.arg.skip > 1
            ? removeDuplicatesById(currentCache?.payload)
            : newItems?.payload
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
    }),

    addManualTimer: build.mutation<
      ApiResponse<TimerType>,
      { startTime: string; endTime: string; taskId: string; description?: string }
    >({
      query: (body) => ({
        url: 'api/timer',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { payload }) => {
        return response.payload
      },
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),

    startTimer: build.mutation<ApiResponse<TimerType>, { taskId: string; description?: string }>({
      query: (body) => ({
        url: 'api/timer/start',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { payload }) => {
        return response.payload
      },
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),

    closeTimer: build.mutation<ApiResponse<TimerType>, { timerId: string }>({
      query: (body) => ({
        url: 'api/timer/close',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),

    deleteTimer: build.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `api/timers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),

    editTimer: build.mutation<ApiEditTimer, { timerId: string; payload: Partial<TimerType> }>({
      query: ({ timerId, payload }) => ({
        url: `api/timers/${timerId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),

    addTimer: build.mutation<
      ApiResponse<TimerType>,
      { name: string; color: string; spaceId: string }
    >({
      query: (body) => ({
        url: `api/Timer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),
    openTimer: build.mutation<
      ApiResponse<TimerType>,
      { name: string; color: string; spaceId: string }
    >({
      query: (body) => ({
        url: `api/Timer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Timer', id: 'LIST' }] as any,
    }),
  }),
})

export const {
  useGetTimerQuery,
  useAddManualTimerMutation,
  useStartTimerMutation,
  useCloseTimerMutation,
  useDeleteTimerMutation,
  useEditTimerMutation,
  useAddTimerMutation,
} = TimerApi
