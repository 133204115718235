import {
  Avatar,
  Modal,
  Switch,
  Spin,
  Tooltip,
  Empty,
  message,
  Checkbox,
  Dropdown,
  MenuProps,
} from 'antd'
import { ReactComponent as CloseIcon } from '../../../../spaces/assets/icons/list/collapse-modal.svg'
import { ReactComponent as ShareIcon } from '../../../../shared/assets/icons/share/share.svg'
import { ReactComponent as LinkSVG } from '../../../assets/icons/share/link.svg'
import { ReactComponent as PublicSVG } from '../../../assets/icons/share/public.svg'

import CopyWhiteSVG from '../../../assets/icons/share/copy-icon-white.svg'
import { ReactComponent as DropDown } from '../../../assets/icons/share/drop-down.svg'

import { ReactComponent as CopySVG } from '../../../assets/icons/share/copy.svg'
import { ReactComponent as ArrowSVG } from '../../../assets/icons/share/arrow.svg'
import MemberRole from '@src/modules/settings/components/MemberRole'
import { memberType } from '@src/modules/settings/components/MemberName/MemberName'
import {
  MembersApi,
  useDeleteShareMutation,
  useGetMembersQuery,
  useGetSharesQuery,
  useShareMutation,
  useUpdateShareMutation,
} from '@src/modules/settings/services/membersApi'
import { useEffect, useRef, useState } from 'react'
import { RoleType } from '@src/modules/settings/components/MemberRole/MemberRole'
import { useAppSelector } from '@src/modules/shared/store'
import Spinner from '../../Spinner'
import { useAppDispatch } from '@src/modules/shared/store/index'
import { useTranslation } from 'react-i18next'
import { makePublicOrPrivate } from './utils/updateState'
import { reformulateData } from './utils/reformulateData'
import { getDataParent } from './utils/getDataParent'
import { useGetListQuery } from '@src/modules/spaces/services/fileApi'
import {
  useGetDocumentByIdQuery,
  useGetDocumentQuery,
  useShareDocumentMutation,
} from '@src/modules/spaces/services/documentApi'
import isEmailValidator from 'validator/lib/isEmail'
import { inviteToWorkspace } from '@src/modules/auth/data/authThunk'
import Button from '@src/modules/shared/components/Button/Button'
import { getTimeDifferenceFromDate } from '@src/modules/dailyQuestions/components/MemberView/utils/getTimeDifferenceFromDate'
import { getDataType } from './utils/getDataType'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import Search from '@src/modules/shared/components/Input/search'

const MemberItem = ({
  member,
  disabled,
  isOnwer,
  ...props
}: {
  member: memberType & { share: any }
  shareData: any
  disabled: boolean
  isOnwer: boolean
}) => {
  const { user } = useAppSelector((state) => state.auth)
  const [share] = useShareMutation()
  const [deleteShare] = useDeleteShareMutation()
  const [updateShare] = useUpdateShareMutation()
  const [defaultRole, setDefaultRole] = useState<RoleType>(null)
  const [role, setRole] = useState<RoleType>(member?.share?.roleId)
  const userId = user?.user?.id

  return (
    <div className="members-list-item" key={member?.id}>
      <Avatar src={member?.avatar}>
        {(member?.fullName || member?.email || '')[0]?.toUpperCase()}
      </Avatar>

      <OverflowTooltip className="member-name" title={member?.fullName || member?.email}>
        {member?.fullName || member?.email || '-'}
      </OverflowTooltip>

      <div className="share-button">
        <div className="share-role">
          {isOnwer ? (
            'Owner'
          ) : (
            <MemberRole
              disabled={userId === member?.id}
              role={member?.share?.roleId}
              onClick={(r) => {
                if (member?.share?.id) updateShare({ id: member?.share?.id, roleId: r?.id })
                setRole(r)
              }}
              setDefaultRole={setDefaultRole}
              level={2}
              className="member-role-table"
              isOwner={false}
            />
          )}
        </div>

        <Switch
          size="small"
          onChange={(e) => {
            if (disabled) return
            e
              ? share({ userId: member.id, roleId: (role || defaultRole)?.id, ...props.shareData })
              : deleteShare({ id: member?.share?.id })
          }}
          defaultChecked={isOnwer || !!member?.share}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

const ShareModal = ({ open, handleClose, data, id }: any) => {
  const { t } = useTranslation(['modals'])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [invitationLoading, setInvitationLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [emails, setEmails] = useState<{
    emails: string[] | null
    role?: string
    memberRole?: string
  }>(null)
  const dispatch = useAppDispatch()

  const [isPeopleDisplayed, setDisplayShow] = useState<boolean>(false)
  const [share] = useShareMutation()
  let { user } = useAppSelector((state) => state.auth)
  const { data: list } = useGetListQuery({ id: data.id }, { skip: data.type !== 'fileId' })
  const { data: document } = useGetDocumentQuery(data.id, { skip: data.type !== 'documentId' })
  const { data: sharedDocument } = useGetDocumentByIdQuery({ id: data.id })

  user = user?.user
  const divRef = useRef(null)
  const [shareWithAnyone, setShareWithAnyone] = useState(sharedDocument == undefined ? false : true)
  const [expireIn, setExpireIn] = useState('')
  const [neverExpier, setNeverExpier] = useState(!!sharedDocument?.expiresAt == null)
  const [dropDown, setDropDown] = useState(false)
  const [sharedDocumentId, setSharedDocumentId] = useState('')
  const [checkboxSelected, setCheckboxSelected] = useState(sharedDocument?.expiresAt != null)
  const sharingName = getDataType(data)

  useEffect(() => {
    if (sharedDocument) {
      setShareWithAnyone(sharedDocument == undefined ? false : true)
      setNeverExpier(sharedDocument?.expiresAt == null)
      setCheckboxSelected(sharedDocument?.expiresAt != null)
      setSharedDocumentId(sharedDocument?._id)
    }
  }, [sharedDocument])
  const { data: shares, refetch: refetchShares } = useGetSharesQuery(
    { [data?.type]: data?.id },
    { skip: !open },
  )

  const {
    data: members,
    isLoading,
    isFetching,
  } = useGetMembersQuery(
    { limit: 20, skip: currentPage, search },
    { skip: !open || (!isPeopleDisplayed && !search) },
  )

  const makePublicPermission = user?.id === data?.ownerId

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    if (scrollHeight - scrollTop === clientHeight && members?.metadata?.last_page > currentPage) {
      setCurrentPage((prev) => prev + 1)
      setLoadMore(true)
    }
  }

  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    if (!open) return
    refetchShares()
  }, [open])

  useEffect(() => {
    if (search && isEmailValidator(search) && !isLoading && !isFetching) {
      setEmails((prev) => ({ ...prev, emails: [search] }))
    } else setEmails(null)

    dispatch(
      MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
        setCurrentPage(1)
        scrollToTop()
        if (draft && draft.payload) draft.payload = []
        return draft
      }),
    )
  }, [search])

  useEffect(() => {
    if (members) {
      setLoadMore(false)
      if (members?.payload.length > 0) setEmails(null)
      else if (search && isEmailValidator(search))
        setEmails((prev) => ({ ...prev, emails: [search] }))
    }
  }, [members])

  const parent = getDataParent(data, { document, list, folder: data })

  const inviteGuestMember = () => {
    setInvitationLoading(true)
    dispatch(inviteToWorkspace({ emails: emails.emails, role: emails.memberRole }))
      .unwrap()
      .then((res) => {
        const { sendTo } = res?.result || {}

        Promise.all(
          sendTo?.map((user) =>
            share({ userId: user.id, roleId: emails.role, [data.type]: data.id }),
          ),
        )
          .then(() => {
            setSearch(null)
          })
          .catch((error) => {
            console.error(t('share_space.Error occurred:'), error)
          })
          .finally(() => setInvitationLoading(false))
      })
  }
  const [ShareDocument] = useShareDocumentMutation()
  const onClick: MenuProps['onClick'] = ({ key }) => {
    setExpireIn(key)
    setCheckboxSelected(true)
    setDropDown(false)
    setNeverExpier(false)
    handelSave(key, true)
  }
  const items: MenuProps['items'] = [
    {
      label: `1 ${t('share_space.day')}`,
      key: '1',
    },
    {
      label: `7 ${t('share_space.days')}`,
      key: '7',
    },
    {
      label: `15 ${t('share_space.days')}`,
      key: '15',
    },
    {
      label: `30 ${t('share_space.days')}`,
      key: '30',
    },
  ]
  const handelSave = (expireIn, publicSharing) => {
    if (data?.type === 'documentId') {
      let expireInDate = undefined

      if (expireIn) {
        expireInDate = new Date()
        expireInDate.setDate(expireInDate.getDate() + parseInt(expireIn))
      }

      ShareDocument({
        type: 'document',
        expiresAt: expireInDate,
        publicShared: publicSharing,
        entityId: data.id,
      })
        .unwrap()
        .then((value) => {
          setSharedDocumentId(value?.payload?._id)
        })
    }
  }
  const host = window?.location?.host
  let newHost
  if (host.startsWith('docs.')) {
    newHost = host.replace('docs.app.', 'docs.').replace('docs.', '')
  } else if (host.includes('app.')) {
    newHost = host.replace('app.', '')
  } else {
    newHost = host
  }
  return (
    <Modal
      footer={false}
      centered={true}
      transitionName=""
      open={open}
      onOk={() => handleClose(id)}
      onCancel={() => handleClose(id)}
      wrapClassName="custom-modal space-modal list-modal share-modal"
      closable={false}
    >
      <div className="space-modal-header">
        <p className="modal-title">
          <ShareIcon />{' '}
          {data.type === 'documentId'
            ? t('share_space.sharing_document')
            : t(`share_space.share_this_${sharingName}`)}
        </p>

        <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
      </div>

      <div>
        <div className="share-modal-path">
          {data.type === 'documentId' ? (
            <>
              <span>{t('space.Sharing')} :</span>
              <span className="share-modal-path-doc-name">{data?.name}</span>
            </>
          ) : (
            <>
              <span>{t('share_space.share')}</span>
              <span className="share-modal-path-doc-name">{data?.name}</span>
              <span>{data.resource}</span>
              <span>{t('share_space.including all views')}</span>
            </>
          )}
        </div>

        <div className="share-modal-members-list">
          <div className="search-input">
            <Search
              variant="label-inline"
              onSearch={(s) => {
                setCurrentPage(1)
                setSearch(s)
              }}
              value={search}
              placeholder={t('share_space.search_for_member')}
              prefix={''}
            />
          </div>
          {data?.type === 'documentId' ? (
            <div className="share-modal-link">
              <div className="share-modal-link-left">
                <PublicSVG />
                <p className="share-modal-link-left-text">
                  {t('share_space.share_link_with_anyone')}
                </p>
              </div>

              <Switch
                defaultChecked={false}
                checked={shareWithAnyone}
                onChange={(e) => {
                  handelSave(0, e)
                  setShareWithAnyone(e)
                  setNeverExpier(true)
                }}
                size="small"
              />
            </div>
          ) : null}
          {shareWithAnyone && (
            <div className="share-drop-down-link">
              <Button
                children={t('share_space.copy_public_link')}
                disabled={!sharedDocumentId}
                onClick={() => {
                  navigator.clipboard.writeText(`docs.${newHost}/${sharedDocumentId}`)
                  message.success(t('share_space.link_copied'))
                }}
                htmlType="button"
                size="sm"
                icon={CopyWhiteSVG}
                className="share-drop-down-link-button"
              />
              <div className="share-drop-down-link-container">
                <div className="share-drop-down-link-content">
                  <div className="share-drop-down-link-content-left">
                    <span>{t('share_space.link_expiration')}</span>
                  </div>
                  <div className="share-drop-down-link-content-right">
                    <Checkbox
                      className="Checkbox"
                      name="c2"
                      checked={neverExpier}
                      onChange={() => {
                        setNeverExpier(!neverExpier)
                        setCheckboxSelected(false)
                        setExpireIn('')
                        handelSave(0, true)
                      }}
                    >
                      <span>{t('share_space.never_expires')}</span>
                    </Checkbox>
                    <Checkbox
                      className="Checkbox"
                      name="c1"
                      checked={checkboxSelected}
                      onChange={() => {
                        setDropDown(true)
                        setNeverExpier(false)

                        if (checkboxSelected) {
                          setCheckboxSelected(false)
                          setDropDown(false)

                          setExpireIn('')
                        }
                      }}
                    >
                      <Dropdown
                        menu={{ items, onClick }}
                        trigger={['click']}
                        open={dropDown}
                        onOpenChange={() => setDropDown(!dropDown)}
                      >
                        <div
                          className="Checkbox-item"
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          <span>
                            {t('share_space.expires_in')}{' '}
                            {sharedDocument && expireIn == ''
                              ? sharedDocument?.expiresAt &&
                                getTimeDifferenceFromDate(
                                  sharedDocument?.expiresAt && sharedDocument.expiresAt,
                                  'en',
                                  true,
                                )
                              : expireIn != '' && (
                                  <>
                                    {expireIn} {t('share_space.days')}
                                  </>
                                )}
                          </span>
                          <DropDown />
                        </div>
                      </Dropdown>
                    </Checkbox>
                  </div>
                </div>
                <div className="share-drop-down-link-content">
                  <div className="share-drop-down-link-content-left">
                    <span>{t('share_space.public_duplication')}</span>
                  </div>
                  <div className="share-drop-down-link-content-right">
                    <Tooltip title="coming soon">
                      <Switch
                        //   defaultChecked={false}
                        disabled={true}
                        onChange={(e) => setShareWithAnyone(e)}
                        //   disabled={}
                        size="small"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!search && (data?.type === 'fileId' || data?.type === 'documentId') ? (
            <div className="share-modal-link">
              <div className="share-modal-link-left">
                <LinkSVG />
                <p className="share-modal-link-left-text">{t('share_space.Private link')}</p>
              </div>
              <div
                className="share-modal-link-right"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/spaces/${data?.resource}/${data.id}`,
                  )

                  message.success(t('share_space.link_copied'))
                }}
              >
                <CopySVG />
                <p>{t('share_space.Copy link')}</p>
              </div>
            </div>
          ) : null}

          <div className="members-list">
            {emails && !isFetching && (
              <div className="privacy-settings invite-guest">
                <div className="privacy-settings-left">
                  <MemberRole
                    role={undefined}
                    onClick={(r) => setEmails((prev) => ({ ...prev, memberRole: r?.name }))}
                  />
                </div>
                <div className="privacy-settings-right">
                  <div className="share-role">
                    <MemberRole
                      role={undefined}
                      onClick={(r) => setEmails((prev) => ({ ...prev, role: r?.id }))}
                      level={2}
                      className="member-role-table"
                      isOwner={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {search ? null : (
              <>
                <div className="members-list-header">
                  <div className="share-with-label">
                    {/* <CollapseIcon className="share-collapse-icon" /> */}
                    {t('share_space.share_with')}
                  </div>
                </div>
                <div className="privacy-settings">
                  <div className="privacy-settings-left">
                    <ArrowSVG className="privacy-settings-arrow" />{' '}
                    <Tooltip title={parent?.name}>
                      {parent?.name?.length > 15
                        ? parent?.name?.slice(0, 15) + '...'
                        : parent?.name || t('share_space.parent_not_exist')}
                    </Tooltip>
                    {data?.type === 'spaceId' && (
                      <div className="privacy-settings-workspace">{t('share_space.Workspace')}</div>
                    )}
                  </div>
                  <div className="privacy-settings-right">
                    {t('share_space.public')}
                    <Switch
                      defaultChecked={!data?.isPrivate}
                      onChange={(e) => makePublicPermission && makePublicOrPrivate(e, data)}
                      disabled={!makePublicPermission}
                      size="small"
                    />
                  </div>
                </div>
                <div className="privacy-settings">
                  <div
                    className="privacy-settings-left"
                    onClick={() => setDisplayShow(!isPeopleDisplayed)}
                  >
                    <ArrowSVG
                      className={`active-arrow privacy-settings-arrow ${isPeopleDisplayed ? 'arrow-down' : ''}`}
                    />{' '}
                    {t('share_space.People')}
                  </div>
                  <div className="privacy-settings-right">
                    <Avatar.Group max={{ count: 5 }}>
                      {shares?.payload
                        ?.filter((share) => !!share?.user)
                        ?.map((share, index: number) => (
                          <Tooltip
                            title={share?.user?.fullName || share?.user?.email || 'None'}
                            key={index}
                          >
                            <Avatar src={share?.user?.avatar}>
                              {(share?.user?.fullName ||
                                share?.user?.email ||
                                'None')[0]?.toUpperCase()}
                            </Avatar>
                          </Tooltip>
                        ))}
                    </Avatar.Group>
                  </div>
                </div>
              </>
            )}

            <Spin spinning={loadMore}>
              <div
                className={`${(isPeopleDisplayed || search) && !emails ? 'peaople-list-displayed members-list-content' : 'peaople-list-hidden'}`}
                onScroll={handleScroll}
                ref={divRef}
              >
                {isLoading ? (
                  <Spinner />
                ) : !members?.payload?.length && !isLoading ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                  <>
                    <div className="total-invited">
                      {search
                        ? t('share_space.All members')
                        : `${shares?.metadata?.total_items} ${t('share_space.Members')}`}
                    </div>
                    {reformulateData(members, shares, data, search)?.map(
                      (member: memberType & { share: any }, index) => {
                        return (
                          <MemberItem
                            member={member}
                            disabled={user?.id === member?.id || member?.id === data?.ownerId}
                            shareData={{
                              [data?.type === 'documentId' ? 'fileId' : data?.type]: data?.id,
                            }}
                            key={index}
                            isOnwer={member?.id === data?.ownerId}
                          />
                        )
                      },
                    )}
                  </>
                )}
              </div>
            </Spin>
          </div>
          {emails && !isFetching && (
            <div className="invite-button" onClick={inviteGuestMember}>
              {invitationLoading ? t('Loading...') : t('Send invite request')}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal
