import { useGetTeamsQuery } from '@src/modules/auth/services/userApi'
import { isManagerCheck } from '@src/modules/dailyQuestions/components/Sidebar/Sidebar'
import { useAppSelector } from '@src/modules/shared/store/index'
import { Avatar } from 'antd'
import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { useEffect, useState } from 'react'
import { useFollowUpContext } from '../../context/FollowUpContext'
import { Dropdown } from 'antd'
import { ReactComponent as DropDown } from '../../assets/dropDown.svg'
import searchIcon from '@src/modules/settings/assets/icons/integrations/search.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { useGetQuestionsQuery } from '@src/modules/dailyQuestions/services/managerQuestionsApi'
import Input from '@src/modules/shared/components/Input/Input'
import Loading from '@src/modules/shared/components/Loading/Loading'

export default function Teams() {
  const { t } = useTranslation(['dashboard'])
  const { t: tdq } = useTranslation(['dailyQuestion'])
  const [usersSearch, setUsersSearch] = useState<string>('')
  const [usersPage, setUsersPage] = useState<number>(1)
  const { user } = useAppSelector((state) => state.auth)
  const userId = user?.user?.id
  const {
    data: teamList,
    isLoading,
    isSuccess,
  } = useGetTeamsQuery({
    limit: 10,
    skip: usersPage,
    search: usersSearch,
    userId,
  })

  const [] = useState()
  const { team: currentTeam, setTeam, setTeams } = useFollowUpContext()
  const { data: questions } = useGetQuestionsQuery(
    { teamId: currentTeam?.id },
    { skip: !currentTeam?.id },
  )
  const isManager = !!isManagerCheck(currentTeam, userId)
  useEffect(() => {
    if (teamList?.payload?.length > 0 && !currentTeam) setTeam(teamList?.payload[0])
    if (teamList?.payload) setTeams(teamList?.payload)
  }, [isSuccess])
  const loadMoreUsers = () => {
    if (teamList?.metadata?.next) setUsersPage((prev) => prev + 1)
  }
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {teamList?.payload && (
            <div className="followup-dashboard-teams">
              {currentTeam && (
                <>
                  <div key={currentTeam?.id} className="followup-dashboard-team">
                    <div className="team-icon">
                      <Avatar
                        src={currentTeam.icon}
                        style={{ background: !currentTeam.icon && currentTeam?.color }}
                      >
                        {currentTeam?.name ? currentTeam?.name[0]?.toUpperCase() : '-'}
                      </Avatar>
                    </div>

                    <div className="team-info">
                      <ArabicElement tooltip tag="span" content={currentTeam?.name} />
                    </div>
                    <div className="team-role">{isManager}</div>
                  </div>
                  <Dropdown
                    trigger={['click']}
                    dropdownRender={() => (
                      <div className="filter_items trash_users">
                        <div className="search_input">
                          <img src={searchIcon} />

                          <Input
                            variant="label-inline"
                            type="text"
                            placeholder={t('Search for teams') + '...'}
                            value={usersSearch}
                            onChange={(e) => {
                              setUsersSearch(e.target.value)
                            }}
                          />
                        </div>

                        <span className="People">
                          {t('teams')}
                          {teamList?.payload.length > 0 ? <>({teamList?.payload.length})</> : ''}
                        </span>

                        <InfiniteScroll
                          dataLength={teamList?.payload?.length || 0}
                          next={loadMoreUsers}
                          hasMore={teamList?.metadata?.next}
                          loader={false}
                          height={'250px'}
                          className="users_list"
                        >
                          {teamList?.payload.length > 0 ? (
                            teamList?.payload?.map((team: any, index: number) => (
                              <div
                                className="user_line"
                                key={team?.id + index}
                                onClick={() => {
                                  if (team?.id != currentTeam?.id) setTeam(team)
                                }}
                              >
                                <Avatar
                                  src={team?.icon}
                                  style={{ background: !team.icon && team?.color }}
                                >
                                  {team?.name?.slice(0, 1).toUpperCase()}
                                </Avatar>
                                <span className="user_list-team-name">{team?.name}</span>
                                <p className="user_p">
                                  ({team?.roles?.length}{' '}
                                  {team?.roles?.length === 1 ? tdq('member') : tdq('members')})
                                </p>
                              </div>
                            ))
                          ) : usersSearch ? (
                            <span className="People">{tdq('Team name not found')}</span>
                          ) : (
                            <span className="People">{t('no_teams')}</span>
                          )}
                        </InfiniteScroll>
                      </div>
                    )}
                  >
                    <div className="filter_item">
                      <div className="team-questions-number">
                        ({questions?.length} {t('questions')})
                      </div>
                      <span>
                        <DropDown />
                      </span>
                    </div>
                  </Dropdown>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
