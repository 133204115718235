import { useAppDispatch } from '@src/modules/shared/store'
import { deleteTimer } from '@src/modules/tasks/data/timerData/thunks'
import { Dropdown, message } from 'antd'
import { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-icon.svg'
import { ReactComponent as CloseEditeSession } from '../assets/icons/close-edite-session.svg'
import { ReactComponent as XCloseIcon } from '../assets/icons/x-close-icon.svg'
import { ReactComponent as EditSessionIcon } from '../assets/icons/edit-session.svg'
import { ReactComponent as NoteIcon } from '../assets/icons/note-icon.svg'
import { ReactComponent as SavePlay } from '../assets/icons/save-play.svg'
import { ReactComponent as CercelClock } from '../assets/icons/cercel-clock.svg'
import { ReactComponent as EditIcon } from '../assets/icons/edit-icon.svg'
import OverflowTooltip from '@src/modules/shared/components/OverflowTooltip'
import { Input } from '@src/modules/shared/components/Input'
import { useTranslation } from 'react-i18next'
import { useEditTimerMutation } from '@src/modules/tasks/services/timerApi'
const EditSession = ({ item, refetch, refetchTask }) => {
  const { t } = useTranslation(['timer'])
  const dispatch = useAppDispatch()
  const [description, setDescription] = useState(item?.description)
  useEffect(() => {
    setDescription(item?.description)
  }, [item?.description])
  const [editTimer] = useEditTimerMutation()
  const endTime = () => {
    const date = new Date(item?.endTime)
    return date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
  }
  const startTime = () => {
    const date = new Date(item?.startTime)
    return date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
  }

  const calculateDuration = () => {
    const start = new Date(item?.startTime)
    const end = new Date(item?.endTime)
    const diff = end.getTime() - start.getTime()

    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

    return `${hours} ${t('hours')} ${minutes} ${t('minutes')}`
  }
  const duration = calculateDuration()

  const handleSave = () => {
    if (!description || !startTime || !endTime) {
      message.warning(t('please_fill_all_fields'))
      return
    }
    if (new Date(startTime()) >= new Date(endTime())) {
      message.warning(t('start_time_must_be_before_end_time'))
      return
    }
    if (description.length < 3) {
      message.warning(t('description_must_be_less_than_3_characters'))
      return
    }
    if (description === item.description) {
      message.warning(t('nothing_to_edit'))
      return
    }
    const payload = {
      description,
      startTime: new Date(item.startTime),
      endTime: new Date(item.endTime),
    }
    editTimer({ timerId: item?.id, payload }).then((res: { data }) => {
      if (res.data) {
        message.success(t('session_edited_successfully'))
        handelClose()
      }
    })
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handelOpenChane = (open) => {
    setDropdownOpen(open)
  }
  const handelClose = () => {
    setDropdownOpen(false)
  }
  const handelDelete = () => {
    dispatch(deleteTimer({ id: item?.id })).then((res) => {
      if (res.payload) {
        message.success(t('timer_deleted_successfully'))
        handelClose()
        refetch()
        refetchTask()
      }
    })
  }
  return (
    <div className="timer-dropdown-content-body-item-content-footer-dropdown">
      <Dropdown
        open={dropdownOpen}
        onOpenChange={handelOpenChane}
        dropdownRender={() => (
          <div className="timer-dropdown-content-body-item-content-footer-dropdown-content">
            <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-header">
              <span>{t('edit_session')}</span>
              <CloseEditeSession onClick={handelClose} />
            </div>
            <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body">
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-left">
                  <span className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-left-icon"></span>
                  <OverflowTooltip
                    title={item?.task?.name}
                    className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-left-task-name"
                  >
                    <span>{item?.task?.name}</span>
                  </OverflowTooltip>
                </div>

                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-right">
                  <XCloseIcon />
                </div>
              </div>
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-left">
                  <NoteIcon />
                  <Input
                    placeholder={t('add_a_note')}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-time">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-time-item">
                  <EditSessionIcon />

                  <span>{startTime()}</span>
                </div>

                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-time-item">
                  <EditSessionIcon />
                  <span>{endTime()}</span>
                </div>
              </div>
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-duration">
                  <CercelClock />
                  <span>{t('duration')}</span>
                  <span>{duration}</span>
                </div>
              </div>
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                  <span>{t('when')}</span>
                  <span className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-when-now">
                    {t('now_label')}
                  </span>
                </div>
              </div>
              <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item">
                <div className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-save">
                  <div
                    className="timer-dropdown-content-body-item-content-footer-dropdown-content-body-item-save-icon"
                    onClick={handleSave}
                  >
                    <SavePlay />
                    {t('save_changes_label')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        trigger={['click']}
        disabled={!item?.manual}
      >
        <div
          className="timer-dropdown-content-body-item-content-footer-dropdown-item"
          style={{ cursor: item?.manual ? 'pointer' : 'not-allowed' }}
        >
          <EditIcon />
          <span>{t('edit_session')}</span>
        </div>
      </Dropdown>
      <div
        className="timer-dropdown-content-body-item-content-footer-dropdown-item"
        onClick={handelDelete}
      >
        <DeleteIcon />
        <span>{t('delete')}</span>
      </div>
    </div>
  )
}

export default EditSession
