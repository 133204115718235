import ManageQuickAnswerModal from '@src/modules/dailyQuestions/components/MemberView/components/QuickAnswers/ManageQuickAnswerModal'
import AnswerDetailsModal from '@src/modules/dailyQuestions/components/Modals/AnswerDetailsModal'
import CreateNewDocument from '@src/modules/documents/components/CreateNewDocument'
import ClearTrashModal from '@src/modules/settings/components/Modals/ClearTrashModal/ClearTrashModal'
import DeleteMemberModal from '@src/modules/settings/components/Modals/DeleteMemberModal'
import DeletePermanentlyModal from '@src/modules/settings/components/Modals/DeletePermanentlyModal/DeletePermanentlyModal'
import DeleteTeamModal from '@src/modules/settings/components/Modals/DeleteTeamModal'
import DeleteWorkspaceModal from '@src/modules/settings/components/Modals/DeleteWorkspaceModal'
import InviteMemberModal from '@src/modules/settings/components/Modals/InviteMemberModal/'
import InvitedUsersModal from '@src/modules/settings/components/Modals/InvitedUsersModal'
import TeamsModal from '@src/modules/settings/components/Modals/TeamsModal/TeamsModal'
import TransferModal from '@src/modules/settings/components/Modals/TransferModal'
import ImportSelectedSpaces from '@src/modules/settings/features/ImportExport/components/ImportSelectedSpaces'
import FolderModal from '@src/modules/spaces/components/Modals/FolderModal'
import ListModal from '@src/modules/spaces/components/Modals/ListModal'
import ShareModal from '@src/modules/spaces/components/Modals/ShareModal/ShareModal'
import SpaceModal from '@src/modules/spaces/components/Modals/SpaceModal/SpaceModal'
import DeleteCommentModal from '@src/modules/tasks/components/EditTaskPopup/components/Comments/DeleteCommentModal'
import CreateTaskModal from '@src/modules/tasks/components/Modals/CreateTaskModal/CreateTaskModal'
import UpdateStatusModal from '@src/modules/tasks/components/Modals/UpdateStatusModal'
import MediaPlayer from '../components/MediaPlayer'
import ConfirmModal from '../components/Modals/ConfirmModal'
import { useAppDispatch, useAppSelector } from '../store'
import { closeModal } from '../store/slices/modals/modalsSlice'
import CustomFieldsModal from '@src/modules/customFields/feataures/CustomFields.tsx'
import UserProfile from '../components/UserProfile/UserProfile'
import ConflictManagementDrawer from '@src/modules/tasks/components/Modals/UpdateStatusModal/ConflictManagementDrawer'
import ImportModal from '@src/modules/settings/features/ImportExport/components/ImportModal'

// import EditTaskPopup from '@src/modules/tasks/components/EditTaskPopup'

const ModalIsOpen = (id: string, modals: any) => {
  const modal = modals[id]
  return modal?.open
}

function ModalsProvider() {
  const dispatch = useAppDispatch()
  const modals = useAppSelector((state) => state.modals)
  const modalState = (id: string, key: 'open' | 'data') => {
    const res = modals[id]
    return res && res[key] ? res[key] : null
  }
  const handleClose = (id: string) => dispatch(closeModal({ id }))

  return (
    <>
      {ModalIsOpen('delete-comment-modal', modals) && (
        <DeleteCommentModal
          handleClose={handleClose}
          id={`delete-comment-modal`}
          open={modalState(`delete-comment-modal`, 'open')}
          data={modalState(`delete-comment-modal`, 'data')}
        />
      )}
      {ModalIsOpen('user-profile-drawer', modals) && (
        <UserProfile
          onClose={handleClose}
          id={`user-profile-drawer`}
          open={modalState(`user-profile-drawer`, 'open')}
          data={modalState(`user-profile-drawer`, 'data')}
        />
      )}
      {ModalIsOpen('space-modal', modals) && (
        <SpaceModal
          id="space-modal"
          open={modalState('space-modal', 'open')}
          data={modalState('space-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('list-modal', modals) && (
        <ListModal
          id="list-modal"
          open={modalState('list-modal', 'open')}
          data={modalState('list-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('folder-modal', modals) && (
        <FolderModal
          id="folder-modal"
          open={modalState('folder-modal', 'open')}
          data={modalState('folder-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('confirm-modal', modals) && (
        <ConfirmModal
          id="confirm-modal"
          open={modalState('confirm-modal', 'open')}
          data={modalState('confirm-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('invite-modal', modals) && (
        <InviteMemberModal
          id="invite-modal"
          open={modalState('invite-modal', 'open')}
          data={modalState('invite-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('delete-workspace-modal', modals) && (
        <DeleteWorkspaceModal
          id="delete-workspace-modal"
          open={modalState('delete-workspace-modal', 'open')}
          data={modalState('delete-workspace-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('transfer-ownership-modal', modals) && (
        <TransferModal
          id="transfer-ownership-modal"
          open={modalState('transfer-ownership-modal', 'open')}
          data={modalState('transfer-ownership-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('delete-member-modal', modals) && (
        <DeleteMemberModal
          id="delete-member-modal"
          open={modalState('delete-member-modal', 'open')}
          data={modalState('delete-member-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('invited-users-modal', modals) && (
        <InvitedUsersModal
          id="invited-users-modal"
          open={modalState('invited-users-modal', 'open')}
          data={modalState('invited-users-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('create-task-modal', modals) && (
        <CreateTaskModal
          id="create-task-modal"
          open={modalState('create-task-modal', 'open')}
          data={modalState('create-task-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('share-modal', modals) && (
        <ShareModal
          id="share-modal"
          open={modalState('share-modal', 'open')}
          data={modalState('share-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('update-status-modal', modals) && (
        <UpdateStatusModal
          id="update-status-modal"
          open={modalState('update-status-modal', 'open')}
          data={modalState('update-status-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('media-player', modals) && (
        <MediaPlayer
          id="media-player"
          open={modalState('media-player', 'open')}
          data={modalState('media-player', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('teams-modal', modals) && (
        <TeamsModal
          id="teams-modal"
          open={modalState('teams-modal', 'open')}
          data={modalState('teams-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('delete-team-modal', modals) && (
        <DeleteTeamModal
          id="delete-team-modal"
          open={modalState('delete-team-modal', 'open')}
          data={modalState('delete-team-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('user-answer-modal', modals) && (
        <AnswerDetailsModal
          id="user-answer-modal"
          open={modalState('user-answer-modal', 'open')}
          data={modalState('user-answer-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('quick-answer-modal', modals) && (
        <ManageQuickAnswerModal
          id="quick-answer-modal"
          open={modalState('quick-answer-modal', 'open')}
          data={modalState('quick-answer-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('delete-permanently-modal', modals) && (
        <DeletePermanentlyModal
          id="delete-permanently-modal"
          open={modalState('delete-permanently-modal', 'open')}
          data={modalState('delete-permanently-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('clear-tash-modal', modals) && (
        <ClearTrashModal
          id="clear-tash-modal"
          open={modalState('clear-tash-modal', 'open')}
          data={modalState('clear-tash-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('create-new-document-modal', modals) && (
        <CreateNewDocument
          id="create-new-document-modal"
          open={modalState('create-new-document-modal', 'open')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('import-selected-spaces-modal', modals) && (
        <ImportSelectedSpaces
          id="import-selected-spaces-modal"
          open={modalState('import-selected-spaces-modal', 'open')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('conflict-management-drawer', modals) && (
        <ConflictManagementDrawer
          id="conflict-management-drawer"
          open={modalState('conflict-management-drawer', 'open')}
          data={modalState('conflict-management-drawer', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('custom-fields-modal', modals) && (
        <CustomFieldsModal
          id="custom-fields-modal"
          data={modalState('custom-fields-modal', 'data')}
          handleClose={handleClose}
        />
      )}
      {ModalIsOpen('import-task-modal', modals) && (
        <ImportModal
          id="import-task-modal"
          open={modalState('import-task-modal', 'open')}
          handleClose={handleClose}
        />
      )}
      {/* <EditTaskPopup
        id="edit-task-modal"
        open={modalState('edit-task-modal', 'open')}
        data={modalState('edit-task-modal', 'data')}
        handleClose={handleClose}
      /> */}
    </>
  )
}

export default ModalsProvider
