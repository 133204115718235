import { AnimatePresence, motion } from 'framer-motion'
import { Editor, ICurrentQuestion } from '../../memberView.interface'

import { Attachment } from './attachement.interface'
import BlockNoteRichText from '@src/modules/shared/components/BlockNoteRichText/BlockNoteRichText'
import DeleteBlocButton from '../DeleteBlocButton/DeleteBlocButton'
import QuestionBlocFooter from './QuestionBlocFooter'
import RTLStyleDecorator from '@src/modules/shared/components/RTLStyleDecorator/RTLStyleDecorator'
import React from 'react'
import { removeEditor } from '../../utils/removeEditor'
import { useAppSelector } from '@src/modules/shared/store/index'
import { useDebouncedUpdateEditorHtml } from '../../utils/memberViewFunctions'
import { useParams } from 'react-router-dom'
import { canAnswerQuestions } from '../../utils/canAnswerQuestions'

interface EditorComponentProps {
  editors: Editor[]
  isView: boolean
  setEditors: React.Dispatch<React.SetStateAction<{ [key: string]: Editor[] }>>
  setShowCreation: React.Dispatch<React.SetStateAction<boolean>>
  currentQuestion: ICurrentQuestion
  currentResponse: Date
  stats: String
  setFilesForEditor: (
    questionId: string,
    editorId: number,
    files: { data: Attachment; editorId: number; id?: string }[],
  ) => void
}

const QestionBlocsList: React.FC<EditorComponentProps> = ({
  editors,
  isView,
  setEditors,
  setShowCreation,
  setFilesForEditor,
  currentQuestion,
  currentResponse,
}) => {
  const userId = useAppSelector((state) => state.auth?.user?.user?.id)
  const { myTeams } = useAppSelector((state) => state.teams)
  const { teamId: selectedTeamId } = useParams()

  const hasAccess = canAnswerQuestions(myTeams, selectedTeamId, userId)

  const handleRemoveEditor = React.useCallback(
    (id: number) => {
      removeEditor(id, setEditors)
    },
    [setEditors],
  )
  const debouncedUpdateEditorHtml = useDebouncedUpdateEditorHtml()

  const updateEditorContent = (key: string, editorId: number, newContent: string): void => {
    setEditors((prevEditors) => {
      const updatedEditors = { ...prevEditors }
      if (updatedEditors[key]) {
        const updatedEditorIndex = updatedEditors[key].findIndex((editor) => editor.id === editorId)
        if (updatedEditorIndex !== -1) {
          updatedEditors[key][updatedEditorIndex] = {
            ...updatedEditors[key][updatedEditorIndex],
            content: `[{"children":[{"text":"${newContent}"}],"type":"p"}]`,
            id: updatedEditors[key][updatedEditorIndex]?.id + 99,
          }

          return { ...prevEditors, [key]: updatedEditors[key] }
        }
      }
      return prevEditors
    })
  }

  const deleteAttachementEditorContent = (key: string, editorId: number, id: string): void => {
    setEditors((prevEditors) => {
      const updatedEditors = { ...prevEditors }

      if (updatedEditors[key]) {
        const updatedEditorIndex = updatedEditors[key].findIndex((editor) => editor.id === editorId)
        const newFiles = updatedEditors[key][updatedEditorIndex]?.files?.filter(
          (prev) => prev?.data?.id !== id,
        )
        if (updatedEditorIndex !== -1) {
          updatedEditors[key][updatedEditorIndex] = {
            ...updatedEditors[key][updatedEditorIndex],
            files: newFiles,
          }
          return { ...prevEditors, [key]: updatedEditors[key] }
        }
      }
      return prevEditors
    })
  }

  return (
    <AnimatePresence>
      {editors?.map((editor) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}
          key={editor?.id}
        >
          <motion.div
            key={editor?.id}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.1 }}
            style={{ width: '100%' }}
          >
            {hasAccess && (
              <div className="answer-container">
                {editor?.editable ? (
                  <div className="text-editor-container">
                    <BlockNoteRichText
                      setValue={(content: string) => {
                        debouncedUpdateEditorHtml(
                          editor.id,
                          content,
                          setEditors,
                          currentQuestion?.id,
                          currentResponse,
                          userId,
                        )
                      }}
                      value={editor.content}
                      key={editor.id}
                      insert
                      onlyView={isView}
                      onDrop={() => setShowCreation(false)}
                      onPaste={() => setShowCreation(false)}
                      onUplaod={() => setShowCreation(true)}
                    />
                    <QuestionBlocFooter
                      isView={isView}
                      setValue={(content: string) => {
                        debouncedUpdateEditorHtml(
                          editor.id,
                          content,
                          setEditors,
                          currentQuestion?.id,
                          currentResponse,
                          userId,
                        )
                      }}
                      editor={editor}
                      setFilesForEditor={setFilesForEditor}
                      questionId={currentQuestion?.id}
                      updateEditorContent={updateEditorContent}
                      deleteAttachementEditorContent={deleteAttachementEditorContent}
                      setEditors={setEditors}
                    />
                  </div>
                ) : (
                  <div
                    className="read-only-content"
                    dangerouslySetInnerHTML={{ __html: editor.content }}
                  />
                )}
              </div>
            )}
          </motion.div>
          <RTLStyleDecorator>
            <div className="daily-bloc_menu">
              <DeleteBlocButton isView={isView} editor={editor} removeEditor={handleRemoveEditor} />
            </div>
          </RTLStyleDecorator>
        </div>
      ))}
    </AnimatePresence>
  )
}

export default React.memo(QestionBlocsList)
