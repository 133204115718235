import { LexicalComposer } from '@lexical/react/LexicalComposer'
import Editor from '../editor'
import PlaygroundEditorTheme from '../../shared/theme/PlaygroundEditorTheme'
import { SharedHistoryContext } from '../../context/SharedHistoryContext'
import { TableContext } from '../../shared/plugins/TablePlugin'
import PlaygroundNodes from '../../shared/nodes/PlaygroundNodes'
import { SharedAutocompleteContext } from '../../context/SharedAutocompleteContext'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin'
import { Provider } from '@lexical/yjs'
import { createWebsocketProvider } from '../../provider/provider'
import * as Y from 'yjs'
import { useAppSelector } from '@src/modules/shared/store'

export const EditorContainer = ({
  documentId,
  content,
  isTask,
  markdownDescription,
  isDocument,
  tableOfContents = false,
  taskId,
  setContent,
  disable,
}: {
  documentId?: string
  content?: string
  isTask?: boolean
  markdownDescription?: string
  taskId?: string
  tableOfContents?: boolean
  isDocument?: boolean
  open?: boolean
  setContent?: any
  disable?: boolean
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [yjsProvider, setYjsProvider] = useState<null | Provider>(null)
  const [activeUsers, setActiveUsers] = useState<
    {
      color: string
      name: string
      userId: string
    }[]
  >([])

  const handleAwarenessUpdate = useCallback(() => {
    const awareness = yjsProvider!.awareness!
    setActiveUsers(
      Array.from(awareness.getStates().entries()).map(([userId, { color, name }]) => ({
        color,
        name,
        userId: userId.toString(),
      })),
    )
  }, [yjsProvider])

  useEffect(() => {
    if (yjsProvider == null) {
      return
    }

    yjsProvider.awareness.on('update', handleAwarenessUpdate)

    return () => yjsProvider.awareness.off('update', handleAwarenessUpdate)
  }, [yjsProvider, handleAwarenessUpdate])

  const initialConfig = {
    namespace: 'editor',
    nodes: [...PlaygroundNodes],
    ...(content && { initialEditorState: content }),
    editable: !disable,
    onError: (error: Error) => {
      console.log(error, 'error')
    },
    theme: PlaygroundEditorTheme,
  }

  const { user } = useAppSelector((state) => state.auth.user)
  const onChange = useCallback((editorState) => {
    setContent?.(editorState)
  }, [])
  const providerFactory = useCallback(
    (id: string, yjsDocMap: Map<string, Y.Doc>) => {
      const provider = createWebsocketProvider(id, yjsDocMap, documentId || taskId)
      setTimeout(() => setYjsProvider(provider), 0)
      return provider
    },
    [documentId, taskId],
  )

  return (
    <div ref={containerRef}>
      {!isTask && (
        <p>
          <b>Active users:</b>{' '}
          {activeUsers.map(({ name, color, userId }, idx) => (
            <Fragment key={userId}>
              <span style={{ color }}>{name}</span>
              {idx === activeUsers.length - 1 ? '' : ', '}
            </Fragment>
          ))}
        </p>
      )}
      <LexicalComposer initialConfig={initialConfig}>
        {/* <Editor /> */}
        <SharedHistoryContext>
          <TableContext>
            <SharedAutocompleteContext>
              {/* {documentId && ( */}
              {(documentId || taskId) && (
                <CollaborationPlugin
                  id={documentId || taskId}
                  providerFactory={providerFactory}
                  shouldBootstrap={true}
                  username={user?.fullName}
                  initialEditorState={content}
                  cursorColor={'var(--primary)'}
                  cursorsContainerRef={containerRef}
                />
              )}
              <div className="editor-shell">
                <Editor
                  taskId={taskId}
                  content={content}
                  tableOfContents={tableOfContents}
                  documentId={documentId}
                  isTask={isTask}
                  onChange={onChange}
                  isDocument={isDocument}
                  markdownDescription={markdownDescription}
                />
              </div>{' '}
              {/* {true ? <DocsPlugin /> : null}
            {true ? <PasteLogPlugin /> : null}
            {true ? <TestRecorderPlugin /> : null} */}
            </SharedAutocompleteContext>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    </div>
  )
}
