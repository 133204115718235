const taskImportFields = [
  {
    label: 'ID',
    key: 'id',
    alternateMatches: ['Task ID', 'unique id', 'identifier'],
    fieldType: {
      type: 'input',
    },
    example: 'taskid',
    validations: [
      {
        rule: 'required',
        errorMessage: 'ID is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['Task Name', 'title'],
    fieldType: {
      type: 'input',
    },
    example: 'taskname',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Name is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Description',
    key: 'description',
    alternateMatches: ['task description', 'details', 'Text Content', 'Task Content'],
    fieldType: {
      type: 'input',
    },
    example: 'Create a new button component for the dashboard',
  },
  {
    label: 'Status',
    key: 'statusId',
    alternateMatches: ['task status', 'current state', 'Status Name'],
    fieldType: {
      type: 'input',
    },
    example: 'open',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Status is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Due Date',
    key: 'dueDate',
    alternateMatches: ['end date', 'deadline'],
    fieldType: {
      type: 'input',
    },
    example: '2023-09-15',
  },
  {
    label: 'Users',
    key: 'users',
    alternateMatches: ['Assignees', 'Assignee'],
    fieldType: {
      type: 'input',
    },
    example: 'user1,user2,user3',
  },
  {
    label: 'File ID',
    key: 'fileId',
    alternateMatches: ['List Name', 'List'],
    fieldType: {
      type: 'input',
    },
    example: 'fileId',
    validations: [
      {
        rule: 'required',
        errorMessage: 'File ID is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Space',
    key: 'space',
    alternateMatches: ['Space Name', 'spaceId'],
    fieldType: {
      type: 'input',
    },
    example: 'space',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Space is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Parent ID',
    key: 'parentId',
    alternateMatches: ['Parent Task ID'],
    fieldType: {
      type: 'input',
    },
    example: 'parent_task_id',
  },
  {
    label: 'Priority',
    key: 'priority',
    alternateMatches: ['task priority', 'importance'],
    fieldType: {
      type: 'input',
    },
    example: '2',
  },
  {
    label: 'Tags',
    key: 'tagsId',
    alternateMatches: ['tags'],
    fieldType: {
      type: 'input',
    },
    example: 'tag1,tag2,tag3',
  },
  {
    label: 'Owner ID',
    key: 'ownerId',
    alternateMatches: ['owner'],
    fieldType: {
      type: 'input',
    },
    example: 'owner_user_id',
  },
  {
    label: 'Estimation',
    key: 'estimation',
    alternateMatches: ['estimated time', 'Time Estimated'],
    fieldType: {
      type: 'input',
    },
    example: '20',
  },
  {
    label: 'Created At',
    key: 'createdAt',
    alternateMatches: ['Date Created'],
    fieldType: {
      type: 'input',
    },
    example: '2023-09-10T10:00:00Z',
  },
  {
    label: 'Attachments ID',
    key: 'attachementsId',
    alternateMatches: ['Attachments'],
    fieldType: {
      type: 'input',
    },
    example: 'attachment1,attachment2',
  },
  {
    label: 'Folder',
    key: 'folder',
    alternateMatches: ['Folder Name'],
    fieldType: {
      type: 'input',
    },
    example: 'Project A Folder',
  },
] as const

export default taskImportFields
