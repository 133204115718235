import { createSlice } from '@reduxjs/toolkit'

interface ActiveUser {
  id: string
  isActive: boolean
  lastActive: Date
}

interface ActiveUsersState {
  usersActivity: ActiveUser[]
}

const initialState: ActiveUsersState = {
  usersActivity: [],
}

export const activeUsersSlice = createSlice({
  name: 'usersActivity',
  initialState,
  reducers: {
    setUsersActivity: (state, action) => {
      if (state.usersActivity.find((user) => user?.id === action?.payload?.id))
        state.usersActivity = state.usersActivity.map((user) => {
          if (user?.id === action?.payload?.id) return action?.payload
          return user
        })
      else state.usersActivity = [...state.usersActivity, action?.payload]
    },
  },
})

export const { setUsersActivity } = activeUsersSlice.actions

export default activeUsersSlice.reducer
