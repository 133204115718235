import { ReactComponent as TimerClock } from './assets/icons/timer-clock.svg'
import { ReactComponent as TimerPlay } from './assets/icons/timer-play.svg'
import { ReactComponent as TimerPause } from './assets/icons/timer-pause.svg'
import React, { useState, useEffect } from 'react'
import { Dropdown, message, Skeleton } from 'antd'
import { useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { useSearchParams } from 'react-router-dom'
import { resetTimer, startTimerWithPayload } from '../../data/timerData/slices'
import { useTimer } from './hooks/useTimer'
import { formatTimerTime } from './utils/formateTimerTime'

import TimerDropdownContent from './TimerDropdownContent'
import { useTranslation } from 'react-i18next'
import { useCloseTimerMutation, useStartTimerMutation } from '../../services/timerApi'
import { TimerType } from '../../data/timerData/types'
import { TaskType } from '../../data/tasksSlice/tasksTypes'
import { useGetTaskQuery } from '../../services/tasksApi'

interface ITimerProps {
  task?: TaskType
}

const Timer: React.FC<ITimerProps> = ({ task }) => {
  const { currentActiveTimer, timerStartAt, currentActiveTimerTaskId } = useAppSelector(
    (state) => state.timer,
  )

  const [searchparams, _] = useSearchParams()
  const taskId = task?.id || searchparams.get('taskId')
  const { t } = useTranslation(['timer'])
  const [isRunning, setIsRunning] = useState(false)
  const [actionCount, setActionCount] = useState(0)
  const [lastActionTime, setLastActionTime] = useState(Date.now())
  const [startTimer, { isLoading }] = useStartTimerMutation()
  const [closeTimer, { isLoading: isLoadingClose }] = useCloseTimerMutation()
  const { data: taskDetails } = useGetTaskQuery({ id: taskId })

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (timerStartAt && currentActiveTimerTaskId === taskId) {
      setIsRunning(true)
    } else {
      setIsRunning(false)
    }
  }, [timerStartAt, currentActiveTimerTaskId, taskId])
  const checkRapidActions = () => {
    const now = Date.now()
    if (now - lastActionTime < 5000) {
      setActionCount((prevCount) => prevCount + 1)
      if (actionCount >= 5) {
        message.warning(t('please_avoid_rapidly_starting_and_stopping_the_timer'))
        return true
      }
    } else {
      setActionCount(0)
    }
    setLastActionTime(now)
    return false
  }
  const handelOpen = async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (checkRapidActions()) return
    const result = (await startTimer({ taskId })) as { data: TimerType }
    if (result?.data) {
      dispatch(startTimerWithPayload(result?.data))
      setIsRunning(true)
    }
    return result
  }

  const handelClose = async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (checkRapidActions()) return
    if (currentActiveTimer?.id) {
      await closeTimer({ timerId: currentActiveTimer?.id })
      dispatch(resetTimer())
      setIsRunning(false)
    }
  }

  const { user } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (user.openTimer && user.openTimer.id === taskId) {
      dispatch(startTimerWithPayload(user.openTimer))
    }
  }, [user.openTimer, currentActiveTimerTaskId, taskId])

  const { elapsedSeconds } = useTimer()

  return (
    <>
      {false ? (
        <Skeleton.Input active />
      ) : (
        <Dropdown
          dropdownRender={() => (
            <TimerDropdownContent
              elapsedSeconds={elapsedSeconds}
              isRunning={isRunning}
              handelOpen={handelOpen}
              handelClose={handelClose}
              task={task}
            />
          )}
          trigger={['click']}
        >
          <div className={`timer-floating-button-container`}>
            <TimerClock className="timer-clock-icon" />
            <div
              className={`timer-icon-wrapper ${isRunning ? 'running' : ''} ${isLoading || isLoadingClose ? 'disabled' : ''}`}
            >
              {isRunning ? (
                <TimerPause className="timer-pause-icon" onClick={handelClose} />
              ) : (
                <TimerPlay className="timer-play-icon" onClick={handelOpen} />
              )}
            </div>
            {formatTimerTime(isRunning ? elapsedSeconds : taskDetails?.totalTime || 0)}
          </div>
        </Dropdown>
      )}
    </>
  )
}

export const handleSave = async ({
  hours,
  minutes,
  taskId,
  addManualTimer,
  description,
  t,
}: {
  hours: string
  minutes: string
  taskId: string
  addManualTimer
  description?: string
  t
}) => {
  if (!hours && !minutes) {
    message.warning(t('please_enter_hours_or_minutes'))
    return
  }
  if (+hours > 99) {
    message.warning(t('hours_must_be_less_than_99'))
    return
  }
  if (+minutes > 99) {
    message.warning(t('minutes_must_be_less_than_99'))
    return
  }
  if (+hours < 0) {
    message.warning(t('hours_must_be_greater_than_0'))
    return
  }
  if (+minutes < 0) {
    message.warning(t('minutes_must_be_greater_than_0'))
    return
  }
  if (hours === '' && minutes === '0') {
    message.warning(t('please_enter_hours_when_minutes_are_0'))
    return
  }
  if (hours === '0' && minutes === '0') {
    message.warning(t('hours_and_minutes_cannot_be_0'))
    return
  }
  const seconds = +hours * 3600 + +minutes * 60
  const payload = {
    startTime: new Date().toISOString(),
    endTime: new Date(new Date().getTime() + seconds * 1000).toISOString(),
    ...(description ? { description } : {}),
    taskId,
  }
  const result = await addManualTimer(payload)
  if (!result?.data) {
    message.error(t('failed_to_add_timer'))
  }
  return result
}
export default Timer
