import { ReactComponent as ManagerIcon } from '../../assets/icons/sidebar/manager-role.svg'
import { ReactComponent as AddQuestionIcon } from '../../assets/icons/manager/add-question.svg'
import { useEffect, useState } from 'react'
import { insert } from '@src/modules/tasks/components/Board/Board'
import QuestionsList from '../QuestionsList/QuestionsList'
import { useGetQuestionsQuery } from '../../services/managerQuestionsApi'
import Spinner from '@src/modules/spaces/components/Spinner'
import { useTranslation } from 'react-i18next'

const CreateQuestionButton = ({
  size = 'medium',
  onClick,
  className = '',
}: {
  size: 'medium' | 'large'
  onClick: () => void
  className?: string
}) => {
  const mediumSize = { fontSize: '17px', fontWeight: '400', width: '160px' }
  const { t, ...rest } = useTranslation(['dailyQuestion'])
  const language = rest[1]?.language
  const isArabic = language === 'ar'
  const buttonStyle = {
    ...(size === 'medium' ? mediumSize : {}),
    ...(isArabic ? { left: '20px' } : { right: '20px' }),
  }
  return (
    <div className={`create-question-button ${className}`} onClick={onClick} style={buttonStyle}>
      {size !== 'medium' && <AddQuestionIcon />} {t('new_question')}
    </div>
  )
}

const ManagerView = ({ team }: any) => {
  const { t } = useTranslation(['dailyQuestion'])
  const { data: managerQuestions, isLoading } = useGetQuestionsQuery({ teamId: team?.id })
  const [questions, setQuestions] = useState<any>([])

  useEffect(() => {
    if (managerQuestions) setQuestions(managerQuestions)
  }, [managerQuestions])

  const addQuestionForm = (questionId?: number, data: any = {}) => {
    setQuestions((questions) => {
      const targetQuestionIndex = questions?.findIndex((question) => question?.id === questionId)
      questions = questions?.filter((question) => question?.type !== 'question-form')
      return insert(
        questions,
        targetQuestionIndex === -1 ? questions?.length + 1 : targetQuestionIndex + 1,
        {
          ...(questions[targetQuestionIndex] || {}),
          type: 'question-form',
          ...data,
        },
      )
    })
  }

  const removeQuestionForm = () => {
    setQuestions((questions) => questions?.filter((question) => question?.type !== 'question-form'))
  }

  if (isLoading)
    return (
      <div>
        <Spinner />
      </div>
    )

  return (
    <div className="manager-view">
      <div className="manager-view-title">
        <ManagerIcon /> {t('manager_view')}
      </div>
      <div className="manager-view-body">
        {questions?.length === 0 ? (
          <div className="create-question">
            <p>{t('no_question_add_one')}</p>
            <CreateQuestionButton size={'large'} onClick={() => addQuestionForm()} />
          </div>
        ) : (
          <QuestionsList
            addQuestionForm={addQuestionForm}
            removeQuestionForm={removeQuestionForm}
            questions={questions}
            team={team}
          />
        )}
      </div>
    </div>
  )
}

export default ManagerView
