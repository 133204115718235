import { api } from '@src/modules/shared/services/api'
import deepClone from 'deep-clone'
import { removeDuplicatesById } from '../utils/removeDup'

export const MembersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMembers: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/users`,
          params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        if (newItems?.payload?.length > 0) {
          currentCache?.payload?.push(...newItems?.payload)
        }

        currentCache.payload = removeDuplicatesById(currentCache?.payload)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: (result = []) => {
        return result?.payload
          ? [
              ...result?.payload?.map(({ id }: any) => ({ type: 'MEMBERS', id }) as const),
              { type: 'MEMBERS', id: 'USER' },
            ]
          : []
      },
    }),
    getMembersTeams: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/users`,
          params,
        }
      },
      transformResponse: (response: { payload: any }): any => {
        return response
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, otherArgs) => {
        const withFilter = otherArgs?.arg?.search
        const shouldCache = !withFilter
        if (newItems?.payload?.length > 0) {
          currentCache.payload.push(...newItems.payload)
        }
        currentCache.metadata = newItems.metadata

        currentCache.payload =
          shouldCache || otherArgs.arg.skip > 1
            ? removeDuplicatesById(currentCache.payload)
            : newItems.payload
      },
      providesTags: (result = []) => {
        return result.payload
          ? [
              ...result.payload.map(({ id }: any) => ({ type: 'MEMBERS', id }) as const),
              { type: 'MEMBERS', id: 'USER' },
            ]
          : []
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
    }),

    addMember: build.mutation<any, any>({
      query: ({ body }) => ({
        url: `api/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'MEMBERS', id: 'USER' }] as any,
    }),

    updateMember: build.mutation<any, Partial<any>>({
      query(data) {
        const { id, ...body } = data

        return {
          url: `api/users/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    deleteMember: build.mutation<any, Partial<{ id: string }>>({
      query(data) {
        const { id } = data
        return {
          url: `api/users/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    deleteMutipleMember: build.mutation<any, Partial<{ userIds: string[] }>>({
      query(data) {
        return {
          url: `api/users`,
          method: 'DELETE',
          body: data,
        }
      },
      async onQueryStarted({ userIds }, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(
          MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
            const oldDraft = deepClone(draft)
            const newDraftPayload = []
            oldDraft?.payload?.map((el) => {
              if (!userIds.includes(el?.id)) {
                newDraftPayload.push(el)
              }
            })
            draft.payload = newDraftPayload
            oldDraft.metadata.total_items = oldDraft?.metadata?.total_items - userIds?.length
            draft.metadata = oldDraft.metadata
            return draft
          }),
        )
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    getShares: build.query<any, any>({
      query: (params) => {
        return {
          url: `api/share/allShared`,
          params,
        }
      },
      providesTags: (result = []) => [
        ...result?.payload?.map(({ id }: any) => ({ type: 'MEMBERS', id }) as const),
        { type: 'MEMBERS', id: 'USER' },
      ],
    }),

    share: build.mutation<any, any>({
      query(data) {
        return {
          url: `api/share`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    deleteShare: build.mutation<any, any>({
      query(data) {
        const { id } = data
        return {
          url: `api/share/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    transferOwnerShip: build.mutation<any, any>({
      query(data) {
        const { id, newOwnerId } = data
        return {
          url: `api/share/${id}/transfer-ownership/${newOwnerId}`,
          method: 'POST',
        }
      },
    }),

    updateShare: build.mutation<any, any>({
      query(data) {
        const { id, ...body } = data
        return {
          url: `api/share/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    deleteInvitation: build.mutation<any, Partial<{ emails: string[] }>>({
      query(data) {
        const { emails } = data
        return {
          url: `api/workspace/invitation`,
          method: 'DELETE',
          body: { emails },
        }
      },
      async onQueryStarted({ emails }, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(
          MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
            const oldDraft = deepClone(draft)
            const mail = emails[0]
            const newDraftPayload = []
            oldDraft?.payload?.map((el) => {
              if (el?.email !== mail) {
                newDraftPayload.push(el)
              }
            })
            draft.payload = newDraftPayload
            return draft
          }),
        )
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),

    deleteMutipleInvitation: build.mutation<any, Partial<{ emails: string[] }>>({
      query(data) {
        const { emails } = data
        return {
          url: `api/workspace/invitation`,
          method: 'DELETE',
          body: { emails },
        }
      },
      async onQueryStarted({ emails }, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(
          MembersApi.util.updateQueryData('getMembers', undefined, (draft) => {
            const oldDraft = deepClone(draft)
            const newDraftPayload = []
            oldDraft?.payload?.map((el) => {
              if (!emails.includes(el?.email)) {
                newDraftPayload.push(el)
              }
            })
            draft.payload = newDraftPayload
            return draft
          }),
        )
      },
      invalidatesTags: (member) => [{ type: 'MEMBERS', id: member?.id }] as any,
    }),
  }),
})

export const {
  useGetMembersQuery,
  useGetMembersTeamsQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useDeleteMutipleMemberMutation,
  useDeleteMutipleInvitationMutation,
  useShareMutation,
  useTransferOwnerShipMutation,
  useGetSharesQuery,
  useUpdateShareMutation,
  useDeleteShareMutation,
  useDeleteInvitationMutation,
} = MembersApi
