import { ReactComponent as BackIcon } from './../../assets/icons/sidebar/Back.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import { Can } from '@src/modules/shared/context/permissions/Can'
import { Avatar, Divider } from 'antd'
import { getCurrentWorkspace } from '@src/modules/shared/components/WorkspacePicker/WorkspacePicker'
import { useAppSelector } from '@src/modules/shared/store'
import ArabicElement from '@src/modules/shared/components/ArabicSupport/ArabicSupport'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.auth)
  const { t } = useTranslation(['sidebar'])

  const { workspace: currentWorkSpace } =
    user?.currentWorkspace || getCurrentWorkspace(user?.lastWorkspaceUsed, user?.workspaces) || {}

  const workspaceItems = [
    { label: t('settings.general_settings'), path: '/settings/workspace' },
    {
      label: t('settings.members'),
      path: '/settings/members',
      withPermission: true,
      verb: 'read', //test
      resource: 'users',
    },
    { label: t('settings.import_export'), path: '/settings/import-export' },
    { label: t('settings.integrations'), path: '/settings/integrations' },
    { label: t('settings.teams'), path: '/settings/teams' },
    { label: t('settings.trash'), path: '/settings/trash' },
  ]

  const prsonelItems = [
    { label: t('settings.profile'), path: '/settings/profile' },
    { label: t('settings.workspaces'), path: '/settings/manage-workspaces' },
  ]

  return (
    <div className="spaces-sidebar settings-sidebar">
      <div className="spaces-sidebar-links">
        <div className="back-btn" onClick={() => navigate('/dashboard')}>
          <BackIcon /> {t('settings.title')}
        </div>
        <span className="sidebar-group-title">
          <Avatar
            size={'small'}
            style={{ background: currentWorkSpace?.icon ? 'transparent' : currentWorkSpace?.color }}
            src={currentWorkSpace?.icon}
          >
            {currentWorkSpace?.name && currentWorkSpace?.name[0]?.toUpperCase()}
          </Avatar>
          <ArabicElement tooltip tag="p" content={currentWorkSpace?.name} />
        </span>

        {workspaceItems.map((item, i) => {
          const NavItem = (
            <NavLink
              key={i}
              to={item.path}
              className={({ isActive }) =>
                `settings-sidebar-links-item ${isActive ? 'active-sibebar-item' : ''}`
              }
            >
              <div className="item-label">{item.label}</div>
            </NavLink>
          )
          return item.withPermission ? (
            <Can I={item.verb} a={item.resource} key={i}>
              {NavItem}
            </Can>
          ) : (
            NavItem
          )
        })}
        <Divider />

        <span className="sidebar-group-title">
          <Avatar size={'small'} src={user?.user?.avatar}>
            {user?.user?.fullName && user?.user?.fullName[0]?.toUpperCase()}
          </Avatar>
          <ArabicElement tooltip tag="p" content={user?.user?.fullName} />
        </span>

        {prsonelItems.map((item: any, i: number) => {
          const NavItem = (
            <NavLink
              key={i}
              to={item.path}
              className={({ isActive }) =>
                `settings-sidebar-links-item ${isActive ? 'active-sibebar-item' : ''}`
              }
            >
              <div className="item-label">{item.label}</div>
            </NavLink>
          )
          return item.withPermission ? (
            <Can I={item.verb} a={item.resource} key={i}>
              {NavItem}
            </Can>
          ) : (
            NavItem
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
