import { ReactComponent as DateSVG } from '../../../assets/icons/fields/date.svg'
import { ReactComponent as CreatedBySVG } from '../../../assets/icons/fields/created-by.svg'
import { ReactComponent as AssigneeSVG } from '../../../assets/icons/fields/assignee.svg'
import { ReactComponent as NameSVG } from '../../../assets/icons/fields/name.svg'
import { ReactComponent as PrioritySVG } from '../../../assets/icons/fields/priority.svg'
import { ReactComponent as TimerSVG } from '../../../assets/icons/fields/timer.svg'
import { column } from '@src/modules/tasks/data/tasksSlice/tasksTypes'

export const defaultFields: column[] = [
  {
    id: 1,
    fieldType: 'text',
    fieldName: 'Name',
    value: 'name',
    width: 800,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: true,
    order: 1,
    groupBy: false,
    hidden: false,
    maxWidth: '40%',
    minWidth: 400,
  },
  {
    id: 2,
    fieldType: 'dueDate',
    fieldName: 'Due Date',
    value: 'dueDate',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 2,
    groupBy: false,
    hidden: false,
    maxWidth: 300,
  },
  {
    id: 3,
    fieldType: 'priority',
    fieldName: 'Priority',
    value: 'priority',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 3,
    groupBy: false,
    hidden: false,
    maxWidth: 300,
  },
  {
    id: 4,
    fieldType: 'text',
    fieldName: 'Task ID',
    value: 'id',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 4,
    groupBy: false,
    hidden: true,
    maxWidth: 300,
  },
  {
    id: 5,
    fieldType: 'staticDate',
    fieldName: 'Created At',
    value: 'createdAt',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 5,
    groupBy: false,
    hidden: true,
    maxWidth: 300,
  },
  {
    id: 6,
    fieldType: 'staticDate',
    fieldName: 'Updated At',
    value: 'updatedAt',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 6,
    groupBy: false,
    hidden: true,
    maxWidth: 300,
  },
  {
    id: 7,
    fieldType: 'users',
    fieldName: 'Assignee',
    value: 'users',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 7,
    groupBy: false,
    hidden: false,
    maxWidth: 300,
  },
  {
    id: 8,
    fieldType: 'owner',
    fieldName: 'Created by',
    value: 'owner',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 8,
    groupBy: false,
    hidden: true,
    maxWidth: 300,
  },
  {
    id: 9,
    fieldType: 'staticDate',
    fieldName: 'Start Date',
    value: 'startDate',
    width: 113,
    sortBy: 0,
    sortOrder: 'ASC',
    disable: false,
    order: 9,
    groupBy: false,
    hidden: true,
    maxWidth: 300,
  },
]

export const defaultFieldsIcons = {
  1: <NameSVG />,
  2: <DateSVG />,
  3: <PrioritySVG />,
  4: <NameSVG />,
  5: <CreatedBySVG />,
  6: <CreatedBySVG />,
  7: <AssigneeSVG />,
  8: <CreatedBySVG />,
  9: <DateSVG />,
  10: <TimerSVG />,
}
