import useDebounce from '@src/modules/shared/hooks/useDebounce'
import { useEffect, useState } from 'react'
import { EditorContainer } from '@src/modules/editor/features/editorContainer/editorContainer'
import { editDocument } from '../../data/documentSlice/documentThunk'
import { useAppDispatch } from '@src/modules/shared/store'

type props = {
  documentContent: string
  documentId: string
}

const DocumentContent = ({ documentContent, documentId }: props) => {
  const [content, setContent] = useState(documentContent)
  useEffect(() => {
    setContent(documentContent)
  }, [documentContent])
  const dispatch = useAppDispatch()
  useDebounce(
    async () => {
      await dispatch(editDocument({ documentId, content: JSON.stringify(content) }))
    },
    500,
    [content],
  )
  return (
    <div className="document_content">
      <EditorContainer
        documentId={documentId}
        isDocument={true}
        setContent={setContent}
        content={documentContent}
      />
    </div>
  )
}

export default DocumentContent
