import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin'
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { useLexicalEditable } from '@lexical/react/useLexicalEditable'
import { useEffect, useState } from 'react'
// import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin'

// import ActionsPlugin from '../../plugins/ActionsPlugin'
import AutocompletePlugin from '../shared/plugins/AutocompletePlugin'
import AutoEmbedPlugin from '../shared/plugins/AutoEmbedPlugin'
import AutoLinkPlugin from '../shared/plugins/AutoLinkPlugin'
import CodeActionMenuPlugin from '../shared/plugins/CodeActionMenuPlugin'
import CodeHighlightPlugin from '../shared/plugins/CodeHighlightPlugin'
import CollapsiblePlugin from '../shared/plugins/CollapsiblePlugin'
// import CommentPlugin from '../../plugins/CommentPlugin'
import ComponentPickerPlugin from '../shared/plugins/ComponentPickerPlugin'
import ContextMenuPlugin from '../shared/plugins/ContextMenuPlugin'
import DraggableBlockPlugin from '../shared/plugins/DraggableBlockPlugin'
import EmojiPickerPlugin from '../shared/plugins/EmojiPickerPlugin'
import EmojisPlugin from '../shared/plugins/EmojisPlugin'
import EquationsPlugin from '../shared/plugins/EquationsPlugin'
// import ExcalidrawPlugin from '../../plugins/ExcalidrawPlugin'
import FigmaPlugin from '../shared/plugins/FigmaPlugin'
import FloatingLinkEditorPlugin from '../shared/plugins/FloatingLinkEditorPlugin'
import FloatingTextFormatToolbarPlugin from '../shared/plugins/FloatingTextFormatToolbarPlugin'
import ImagesPlugin from '../shared/plugins/ImagesPlugin'
import InlineImagePlugin from '../shared/plugins/InlineImagePlugin'
import KeywordsPlugin from '../shared/plugins/KeywordsPlugin'
import { LayoutPlugin } from '../shared/plugins/LayoutPlugin/LayoutPlugin'
import LinkPlugin from '../shared/plugins/LinkPlugin'
import ListMaxIndentLevelPlugin from '../shared/plugins/ListMaxIndentLevelPlugin'
// import MarkdownShortcutPlugin from '../../plugins/MarkdownShortcutPlugin'
import { MaxLengthPlugin } from '../shared/plugins/MaxLengthPlugin'
import MentionsPlugin from '../shared/plugins/MentionsPlugin'
import PageBreakPlugin from '../shared/plugins/PageBreakPlugin'
import PollPlugin from '../shared/plugins/PollPlugin'
import SpeechToTextPlugin from '../shared/plugins/SpeechToTextPlugin'
import TabFocusPlugin from '../shared/plugins/TabFocusPlugin'
// import TableCellResizer from '../../plugins/TableCellResizer'
import TableHoverActionsPlugin from '../shared/plugins/TableHoverActionsPlugin'
import TableOfContentsPlugin from '../shared/plugins/TableOfContentsPlugin'

import TwitterPlugin from '../shared/plugins/TwitterPlugin'
import YouTubePlugin from '../shared/plugins/YouTubePlugin'
import ContentEditable from '../shared/ui/ContentEditable'

import { EditorState } from 'lexical'

import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { $convertFromMarkdownString } from '@lexical/markdown'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useSettings } from '../context/SettingsContext'
import { CAN_USE_DOM } from '@udecode/plate-common'

import TableActionMenuPlugin from '../shared/plugins/TableActionMenuPlugin'
import VideoPlugin from '../shared/plugins/VideoPulgin'
import EmbedAnyWebsitePlugin from '../shared/plugins/embedAnyWebsitPlugin'
import { PLAYGROUND_TRANSFORMERS } from '../shared/plugins/MarkdownTransformers'
import TableCellResizer from '../shared/plugins/TableCellResizer'
import { useTranslation } from 'react-i18next'

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import DragDropPaste from '../shared/plugins/DragDropPastePlugin'

// import {} from '@lexical/markdown'
// @ts-expect-error
const skipCollaborationInit = window.parent != null && window.parent.frames.right === window
function Editor({
  content,
  markdownDescription,
  tableOfContents = false,
  onChange,
}: {
  content: any
  taskId: string
  isTask: boolean
  documentId: string
  markdownDescription: string
  tableOfContents: boolean
  isDocument: boolean
  onChange: (editorState: EditorState) => void
}): JSX.Element {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves }) => {
      if (dirtyElements.size > 0 || dirtyLeaves.size > 0) {
        onChange(editorState)
      }
    })
  }, [editor])

  useEffect(() => {
    if (content) {
      editor.update(() => {
        editor.setEditorState(editor.parseEditorState(content))
      })
    }
  }, [content])

  useEffect(() => {
    if (markdownDescription) {
      editor.update(() => {
        $convertFromMarkdownString(markdownDescription, PLAYGROUND_TRANSFORMERS)
        editor.focus()
      })
    }
  }, [markdownDescription])

  const {
    settings: {
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView = true,
      shouldUseLexicalContextMenu,
      //   shouldPreserveNewLinesInMarkdown,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings()
  const isEditable = useLexicalEditable()

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false)
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport)
      }
    }
    updateViewPortWidth()
    window.addEventListener('resize', updateViewPortWidth)

    return () => {
      window.removeEventListener('resize', updateViewPortWidth)
    }
  }, [isSmallWidthViewport])

  useEffect(() => {
    editor.focus()
  }, [])

  const { t } = useTranslation(['editor'])

  return (
    <>
      {/* <Action editor={editor} /> */}

      {/* <ShowClearDialog editor={editor} onClose={onClose} /> */}
      {/* {isRichText && <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />} */}
      <div
        className={`editor-container ${showTreeView ? 'tree-view' : ''} ${
          !isRichText ? 'plain-text' : ''
        }`}
      >
        <AutoFocusPlugin defaultSelection={'rootStart'} />

        {isMaxLength && <MaxLengthPlugin maxLength={30} />}
        <DragDropPaste t={t} />
        <ClearEditorPlugin />
        <ComponentPickerPlugin t={t} />
        <EmojiPickerPlugin />
        <AutoEmbedPlugin />
        <VideoPlugin />
        <MentionsPlugin />
        <EmojisPlugin />
        <KeywordsPlugin />
        <EmbedAnyWebsitePlugin />

        <SpeechToTextPlugin />
        <AutoLinkPlugin />
        {/* <CommentPlugin providerFactory={isCollab ? createWebsocketProvider : undefined} /> */}
        {isRichText ? (
          <>
            {/* COLLABORATION */}

            <RichTextPlugin
              //   initialEditorState={isCollab ? null : emptyEditor ? undefined : prepopulatedRichText}
              contentEditable={
                <div className="editor-scroller">
                  <div className="editor" ref={onRef}>
                    <ContentEditable placeholder="" />
                  </div>
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />

            {/* <MarkdownShortcutPlugin /> */}
            <MarkdownShortcutPlugin transformers={PLAYGROUND_TRANSFORMERS} />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <TablePlugin
              hasCellMerge={tableCellMerge}
              hasCellBackgroundColor={tableCellBackgroundColor}
            />
            <TableCellResizer />
            <TableHoverActionsPlugin />
            <ImagesPlugin />
            <InlineImagePlugin />
            <LinkPlugin />
            <PollPlugin />
            <TwitterPlugin />
            <YouTubePlugin />
            <FigmaPlugin />
            <ClickableLinkPlugin disabled={isEditable} />
            <HorizontalRulePlugin />
            <EquationsPlugin />
            {/* <ExcalidrawPlugin /> */}
            <TabFocusPlugin />

            <TabIndentationPlugin />
            <CollapsiblePlugin />
            <PageBreakPlugin />
            <LayoutPlugin />
            {/* && !isSmallWidthViewport */}
            {floatingAnchorElem && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin
                  anchorElem={floatingAnchorElem}
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                <TableActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} t={t} />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
              </>
            )}
          </>
        ) : (
          <>
            <PlainTextPlugin
              contentEditable={<ContentEditable placeholder={''} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {/* <HistoryPlugin externalHistoryState={historyState} /> */}
          </>
        )}
        {(isCharLimit || isCharLimitUtf8) && (
          <CharacterLimitPlugin charset={isCharLimit ? 'UTF-16' : 'UTF-8'} maxLength={5} />
        )}
        {isAutocomplete && <AutocompletePlugin />}
        <div>{tableOfContents && <TableOfContentsPlugin />}</div>
        {shouldUseLexicalContextMenu && <ContextMenuPlugin />}
        {/* <ActionsPlugin isRichText={isRichText}  /> */}
      </div>
      {/* {showTreeView && <TreeViewPlugin />} */}
    </>
  )
}

export default Editor
