import { useGetDescriptionHistoryQuery } from '@src/modules/tasks/services/notificationApi'
import { Modal, Table } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import DesHistoryView from '../components/DesHistoryView'
import UpdatedOn from '../components/UpdatedOn'
import User from '../components/User'

type props = {
  isHistoryOpen: boolean
  setIsHistoryOpen: Function
  setDescription: (o: string) => void
}

const DescriptionHistory = ({ setIsHistoryOpen, isHistoryOpen }: props) => {
  const { t } = useTranslation(['taskDetails'])
  const [searchparams, _] = useSearchParams()
  const taskId = searchparams.get('taskId')
  const locale = {
    emptyText: t('no_data'),
  }

  const bottomRef = useRef(null)
  const [page, setPage] = useState<number>(1)

  const { data, isLoading, refetch } = useGetDescriptionHistoryQuery({
    taskId,
    skip: page,
    limit: 15,
  })
  const descriptionHistory = data?.payload

  const columns = [
    {
      title: t('User'),
      dataIndex: 'user',
      key: 'user',
      render: (_, elem) => <User elem={elem} />,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      render: (_, elem) => (
        <DesHistoryView elem={elem} taskId={taskId} setIsHistoryOpen={setIsHistoryOpen} />
      ),
    },
    {
      title: t('Updated on'),
      dataIndex: 'updated on',
      key: 'updated on',
      render: (_, elem) => <UpdatedOn elem={elem} />,
    },
  ]

  const handleScroll = () => {
    const bottom = bottomRef.current.getBoundingClientRect().top <= window.innerHeight
    if (bottom) {
      setPage((prev) => prev + 1)
      refetch()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Modal
      footer={true}
      open={isHistoryOpen}
      onCancel={() => setIsHistoryOpen(false)}
      closable={true}
      className="modal-history"
    >
      <Table
        dataSource={descriptionHistory}
        loading={isLoading}
        columns={columns}
        pagination={false}
        className="table-history"
        locale={locale}
      />
      <div ref={bottomRef}></div>
    </Modal>
  )
}

export default DescriptionHistory
