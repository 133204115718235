import { ReactComponent as OptionsIcon } from './../../../assets/icons/status/status-card-options.svg'
import { ReactComponent as RenameIcon } from './../../../assets/icons/status/rename.svg'
import { ReactComponent as UpdateIcon } from './../../../assets/icons/status/update.svg'
import { ReactComponent as AddTaskIcon } from './../../../assets/icons/status/add-task.svg'
import { ReactComponent as SkeletonIcon } from './../../../assets/icons/skeleton/status/status.svg'
import { TaskType, column } from '@src/modules/tasks/data/tasksSlice/tasksTypes'
import { useGetTasksQuery } from '@src/modules/tasks/services/tasksApi'
import { Dropdown, MenuProps } from 'antd'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ListType } from '@src/modules/spaces/components/List/List'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { VariableSizeList as List } from 'react-window'
import { openModal } from '@src/modules/shared/store/slices/modals/modalsSlice'
import { store, useAppDispatch, useAppSelector } from '@src/modules/shared/store'
import { TasksByGroupType } from '../Board'
import { resetSocketEvents, setSelectedItems } from '@src/modules/tasks/data/tasksSlice/tasksSlice'
import SkeletonLoader from '@src/modules/shared/components/SkeletonLoader'
import AddTask from '../AddTask'
import TaskCard from '../TaskCard/TaskCard'
import { addOpacity } from '@src/modules/shared/utils/addOpacity'
import Spinner from '@src/modules/spaces/components/Spinner'
import { checkCanOnlyView, tasksColumnHeader } from '../../ListView/StatusTree/StatusTree'
import { GroupByFields } from '@src/modules/tasks/features/Tasks/Tasks'
import { useTranslation } from 'react-i18next'
import { getSortFieldByGroupBy } from '@src/modules/tasks/utils/getSortFieldByGroupBy'

export const getSort = (headers): column[] => {
  return headers?.filter((header) => header.sortBy > 0)?.sort((a, b) => a.sortBy - b.sortBy)
}

const generateSort = (headers, groupBy?: string) => {
  const sort = getSort(headers)?.reduce((acc, header) => {
    acc[header?.custom ? `customField_${header?.id}` : header.value] =
      header.value === 'users' || header.value === 'owner'
        ? { fullName: header.sortOrder, email: header.sortOrder }
        : header.sortOrder
    return acc
  }, {})

  if (groupBy && sort) sort[getSortFieldByGroupBy(groupBy)] = 'ASC'

  return sort
}

export const concatStatusFilter = (
  statusFilter?,
  filter?,
  fileId?: string,
  headers?: column[],
  groupBy?,
  extraOptions?: any,
) => {
  filter = filter ? JSON.parse(filter) : []
  if (filter?.length !== 0)
    filter = {
      where: filter?.where?.map((fil) => ({
        ...fil,
        ...statusFilter,
        ...(fileId ? { fileId: { operation: '$Equal', value: fileId } } : {}),
      })),
    }
  else
    filter = {
      where: [
        {
          ...statusFilter,
          ...(fileId ? { fileId: { operation: '$Equal', value: fileId } } : {}),
        },
      ],
    }

  if (!groupBy?.custom) filter['order'] = generateSort(headers, groupBy?.type)

  if (extraOptions) filter = { where: [...filter.where, extraOptions] }

  return JSON.stringify(filter)
}

interface statusCardProps {
  data: any
  tasks: TaskType[]
  setTasks: (data) => void
  groupBy: {
    id: string
    type: GroupByFields
    custom: boolean
  }
  filterByMe: boolean
  tasksCount: any
  setTasksCount: (count) => void
  search?: string
  list: ListType
  taskIsDraging: boolean
  index: number
}

const statusOpions = (list: ListType, t): MenuProps['items'] => {
  return [
    {
      key: '1',
      label: t('board_view.rename_status'),
      icon: <RenameIcon />,
      onClick: () => {
        store.dispatch(openModal({ id: 'update-status-modal', data: { ...list, type: 'fileId' } }))
      },
    },
    {
      key: '2',
      label: t('board_view.status_managment'),
      icon: <UpdateIcon />,
      onClick: () => {
        store.dispatch(openModal({ id: 'update-status-modal', data: { ...list, type: 'fileId' } }))
      },
    },
    {
      key: '3',
      label: t('board_view.new_task'),
      icon: <AddTaskIcon />,
    },
  ]
}

const StatusCard: React.FC<statusCardProps> = ({
  data,
  taskIsDraging,
  tasks,
  list,
  index,
  filterByMe,
  tasksCount,
  setTasksCount,
  search,
  groupBy,
  setTasks,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useAppSelector((state) => state.auth)
  const { createdTask } = useAppSelector((state) => state.tasks) //socket updates "socket events" - the create task event should refetch the data
  const { tasks: tasksFilter } = useAppSelector((state) => state.filter)
  const { headers } = useAppSelector((state) => state.tasks)
  const dispatch = useAppDispatch()
  const listRef = useRef(null)
  const sizeMap = useRef({})
  const [addTaskInfo, setAddTaskMode] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const canOnlyView = checkCanOnlyView(list)
  const { selectedItems } = useAppSelector((state) => state.tasks)
  const { t } = useTranslation(['tasks'])

  const {
    data: statusTasks,
    isLoading,
    refetch,
  } = useGetTasksQuery(
    {
      fileId: list?.id,
      skip: 1,

      options: concatStatusFilter(
        {
          ...(groupBy?.type === GroupByFields.STATUS
            ? { status: { operation: '$Equal', value: data.id } }
            : {}),
          ...(search ? { name: { operation: '$contains', value: search } } : {}),
          ...(groupBy?.type === GroupByFields.PRIORITY
            ? { priority: { operation: '$Equal', value: data.id } }
            : {}),
          parentId: { operation: '$Equal', value: null },

          ...(groupBy?.type === GroupByFields.ASSIGNEE
            ? {
                assigned_to: {
                  operation: data?.notAssigned ? '$Equal' : '$Assigned',
                  value:
                    data?.id !== 'empty' ? data?.value?.map((user) => user.id).join(',') : null,
                },
              }
            : {}),

          ...(groupBy?.custom
            ? {
                customFieldsValues:
                  data?.id === 'Empty'
                    ? {
                        operation: '$in',
                        value: [],
                      }
                    : {
                        operation: '$elemMatch',
                        value: {
                          customFieldId: {
                            operation: '$Equal',
                            value: groupBy?.id,
                          },
                          value: { operation: '$jsonbEquals', value: data?.value },
                        },
                      },
              }
            : {}),

          ...(filterByMe && user?.user?.id
            ? { assigned_to: { operation: '$in', value: [user.user.id] } }
            : {}),
        },
        tasksFilter,
        list?.id,
        headers,
        groupBy,
      ),
      limit: currentPage * 10,
    },
    { skip: !list?.id },
  )

  const { metadata } = statusTasks?.payload || {}

  useEffect(() => {
    if (list?.id) {
      refetch() //refetch data
      dispatch(resetSocketEvents())
    }
  }, [createdTask, tasksFilter, groupBy?.id, filterByMe])

  useEffect(() => {
    if (data?.id !== undefined) {
      setTasks((prev: TasksByGroupType) => ({
        ...prev,
        [data.id]: statusTasks?.payload?.payload,
      }))
      setTasksCount((prev) => ({
        ...prev,
        [data.id]: { ...metadata, loadedTasks: currentPage * 20 },
      }))
      setLoading(false)
    }
  }, [statusTasks])

  const getSize = (index: number) => sizeMap.current[index] || 220
  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    if (listRef.current) listRef.current.resetAfterIndex(index)
  }, [])

  const handleScroll = () => {
    const target = document.getElementsByClassName(`status-card-content ${data.id}`)[0]
    const scrollHeight = Math.trunc(target?.scrollHeight - target?.scrollTop)
    const bottom =
      scrollHeight === target?.clientHeight ||
      scrollHeight + 1 === target?.clientHeight ||
      scrollHeight - 1 === target?.clientHeight

    if (bottom && target?.scrollTop > 0 && tasksCount.total_items > currentPage * 10) {
      setCurrentPage((prev) => prev + 1)
      setLoading(true)
    }
  }

  const select = (task) => {
    const alreadySelected = selectedItems?.includes(task?.id)
    const newSelectedItems = alreadySelected
      ? selectedItems?.filter((id) => task?.id !== id)
      : [...(selectedItems || []), task?.id]
    dispatch(setSelectedItems(newSelectedItems))
  }

  return (
    <SkeletonLoader
      isLoading={isLoading}
      skeleton={<SkeletonIcon className="skeleton status-card" />}
    >
      <Draggable
        draggableId={data?.id?.toString()}
        index={index}
        isDragDisabled={groupBy?.id !== GroupByFields.STATUS}
      >
        {(provided, _) => (
          <div
            className="status-card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <div
              className="status-card-header"
              {...provided.dragHandleProps}
              style={{ borderColor: data?.color }}
            >
              {tasksColumnHeader(t)?.[groupBy?.type]?.(data, tasksCount?.total_items)}

              <div className="col2">
                {!canOnlyView && (
                  <div
                    className="card-plus-button"
                    style={{ color: data?.color, background: addOpacity(data?.color, 35) }}
                    onClick={() => setAddTaskMode({})}
                  >
                    +
                  </div>
                )}

                <Dropdown
                  disabled={canOnlyView}
                  menu={{
                    items: statusOpions(list, t),
                    onClick: ({ key }) => key === '3' && setAddTaskMode({}),
                  }}
                  trigger={['click']}
                >
                  <div className="card-options">
                    <OptionsIcon />
                  </div>
                </Dropdown>
              </div>
            </div>

            {addTaskInfo && (
              <AddTask
                data={data}
                fileId={list?.id}
                addTaskInfo={addTaskInfo}
                groupBy={groupBy}
                onAddTask={(newTask) =>
                  setTasks((prev: TasksByGroupType) => ({
                    ...prev,
                    [data.id]: [newTask, ...(prev[data.id] || [])],
                  }))
                }
                setAddTaskMode={setAddTaskMode}
              />
            )}

            {tasks ? (
              <Droppable
                droppableId={`${data.id}`}
                type="TASKS"
                direction="vertical"
                mode="virtual"
                renderClone={(provided2, snapshot2, rubric) =>
                  tasks[rubric.source.index] && (
                    <TaskCard
                      provided={provided2}
                      snapshot={snapshot2}
                      select={() => select(tasks[rubric.source.index])}
                      selected={selectedItems?.includes(tasks[rubric.source.index]?.id)}
                      data={tasks[rubric.source.index]}
                      list={list}
                      canOnlyView={canOnlyView}
                      setSize={setSize}
                      style={undefined}
                      index={rubric.source.index}
                    />
                  )
                }
              >
                {(dropProvided, dropSnapshot) => {
                  const itemCount: number = dropSnapshot.isUsingPlaceholder
                    ? tasks.length + 1
                    : tasks.length

                  return (
                    <List
                      height={500}
                      {...dropProvided.droppableProps}
                      className={`status-card-content ${data.id}`}
                      itemCount={itemCount}
                      itemSize={getSize}
                      width={295}
                      ref={listRef}
                      onScroll={handleScroll}
                      outerRef={dropProvided.innerRef}
                      itemData={{
                        tasks,
                        setSize,
                        taskIsDraging,
                        canOnlyView,
                        selectedItems,
                        select,
                        list,
                        groupBy,
                      }}
                    >
                      {TaskRow}
                    </List>
                  )
                }}
              </Droppable>
            ) : null}
            {loading ? (
              <div className="board-loading-more">
                <Spinner />
              </div>
            ) : null}
          </div>
        )}
      </Draggable>
    </SkeletonLoader>
  )
}

export default memo(StatusCard)

const TaskRow = React.memo(({ data, index, style }: { data; index: number; style }) => {
  const { tasks, setSize, taskIsDraging, canOnlyView, select, selectedItems, list, groupBy } = data
  const task = tasks[index]
  if (!task || !task?.id) return null

  const patchedStyle = {
    ...style,
    paddingBottom: 0,
  }

  // useEffect(() => {
  //   { task })
  //   if (!task) return null
  // }, [task])

  return (
    <Draggable
      isDragDisabled={canOnlyView || taskIsDraging || groupBy?.custom}
      draggableId={task?.id.toString()}
      index={index}
      shouldRespectForcePress={true}
      key={task?.id}
    >
      {(dragProvided, dragSnapshot) => (
        <TaskCard
          data={task}
          provided={dragProvided}
          snapshot={dragSnapshot}
          setSize={setSize}
          select={() => select(task)}
          selected={selectedItems?.includes(task?.id)}
          style={patchedStyle}
          index={index}
          list={list}
          canOnlyView={canOnlyView}
          key={task.id}
        />
      )}
    </Draggable>
  )
})
